import React, { useEffect, useMemo } from 'react';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import ExcellentExport from 'excellentexport';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import ArticleIcon from '@mui/icons-material/Article';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import Stack from '@mui/material/Stack';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import InputAdornment from '@mui/material/InputAdornment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import SaveIcon from '@mui/icons-material/Save';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Badge from '@mui/material/Badge';
import MapIcon from '@mui/icons-material/Map';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CommentIcon from '@mui/icons-material/Comment';
import Tooltip from '@mui/material/Tooltip';
import Radio from '@mui/material/Radio';
import CheckIcon from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import CircularProgress from '@mui/material/CircularProgress';

import { useLocation, useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";

import { useStyles } from "./styles";
import {get, post, postPlain, deleteData} from "./commons";

class Welcome extends React.Component {
  render() {
    return <td>+</td>;
  }
}

export default function WorkReport({isMobile,isMobileSize,sendMessage,lastMessage,userData,ws_id}) {
  let location = useLocation();
  let router_params = useParams();
  const classes = useStyles();
  const [keysdown,setkeysdown] = React.useState([0,0]);
  const [checkedCol, setCheckedCol] = React.useState([]);
  const [checkedRow, setCheckedRow] = React.useState({});
  const [datset,setDatset] = React.useState([]);
  const [fieldNames, setfieldNames] = React.useState([]);
  const [columnHeaders, setColumnHeaders] = React.useState([]);
  const [columnVisible, setColumnVisible] = React.useState([]);
  const [workOrders, setWorkOrders] = React.useState([]);
  const [openWOsOnly, setOpenWOsOnly] = React.useState(true);
  const [valsegment, setValsegment] = React.useState("");
  const [categoryTypes, setCategoryTypes] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [categoriesID, setCategoriesID] = React.useState([]);
  const [workOrder, setWorkOrder] = React.useState();
  const [prevWorkOrder, setPrevWorkOrder] = React.useState();
  const [allRowsChecked, setAllRowsChecked] = React.useState(false);
  const [pendingCircular, setPendingCircular] = React.useState(false);
  const [displayDocuments, setDisplayDocuments] = React.useState(false);
  const [openDocumentDialog, setOpenDocumentDialog] = React.useState(false);
  const [addingDocument, setAddingDocument] = React.useState({private:false});
  const [documentsList, setDocumentsList] = React.useState([]);
  const [showFilterColumn, setShowFilterColumn] = React.useState();
  const [columnFilter, setColumnFilter] = React.useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [inputField, setInputField] = React.useState("");
  const [prevInput_Field, setPrevInput_Field] = React.useState("");
  const [newSessionVal, setnewSessionVal] = React.useState();
  const [columnField, setColumnField] = React.useState();
  const [rowField, setRowField] = React.useState();
  const [globalTableSelected, setGlobalTableSelected] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const [isUpdated, setIsUpdated] = React.useState();
  const [age, setAge] = React.useState();
  const [totalDBRows, setTotalDBRows] = React.useState(0);
  const [totalDisabledRows, setTotalDisabledRows] = React.useState(0);
  const [selectedColumn, setSelectedColumn] = React.useState();
  const [displaySections, setDisplaySections] = React.useState(false);
  const [clipboardText, setClipboardText] = React.useState();
  const [clickMovingR, setClickMovingR] = React.useState([]);
  const [clickMovingC, setClickMovingC] = React.useState([]);
  const [initialClick, setInitialClick] = React.useState([]);
  const [selectingFields, setSelectingFields] = React.useState(false);
  const [biggestWidth, setBiggestWidth] = React.useState();
  const [othersEditing, setOthersEditing] = React.useState({});
  const [othersWorkingOn,setOthersWorkingOn] = React.useState([]);
  const [newEmptyRow, setNewEmptyRow] = React.useState([]);
  const [previousField, setPreviousField] = React.useState();
  const [cssfields, setcssfields] = React.useState();
  const [filteringRows, setFilteringRows] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [multiPosFilter, setMultiPosFilter] = React.useState({});
  const [filterStrings, setFilteringStrings] = React.useState();
  const [includeDisabled, setIncludeDisabled] = React.useState(false);
  const [current_ws_id, setcurrent_ws_id] = React.useState();
  const [filteredRIDs,setFilteredRIDs] = React.useState();
  const [displayActionRegistry, setDisplayActionRegistry] = React.useState();
  const [value2paste, setValue2paste] = React.useState();
  const [activity, setActivity] = React.useState();
  const [employees, setEmployees] = React.useState([]);
//  const [listTooLong, setListTooLong] = React.useState(false);
  const [displayDatSet, setDisplayDatSet] = React.useState(false);
  const [isNewWO, setIsNewWO] = React.useState(true);
  const everyregistry = ["Todos los registros"];
  const [multiFilter, setMultiFilter] = React.useState([everyregistry]);
  const [fullFilterSet, setFullFilterSet] = React.useState([everyregistry]);
  const [filterGroups, setFilterGroups] = React.useState([]);
  const [serverFiltering, setServerFiltering] = React.useState(false);
  const [wOfilter, setWOfilter] = React.useState();
  const [wo_items, set_wo_items] = React.useState([]);
  //const [allVals, setAllVals] = React.useState({});
  const [pendingMultifilters, setPendingMultifilters] = React.useState();
  const [anchorShowSettings, setAnchorShowSettings] = React.useState(null);
  const [isCircleProgress, setIsCircleProgress] = React.useState(false);
  const [lines2add, setLines2add] = React.useState(0);

  const pasteRef = React.useRef(null);
  const dropdownRef = React.useRef(null);

  const [cookies, setCookie] = useCookies();

  const valRange = Array(50).fill().map((_, i) => i + 1);

  const storeItem = (key, val, afterinitialload,pos) => { //console.log("storeItem",pos)
    if (afterinitialload) {
      let currentmempos = localStorage.getItem("mempos");
      if (!currentmempos) currentmempos = 0;
      currentmempos++;
      localStorage.setItem("mempos",currentmempos);
      localStorage.setItem(`mempos${currentmempos}`, `${key}//key-val//${sessionStorage.getItem(key)}`);
    }
    sessionStorage.setItem(key, val);
  }
  const returnObjVal = (key) => {
    return sessionStorage.getItem(key)
  }
  const delObjVal = (key) => {
    sessionStorage.removeItem(key)
  }

  const fieldTypes = {
    TEXT:"t",
    NUMB:"n",
    DECIM:"d",
    DROPDOWN:"s",
    PERCENT:"p",
    DATE:"f",
    DATEWO:"f_wo",
    EDIT_DATE:"f_from",
    IMAGE:"i",
    GEOLOCATION:"g"
  };


  const downloadFile = (dataSet2Export,fromlocal) => {
    let options = {
       openAsDownload: true,
       format: 'xlsx',
       filename: `${workOrder.work_report}_produccion`
    };
    let fullDatSet2Export = dataSet2Export.map(e=>{
      if (fromlocal) return e.map((a, i) => {
        return returnObjVal(`${e[0].rid}-${a.id}`);
      });
      else return datset[0]?.map((a, i) => {
          let element2 = e.content.filter(cont2=>cont2.id===a.id);
          if (element2[0]?.value) return element2[0].value;
          else return ""
        })
    });
    let sheets =   [
         {
             name: `${workOrder.work_report} datos de producción`,
             from: {
                 table: String/Element,
                 array: [
                   fieldNames.map(f=>f.category).map((f,p)=>{if (p === 0 || f !== fieldNames[p-1].category) return f}),
                   fieldNames.map(f=>f.name),
                   ...fullDatSet2Export
                 ]
             }
         }
     ]
    ExcellentExport.convert(options, sheets);
  }
  useEffect(()=>{
    if (lastMessage) {
      if (lastMessage.data?.substr(0,8)==="prod_id:") {
        let elements = lastMessage.data.split("///ds:");
        let id = elements[0].substr(8);
        let elements1 = elements[1].split("///wo:");
        let ds = JSON.parse(elements1[0]);
        let wo = JSON.parse(elements1[1]);
        if (id.length > 0 && wo.toString() === workOrder?.work_report && ds) {
          ds.forEach((e) => {//console.log("msg",id,e)
            if (e.content) storeItem(`${id}-${e.id}`, e.content, false,13);
            else if (e.value) storeItem(`${id}-${e.id}`, e.value, false,14);
          });
        }
      }
      else if (lastMessage.data?.substr(0,3)==="if:") {
        let elementsTemp = lastMessage.data?.substr(3).split("//us:");
        let elements = elementsTemp[0].split("//wo:");
        let newOthersEditing = {...othersEditing}
        newOthersEditing[elements[1]] = elements[0];
        setOthersEditing(newOthersEditing);
        let listOfInputs = Object.keys(newOthersEditing);
        setOthersWorkingOn(listOfInputs.map(e=>newOthersEditing[e]));
      }
      else if (lastMessage.data?.substr(0,12)==="proditemdel:" ||
               lastMessage.data?.substr(0,14)==="proditemundel:" ||
               lastMessage.data?.substr(0,13)==="lockproditem:" ||
               lastMessage.data?.substr(0,13)==="proddata_new:") {
        let items = lastMessage.data.split(":")[1].split("-");
        if (items.length === 2 && items[0] === workOrder?.work_report) {
          let filters2set = filterGroups.filter(e=>e.filtering?.join("-")===multiFilter?.join("-"));
          retriveProdData(1,null,null,filters2set[0]?.filters);
        }
      }
      else if (lastMessage.data?.substr(0,10)==="new_lines:") {
        let elements = lastMessage.data.split("///wo:");
        let id = elements[0].substr(10);
        let wo = elements[1];
        if (id.length > 0 && wo.toString() === workOrder?.work_report) {
          let currentLength = parseInt(datset.length);
          addNewRow2list(true,id,currentLength);
        }
      }
    }
  },[lastMessage])

  useEffect(()=>{
    sessionStorage.clear();
    get(`/server/request/getprodtypes`)
    .then((response) => {
      if (response.wo_filter) setWOfilter(response.wo_filter);
      if (response.wo_items) set_wo_items(response.wo_items);
      if (response?.data?.length > 0) {
        let newfieldNames = response.data.map((e)=>{e.options = JSON.parse(e.options);return e});
        setfieldNames(newfieldNames);
        setCategories(newfieldNames.map((e)=>e.name));
        setCategoriesID(newfieldNames.map((e)=>e.id));
        let newCategoryTypes = [...new Set(newfieldNames.map((e)=>e.category))];
        setCategoryTypes(newCategoryTypes);

        let columnsVisible = [];
        let newColumnHeaders = newfieldNames.map((e,pos)=>{
          if (e.category === newCategoryTypes[0]) {
            columnsVisible[pos] = true;
            return e.name;
          }
          else columnsVisible[pos] = false;
        }).filter(e=>e);
        setColumnHeaders(newColumnHeaders);
        setColumnVisible(columnsVisible);
      }
      if (response?.filters) {
        let posFilterEdit = response.filters.filter(e=> e.name==="edit" )
        if (posFilterEdit[0]?.content) {
          let newfilters = JSON.parse(posFilterEdit[0]?.content);
          if (newfilters.prod_menu) {
            setFilters(newfilters.prod_menu);
            let newMultiPosFilter = {};
            newfilters.prod_menu.forEach((item, i) => newMultiPosFilter[item] = i);
            setMultiPosFilter(newMultiPosFilter);
          }
        }
      }
    });
    get(`/server/request/getemployees`)
    .then((response) => {
      if (response && response.employees && response.employees.length > 0) {
        setEmployees(response.employees);
      }
    });
  },[])
  useEffect(()=>{
    if (location?.state?.info) {
      let thiswo = workOrders.filter(e=>e.work_report.toString() === location.state.info.toString());
      if (thiswo.length === 1) selectWorkOrder(null,thiswo[0]);
      if (location?.state?.info2) setPendingMultifilters(location.state.info2);
    }
  },[location]);
  useEffect(()=>{
    get(`/server/request/getworkorders?open=${openWOsOnly}`)
    .then((response) => {
      if (response?.wo?.length > 0) {
        setWorkOrders(response.wo);
        if (location?.state?.info?.length > 0) {
          let thiswo = response.wo.filter(e=>e.work_report === location.state.info);
          if (thiswo.length === 1) selectWorkOrder(null,thiswo[0]);
        }
      }
    });
  },[openWOsOnly]);

  const removeSelected = (delete_all) => {
    if (Object.keys(checkedRow).length === datset.length) {
      let newCheckedRow = {}
      let checkedItems = datset.filter((e)=>{
        if (checkedRow[e[0].rid] === "self") return true;
        else {
          newCheckedRow[e[0].rid] = checkedRow[e[0].rid];
          return false;
        }
      }).map(e=>e[0].rid);
      post(`/server/request/delproditems`,{wo:workOrder.work_report, items:checkedItems, del:Boolean(delete_all)})
      .then((response) => {
        if (response?.deleted) {
          let filters2set = filterGroups.filter(e=>e.filtering?.join("-")===multiFilter?.join("-"));
          retriveProdData(2,newCheckedRow,null,filters2set[0]?.filters);
        }
      });
    }
  }

  const addingNewLine = (ids,newSet,num) => {
    let newcheckedRow = {...checkedRow};
    let newDataSet = [...datset];
    ids.forEach((item, i) => {
      newcheckedRow[item] = "no";
      let newSetRID = [...newSet].map((val,index)=>{
        let e = {...val}
        if (index === 0) e.rid = item;
        return e;
      })
      newDataSet.push([...newSetRID])
    });
    setCheckedRow({...newcheckedRow});
    setNewEmptyRow([...newEmptyRow, ...ids]);
    addData2Set(newDataSet,true,num);
  }

  const addNewRow2list = (adding,id,pos,prevTables,num) => {
    let obj = {};
    let newSet = fieldNames.map((f,c)=>{
      let newItem = {...f}
      if (c === 0){
        if (prevTables?.id) newItem.rid = prevTables.id;
        newItem.reviewed = prevTables?.reviewed;
        newItem.disabled = (prevTables?.disabled) ? true : false;
        if (prevTables?.locked) newItem.locked = prevTables.locked ;
      }
      let currentInfo = prevTables?.content?.filter((r)=>r.id === f.id);
      if (currentInfo && currentInfo[0] && currentInfo[0].value && currentInfo[0].value !== undefined) {
        newItem.value = currentInfo[0].value;
        }
      else if (f.default_value) newItem.value = f.default_value;
      else newItem.value = "";
      if (f.type === "DROPDOWN" && newItem.options?.values) newItem.options = newItem.options.values;
      else delete newItem.options;
      if (f.type === "NUMB" && isNaN(newItem.value.replace(',', '.'))) {
        storeItem(`${prevTables.id}-${newItem.id}-s`,"red", false,15);
        obj[`${prevTables.id}-${newItem.id}-s`] = "red";
      }
      delete newItem.category;
      delete newItem.default_value;
      delete newItem.name;
      newItem.t = fieldTypes[newItem.type];
      delete newItem.type;
      return {...newItem};
    });
    if (adding && !id) {
      post(`/server/request/addnewprodrow`,{wo:workOrder.work_report,num:num,mssg:1,filters:filters})
      .then((response) => {
        if (response.newids && response.newids[0]) addingNewLine(response.newids,newSet,num);
        setLines2add(0); // if adding multiple lines reset select to 0
      });
    }
    else if (adding && id) addingNewLine([id],newSet,1);
    else {
      let newSetNoVal = newSet.map((e)=>{
        storeItem(`${newSet[0].rid}-${e.id}`, e.value,false,16);
        obj[`${newSet[0].rid}-${e.id}`] = e.value;
        delete e.value;
        return e;
      });
      return {newSetNoVal:newSetNoVal,obj:obj,rid:newSet[0].rid};
    }
  }

  const clearData = (refresh = false) => {
    setDatset([]);
    setPrevWorkOrder();
    setAllRowsChecked(false);
    sessionStorage.clear();
    setInputField("");
    setRowField();
    setColumnField();
    setGlobalTableSelected(false);
    setDocumentsList([]);
    setTotalDBRows();
  }

const retriveProdData = (pos,newCheckedRow,includeDeleted,filteringGroup,newfilteringRows) => {//console.log("retrive pro data", pos)
    sessionStorage.clear();
    Object.keys(localStorage).forEach(function(key){
      if (key.substring(0,6) == 'mempos') {
        localStorage.removeItem(key);
      }
    });
    let query = `/server/request/getproddata2edit?wo=${workOrder.work_report}`;
    let latestFilterRows = (newfilteringRows) ? newfilteringRows : filteringRows;
    let not_filtering_rids = datset.map(f=>f[0].rid).filter(e=>e).filter(e=>!latestFilterRows.includes(e));
    if (pendingMultifilters) filteringGroup = JSON.stringify(pendingMultifilters);//console.log("filteringGroup",filteringGroup,pendingMultifilters)
    if (filteringGroup) query += `&filter=${encodeURI(filteringGroup)}&include=${not_filtering_rids}`;//console.log("getproddata2edit",datset.map(f=>f[0].rid).filter(e=>e),filteringRows,newfilteringRows,not_filtering_rids)
    if (includeDeleted) query += `&del=${includeDeleted}`;
    let a2z = filterOptions.map((e)=>e?.order==="a2z").map((e,pos)=>{if (e) return fieldNames[pos]}).filter((e)=>e);
    let z2a = filterOptions.map((e)=>e?.order==="z2a").map((e,pos)=>{if (e) return fieldNames[pos]}).filter((e)=>e);
    if (a2z.length > 0) query += `&a2z=${a2z.map(e=>e.id)}`;
    if (z2a.length > 0) query += `&z2a=${z2a.map(e=>e.id)}`;
    let textsItems = filterOptions.filter((e)=>e?.filter?.length > 0);
    let texts = filterOptions.map((e)=>e?.filter?.length > 0).map((e,pos)=>{if (e) return fieldNames[pos]}).filter((e)=>e);
    if (texts.length > 0) {
      query += '&txts='
      textsItems.forEach((e,pos)=>{
        query += `[${texts[pos].id},${e.filter}]`;
        if (pos < textsItems.length-1) query += ",";
      });
    }
    get(query).then((response) => {
      let ready2display = true;
      setPrevWorkOrder(workOrder);
      if (response?.docs?.length > 0) {
        setDocumentsList(response.docs);
      }
      if (response?.active) setTotalDBRows(response.active);
      if (response?.disabled) setTotalDisabledRows(response.disabled);
      if (response?.items_own?.cat) setCategories(response.items_own.cat);
      else if (response?.items_other?.cat) setCategories(response.items_other.cat);
      else {
        let commonCategories = fieldNames?.map((e,pos)=>{
          if (e.view_default) return e.name;
        }).filter(e=>e);
        setCategories(commonCategories);
      }
      if (response?.activity) setActivity(response.activity);
      if (response?.items_own?.ids) setCategoriesID(response.items_own.ids);
      else if (response?.items_other?.ids) setCategoriesID(response.items_other.ids);
      else {
        let commonCategories = fieldNames?.map((e,pos)=>{
          if (e.view_default) return e.id;
        }).filter(e=>e);
        setCategoriesID(commonCategories);
      }
      if (response.data?.length > 0 || response.filter_groups?.length > 0) {

        let checked_RowList = [];
        let newcheckedRowNewfull = (newCheckedRow) ? {...newCheckedRow} : {...checkedRow};
        let fullRIDlist = []
        let rids = []
        let readOnlyDats = response.data.map((e,pos)=>{
          fullRIDlist.push(e.id.toString());
          newcheckedRowNewfull[e.id] = e.locked;
          checked_RowList.push(e.locked);
          e.content = (response?.sets) ? response.sets.map(f => {
            if (e.id === f.id) return {id:f.row,value:f.content}
          }).filter(e=>e) : [];
          let responseObj = addNewRow2list(false,null,pos,e,1);
          //obj = {...obj, ...responseObj.obj};
          rids.push(responseObj.rid)
          return responseObj.newSetNoVal;
        });
        let newcheckedRowNew = {}
        Object.keys(newcheckedRowNewfull).forEach(i=>{
          if (fullRIDlist.includes(i)) newcheckedRowNew[i] = newcheckedRowNewfull[i];
        })
        //setAllVals(obj);
        addData2Set(readOnlyDats,true,1);
        setCheckedRow({...newcheckedRowNew});
        setAllRowsChecked(checked_RowList.filter((e)=>e==="no").length===0);
/*        if (response.data.length > 50) {
          ready2display = false;
          setListTooLong(true);
        }*/
        if (ready2display) setDisplayDatSet(true);
        else setDisplayDatSet(false);
        if (response.filter_groups?.length > 0 && response.filter_groups[0]?.filters && filters.length > 0) {
          let fullSet = response.filter_groups;
          let theFitlers = response.filter_groups.map((e,p)=>{
            let jsonData = JSON.parse(e.filters);
            fullSet[p].filtered = jsonData;
            return jsonData;
          }).filter(e=>e).map(f=>{
            let thefilters = filters.map(e=>f[e]);
            fullSet = fullSet.map(g=>{
              if (g?.filtered===f) g.filtering = thefilters;
              return g;
            });
            return thefilters;
          });
          setFilterGroups(fullSet);
          if (theFitlers.length > 0) {
            if (response.active_filter !== "no") {
              setServerFiltering(true);
              if (!filteringGroup) {//console.log("response.active_filter ",JSON.parse(response.active_filter.filters),)
              if (response.active_filter?.filters) {
                  let json_elements = JSON.parse(response.active_filter.filters);
                  let json_Vals = Object.keys(json_elements);
                  let newFilters = json_Vals.map(e=>json_elements[e]);//console.log("json_Vals",json_Vals)
                  setMultiFilter(newFilters);//console.log("newFilters2",newFilters)
              }
              else {
                  let newFilters = theFitlers[0];
                  setMultiFilter(newFilters);//console.log("newFilters3",newFilters)
                }
              }
              else if (filteringGroup !== "all") {
                let newFilters = fullSet.filter(e=>e.filters === filteringGroup);
                if (newFilters.length > 0) {
                  setMultiFilter(newFilters[0].filtering);//console.log("newFilters 4",newFilters)
                }
              }
            }
            else setServerFiltering(false);
            let theFitlersNew = theFitlers.filter(e=>e).sort();
            theFitlersNew.unshift(everyregistry);
            setFullFilterSet(theFitlersNew);
            let allRIDs = datset.map(e=>e[0].rid)
            setFilteringRows(allRIDs);//console.log("set filteringRows *1",allRIDs)
            if (not_filtering_rids.length === 0) {
              setNewEmptyRow([]);
            }
          }
          else setServerFiltering(true);
        }
      }
      else {
        addNewRow();
        setDisplayDatSet(true);
      }
      if (pendingMultifilters) {
        let filerKeys = Object.keys(pendingMultifilters);
        let textflters = filerKeys.map(e=>pendingMultifilters[e])
        createMultipleFilters(null,textflters.join(" -- "),"noreload");
        setPendingMultifilters();
      }
      setIsCircleProgress(false);
    });
  }

useEffect(()=>{
  if (
    //listTooLong &&
    datset && isNewWO) {
  //  setListTooLong(false);
    setIsNewWO(false);
    let rids = datset.map(f=>f[0].rid).filter(e=>e);
    let exitingSets = rids.map(rid=>filters.map(filter =>returnObjVal(`${rid}-${filter}`)).filter(e=>e)).filter(e=>e.length === filters.length);
    let ridNames = exitingSets.reduce((acc, curr) => acc.includes(curr) ? acc : [...acc, curr], []);
    if (ridNames.length>0) {//console.log("ridNames",ridNames)
      if (totalDBRows < datset.length) setMultiFilter(ridNames[0]);
      setFilteringRows(datset.map(e=>e[0].rid));//console.log("set filteringRows *2",datset.map(e=>e[0].rid))
      setNewEmptyRow([]);
    }
    else setDisplayDatSet(true);
  }
},[
  //listTooLong,
  datset]);
useEffect(()=>{
  if (multiFilter && !displayDatSet) setDisplayDatSet(true);
},[multiFilter,displayDatSet]);
useEffect(()=>{
  if (workOrder && workOrder !== prevWorkOrder) retriveProdData(3);
},[workOrder, datset]);

  useEffect(()=>{
    let columnsVisible = [];
    let newColumnHeaders = fieldNames?.map((e,pos)=>{
      if (categories.includes(e.name)) {
        columnsVisible[pos] = true;
        return e.name;
      }
      else columnsVisible[pos] = false;
    }).filter(e=>e);
    setColumnHeaders(newColumnHeaders);
    setColumnVisible(columnsVisible);
  },[categories]);

  const SelectView = (selectedRowFields,element,column,rowsIDs,allRowsIDs,row,firstrow)=>{
    let imputName = `${firstrow.rid}-${element.id}`;
    let currentPos = selectedRowFields.indexOf(element);
    const resetOption = (valueA) => {
      let value = (valueA?.target?.value) ? valueA.target.value : "";
      let oldV = returnObjVal(imputName);
      storeItem(imputName,value,true,1);
      storeItem(imputName,value,true,2);
      clickcheckRow(null, [allRowsIDs.indexOf(rowsIDs[row])], false, 1, null, element.id);
    }
    const presskeydown = (d) => {
      if (d.keyCode === 90 || d.keyCode === 17) {
        setkeysdown([keysdown[1],d.keyCode]);
      }
      else if (((d.keyCode === 9 && !d.shiftKey) || d.keyCode === 39) && currentPos+1<selectedRowFields.length) {
        d.preventDefault();
        changeImputField(`${firstrow.rid}-${selectedRowFields[currentPos+1].id}`)
      }
      else if ((d.keyCode === 9 && d.shiftKey) || (d.keyCode === 37 && currentPos > 0)) {
        d.preventDefault();
        changeImputField(`${firstrow.rid}-${selectedRowFields[currentPos-1].id}`)
      }
      else if (d.keyCode === 38 && row > 0) {
        d.preventDefault();
        changeImputField(`${datset[row-1][0].rid}-${element.id}`)
      }
      else if (d.keyCode === 40 && row+1 < datset.length) {
        d.preventDefault();
        changeImputField(`${datset[row+1][0].rid}-${element.id}`)
      }
      else if (d.keyCode === 46) {
        d.preventDefault();
        let oldV = returnObjVal(imputName);
        setInputField("");
        resetOption();
        setPreviousField(`${datset[row][0].rid}-${element.id}`)
      }
    }
    return <NativeSelect
              autoFocus
              defaultValue={returnObjVal(`${firstrow.rid}-${element.id}`)}
              fullWidth
              onChange={resetOption}
              onKeyDown={presskeydown}
              >
               <option key={"empty"} value={""} />
               {element?.options?.map((i,e) => <option key={e} value={i}>{i}</option>)}
           </NativeSelect>
  }

  const redoAction = () => {
    setkeysdown([]);
    let currentmemposTxt = localStorage.getItem("mempos");
    let currentmempos = parseInt(currentmemposTxt);
    if (currentmempos) {
      let val2store = localStorage.getItem(`mempos${currentmempos+1}`);
      if (val2store) {
        let val2storeSplit = val2store.split("//key-val//")
        if (val2storeSplit.length === 2) {
          let inputSlipt = val2storeSplit[0].split("-");
          if (inputSlipt.length === 2) {
            let rowID = parseInt(inputSlipt[0]);
            datset.forEach((e,pos)=>{
                if (e[0].rid === rowID) {
                  storeItem(val2storeSplit[0],val2storeSplit[1],true,0);
                  localStorage.setItem("mempos",currentmempos+1);
                  clickcheckRow(null, [pos], false, 1, null, parseInt(inputSlipt[1]));
                }
            })
          }
        }
      }
    }
    /*
    get(`/server/request/nextdatver?wo=${workOrder.work_report}`).then((response) => {
    });
    */
  }

  const undoAction = () => {
    setkeysdown([]);
    let currentmemposTxt = localStorage.getItem("mempos");
    let currentmempos = parseInt(currentmemposTxt);
    if (currentmempos > 0) {
      let val2store = localStorage.getItem(`mempos${currentmempos}`);
      let val2storeSplit = val2store.split("//key-val//")
      if (val2storeSplit.length === 2) {
        let inputSlipt = val2storeSplit[0].split("-");
        if (inputSlipt.length === 2) {
          let rowID = parseInt(inputSlipt[0]);
          datset.forEach((e,pos)=>{
              if (e[0].rid === rowID) {
                storeItem(val2storeSplit[0],val2storeSplit[1],true,0);
                localStorage.setItem("mempos",currentmempos-1);
                clickcheckRow(null, [pos], false, 1, null, parseInt(inputSlipt[1]));
              }
          })
        }
      }
    }
    /*
    get(`/server/request/prevdatver?wo=${workOrder.work_report}`).then((response) => {
      if (response.done) setIsSaved(true);
    });
    */
  }

  useEffect(()=>{
    if (keysdown[1] === 90 && keysdown[0] === 17) undoAction();
  },[keysdown])

  const keydown = (d,e) => {
    if (d.keyCode === 90 || d.keyCode === 17) {
      setkeysdown([keysdown[1],d.keyCode]);
    }
  }
  const allCheckedRow = () => {
    selectAllTable(true);
    let newAllRowsChecked = !Boolean(allRowsChecked);
    setAllRowsChecked(newAllRowsChecked);
/*    let checked_RowValues = Object.values(checkedRow);//console.log("checked_RowValues",checked_RowValues)
    if (checked_RowValues.filter((e)=> (e==="no" || e==="self")).length > 0) {
      if (newAllRowsChecked) {
        let newcheckedRow = {...checkedRow};
        let newcheckedRowKeys = Object.keys(newcheckedRow);
        newcheckedRowKeys.forEach((e)=>{
          if (newcheckedRow[e] === "no" || newcheckedRow[e] === "self") newcheckedRow[e] = "self";
        });
        setCheckedRow(newcheckedRow);
        savedata();
      }
      else {*/
        let currentRIDs = datset.map(e=>e[0].rid);
        post(`/server/request/unlockallproditem`,{wo:workOrder.work_report,ids:currentRIDs,all:newAllRowsChecked})
        .then((response) => {
          if (response.updated) {
            let filters2set = filterGroups.filter(e=>e.filtering?.join("-")===multiFilter?.join("-"));
            retriveProdData(4,null,null,filters2set[0]?.filters);
            clearBasic();
            setIncludeDisabled(false);
          }
        });
//      }
//    }
  }
  const clearBasic = () => {
    setGlobalTableSelected();
    setRowField();
    setInputField("");
    setColumnField();
    setRowField();
    setSelectedColumn();
  }
  const undeleterow = (id) => {
    if (id) {
      post(`/server/request/undeleteprodrow`,{wo:workOrder.work_report,id:id})
      .then((response) => {
        if (response.restored) {
          let filters2set = filterGroups.filter(e=>e.filtering?.join("-")===multiFilter?.join("-"));
          retriveProdData(5,null,null,filters2set[0]?.filters);
          clearBasic();
          setIncludeDisabled(false);
          }
      });
    }
  }
  const clickcheckRow = (isChecked, rowArray, storeAndLock, test, prevFilteringGroups, element_id) => {//console.log(isChecked, rowArray, storeAndLock, test, prevFilteringGroups, element_id)
    if (rowArray[0] !== null) {
      let row = rowArray[0];
      let rid = ( datset[row] && datset[row][0] && datset[row][0].rid) ? datset[row][0].rid : null;
      let rowfilters = [];
      let wofilterID;
      let dataset_row = [...datset][row]
      if (element_id) {
    //    let is_filter = filters.includes(element_id);
        dataset_row = dataset_row.filter(e=>{
    //      if (is_filter) return Boolean(filters.includes(e.id) || e.id===element_id)
    //      else
           return Boolean(e.id===element_id);
          });
      }
      let new_Dat_set = dataset_row.map((e,pos)=>{
        let currentVal = returnObjVal(`${datset[row][0].rid}-${e.id}`);
        if (filters.includes(e.id)) rowfilters.push(e.id);
        if (wOfilter === e.id.toString()) wofilterID = e.id;
        return {
          id: e.id,
          value: currentVal
        }
      }).map((g)=>{
        if (!g.value?.length > 0) g.value = ""
        return g;
      });
      if (rid && new_Dat_set.length > 0) {
        let query = (storeAndLock) ? "lockproditem" : "storeproditem";
        let data = {
          id:rid,
          wo:workOrder.work_report,
          dataset:new_Dat_set,
          wofilter:wOfilter
        };
        if (!element_id || wo_items.map(e=>e.id).includes(element_id)) data.woitems = wo_items;
        if (rowfilters.length > 0) data.filters = filters;
        if (wofilterID) data.wofilter = wOfilter;
        if (storeAndLock) data.active = isChecked;
        post(`/server/request/${query}`, data)
        .then((response) => {
          let newFilterGroups = (prevFilteringGroups) ? prevFilteringGroups : [...filterGroups];
          if (response.changed) {
            /*
            let filterGroupsDoesItExist = newFilterGroups.filter(e=>e.filters===JSON.stringify(rowfilters));
            if (filterGroupsDoesItExist.length === 0) {
              newFilterGroups = newFilterGroups.map(e=>{
                if (e.ids.split(",").includes(rid.toString())) {
                  e.ids = e.ids.split(",").filter(f=>f!==rid.toString()).toString();
                }
                return e;
              });
              newFilterGroups.push({
                filtered: rowfilters,
                filtering: filters.map(e=>rowfilters[e]),
                filters: JSON.stringify(rowfilters),
                ids: rid.toString()
              });
            }
            else {
              newFilterGroups = newFilterGroups.map(e=>{
                if (e.filters===JSON.stringify(rowfilters)) {
                  let newids = e.ids.split(",");
                  if (!newids.includes(rid)) {
                    newids.push(rid);
                    e.ids = newids.toString();
                  }
                }
                else if (e.ids.split(",").includes(rid.toString())) e.ids = e.ids.split(",").filter(f=>f!==rid.toString()).toString();
                return e;
              });
            }
            newFilterGroups = newFilterGroups.filter(e=>e.ids.length > 0);
            */
            if (storeAndLock) {
              let editable = !isChecked;
              let checked_RowNew = {...checkedRow};
              checked_RowNew[rid] = (isChecked) ? "self" : "no";
              let rowKeys = Object.keys(checked_RowNew);
              let listOfRows = rowKeys.map(e=>checked_RowNew[e]);
              setCheckedRow(checked_RowNew);
              if (editable) {
                setAllRowsChecked(false);
                setIsSaved(true)
              }
              else if (listOfRows.filter((e)=>e !== "no").length === 0) setAllRowsChecked(true);
            }
          }
          rowArray.shift();
          if (rowArray.length > 0) clickcheckRow(isChecked, rowArray, storeAndLock, 2, newFilterGroups, element_id)
          else {
            setFilterGroups(newFilterGroups);
            let theFitlers = newFilterGroups.map(f=>f.filtering);
            let theFitlersNew = theFitlers.filter(e=>e).sort();
            theFitlersNew.unshift(everyregistry);
            setFullFilterSet(theFitlersNew);
            return false;
          }
        });
      }
    }
  }

  useEffect(()=>{
    if (previousField) {
      setInputField(previousField);
      setPreviousField();
    }
  },[previousField]);

  useEffect(()=>{
    if (inputField?.substr(0,5) === "null-") {//console.log("inputField",inputField)
      setInputField(inputField.substr(5));
    }
  },[inputField]);

  const changeImputField = (newImput,reseting) => {
    if (columnField !== undefined) {
      setSelectedColumn();
      setColumnField();
    }
    if (reseting) {
      setInputField("null-"+inputField);
    }
    else if (newImput !== inputField) setInputField(newImput);
    if (rowField) setRowField();
    if (globalTableSelected) setGlobalTableSelected();
  }

  const newImputField = (imputName,storedSessionData,selectedRowFields,element,column,rowsIDs,allRowsIDs,row,firstrow,type) => {
    let currentPos = selectedRowFields.indexOf(element);
    let filteredDatSet = datset.filter(e=>filteredRIDs.includes(e[0].rid));
    const changeValue = (e, test) => {
      let oldV = returnObjVal(imputName);
      if (e?.target?.value && e.target.value !== null) {
        if (oldV !== e.target.value) {
          if (filters.includes(element.id)) {
            setFilteringRows([...filteringRows].filter(e=>e!==firstrow.rid))
            //console.log("set filteringRows *3",[...filteringRows].filter(e=>e!==firstrow.rid))
          }
          if (type==="n") {
            if (isNaN(e.target.value.replace(',', '.')) || e.target.value.indexOf(",")>-1 || e.target.value.indexOf(".")>-1) {
              storeItem(imputName+"-s","red", false,3);
              ////storeItem(imputName+"-s","red");
            }
            else delObjVal(imputName+"-s");
          }
          else if (type==="d") {
            if (isNaN(e.target.value.replace(',', '.'))) storeItem(imputName+"-s","red", false,4);
            else delObjVal(imputName+"-s");
          }
          else if (type==="p") {
            if (isNaN(e.target.value.replace(',', '.')) || parseFloat(e.target.value.replace(',', '.')) < 0 || parseFloat(e.target.value.replace(',', '.')) > 100) {
              storeItem(imputName+"-s","red", false,5);
            }
            else delObjVal(imputName+"-s");
          }
          let newValue = (e.target.value) ? e.target.value : "";
          storeItem(imputName,newValue,true,6);
        }
      }
      else {
        delObjVal(imputName+"-s");
        delObjVal(imputName);
      }
      if (e?.target?.value !== oldV) { //console.log(e,keysdown)
        clickcheckRow(null,[allRowsIDs.indexOf(rowsIDs[row])],false, 3, null, element.id);
      }
      if (selectingFields) setInputField("");
    }
    const presskeydown = (d) => {
      if (d.keyCode === 90 || d.keyCode === 17) {
        setkeysdown([keysdown[1],d.keyCode]);
      }
      else if (((d.keyCode === 9 && !d.shiftKey) || (d.keyCode === 39 && d.target.selectionStart === d.target.value.length)) && currentPos+1<selectedRowFields.length) {
        changeValue(d,1);
        d.preventDefault();
        changeImputField(`${firstrow.rid}-${selectedRowFields[currentPos+1].id}`)
      }
      else if ((d.keyCode === 9 && d.shiftKey) || (d.keyCode === 37 && d.target.selectionStart === 0 && currentPos > 0)) {
        changeValue(d,2);
        d.preventDefault();
        changeImputField(`${firstrow.rid}-${selectedRowFields[currentPos-1].id}`)
      }
      else if (d.keyCode === 38 && row > 0) {
        changeValue(d,3);
        d.preventDefault();
        changeImputField(`${filteredDatSet[row-1][0].rid}-${element.id}`)
      }
      else if ((d.keyCode === 13 || d.keyCode === 40 ) && row+1 < filteredDatSet.length) {
        changeValue(d,4);
        d.preventDefault();
        changeImputField(`${filteredDatSet[row+1][0].rid}-${element.id}`)
      }
      else if (d.keyCode === 46) {
        d.preventDefault();
        let oldV = returnObjVal(imputName);
        setInputField("");
        changeValue(null,5);
        setPreviousField(`${filteredDatSet[row][0].rid}-${element.id}`)
      }
      else if (d.keyCode === 8) {
        if (d.target?.value && d.target?.selectionStart) {
          d.target.is8 = true;
          d.target.value = d.target.value.substr(0, d.target.selectionStart+1);
         }
      }
    }
    const displayTextOptions = (e) => {
      let currentVal = e.target.value;
      let currentLength = currentVal.length;
      if (currentVal.length > 0 && !e.target.is8) {
        let otherTexts = rowsIDs.filter(e=>firstrow.rid!==e).map((item, i) => {
          return returnObjVal(`${item}-${element.id}`);
        }).filter(e=>e?.substr(0,currentVal.length)===currentVal);
        if (otherTexts.length > 0) {
          e.target.value = otherTexts[0];
          e.target.setSelectionRange(currentLength,otherTexts[0].length);
        }
      }
      e.target.is8 = false;
    }
    return <TextField
              multiline={type==="t"}
              fullWidth={type!=="p"}
              type={(["f","f_from"].includes(type))?"date":"text"}
              onKeyDown={presskeydown}
              onBlur={(e)=>changeValue(e,6)}
              onChange={(e)=>displayTextOptions(e)}
              defaultValue={storedSessionData}
              onMouseLeave={(e)=>changeValue(e,7)}
              className={type!=="p" ? classes.yellow : classes.yellowShort}
              InputProps={type==="p" ? {endAdornment: `%`} : null}
              autoFocus={true}/>
  }
  const changeReviewedStatus = (rid,status) => {
    post(`/server/request/reviewupdate`,{wo:workOrder.work_report,id:rid,status:!status})
    .then((response) => {
      if (response?.set) {
        let newDatSet = [...datset].map((e,p)=>{
          if (e[0].rid === rid) e[0].reviewed = !status;
          return e;
        });
        setDatset(newDatSet);
      }
    });
  }
  const NewRow2IndicatorComponent = (row,rowdata) => {
    let newRow = [];
    let rowActivity = activity?.filter(e=>rowdata[0].rid===e.row);
    let rowPosRow = datset.map((e,p)=>{if (e[0].rid===rowdata[0].rid) return p}).filter(e=>!isNaN(e));
    let rowPos = (rowPosRow?.length > 0) ? rowPosRow[0] : null;
    newRow.push(
      <>
        <TableCell key={"main"+row+"-0B"} className={filteringRows.includes(rowdata[0].rid) ? classes.tableContentSimpleBlue : classes.tableContentSimple} onClick={(f)=>selectRow(rowdata,row,f)}>
          {row+1}
        </TableCell>
        <TableCell key={"main"+row+"-1Ca"} className={classes.tableContentShortHeaderUnder}>
          <Radio
            checked={rowdata[0].reviewed}
            onClick={()=>changeReviewedStatus(rowdata[0].rid,rowdata[0].reviewed)}
            value="b"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'B' }}
          />
        </TableCell>
        <TableCell key={"main"+row+"-1Cb"} className={classes.tableContentShort}>
          {Boolean(rowActivity?.length > 0) &&
            <IconButton key="send" variant="contained" onClick={()=>setDisplayActionRegistry(rowActivity)}>
              <AssignmentIcon/>
            </IconButton>
          }
        </TableCell>
      </>
    );
    newRow.push(
      <TableCell key={"main"+row+"-0"} className={classes.tableContentShort}>
        {!true && checkedRow[rowdata[0].rid]!=="self" && <isgrey/>}
        {!true &&
        <ArticleIcon className={classes.algin2middle}/>
        }
        {!rowdata[0].disabled && (checkedRow[rowdata[0].rid] !== "no" && checkedRow[rowdata[0].rid] !== "self") && userData.role < 50 &&
          <Tooltip title={"Bloqueada ("+employees.filter(f=>f.code === checkedRow[rowdata[0].rid])[0]?.name+")"}>
            <span  className={classes.padding9}>
              <LockPersonIcon/>
            </span>
          </Tooltip>
        }
        {!rowdata[0].disabled && (checkedRow[rowdata[0].rid] !== "no" && checkedRow[rowdata[0].rid] !== "self") && userData.role > 49 &&
          <Tooltip title={"Desbloquear ("+employees.filter(f=>f.code === checkedRow[rowdata[0].rid])[0]?.name+")"}>
            <span  className={classes.padding9}>
              <LockPersonIcon onClick={()=>undeleterow(rowdata[0].rid)} className={classes.pointer}/>
            </span>
          </Tooltip>
        }
        {rowdata[0].disabled &&
          <Tooltip title="Recuperar fila">
            <span  className={classes.padding9}>
              <RestoreFromTrashIcon onClick={()=>undeleterow(rowdata[0].rid)} className={classes.pointer}/>
            </span>
          </Tooltip>
        }
        {!rowdata[0].disabled && (checkedRow[rowdata[0].rid] === "no" || checkedRow[rowdata[0].rid] === "self") &&
            <span key={"row"+rowdata[0].rid} className={classes.padding9}>
              <Checkbox checked={checkedRow[rowdata[0].rid]==="self"} onChange={(e)=>clickcheckRow(e.target.checked,[rowPos],true,4)} className={classes.padding0}/>
            </span>
        }
      </TableCell>
    );
    return newRow;
  };

  const NewFullDatSetDisplay = () => {
    //const new_Dat_set = useMemo( [...datset], [datset] );
    let new_Dat_set =  [...datset];
    let testingA = []
    if (workOrder) {
      if (filterOptions.length > 0) {
        let a2z = filterOptions.map((e)=>e?.order==="a2z").map((e,pos)=>{if (e) return fieldNames[pos]}).filter((e)=>e);
        let z2a = filterOptions.map((e)=>e?.order==="z2a").map((e,pos)=>{if (e) return fieldNames[pos]}).filter((e)=>e);
        let textsItems = filterOptions.filter((e)=>e?.filter?.length > 0);
        let texts = filterOptions.map((e)=>e?.filter?.length > 0).map((e,pos)=>{if (e) return fieldNames[pos]}).filter((e)=>e);
        if (texts.length > 0) {
          new_Dat_set = datset.filter((a,p)=>{
            if (texts.length > 0) {
              let text = returnObjVal(`${a[0].rid}-${texts[0].id}`);
              if (!filteringRows.includes(a[0].rid)) return a;
              else if (text && textsItems[0]?.filter && text.toLowerCase().indexOf(textsItems[0].filter.toLowerCase()) > -1) return a;
              else if (newEmptyRow.includes(a[0].rid)) return a;
              else return;
              }
          });
        }
        if (a2z.length > 0 || z2a.length > 0) {
          let val, v1, v2, val1, val2;
          new_Dat_set = new_Dat_set.sort((a,b)=>{
            if (a2z.length > 0) {
              val = a2z[0];
              v1 = returnObjVal(`${a[0].rid}-${val.id}`);
              v2 = returnObjVal(`${b[0].rid}-${val.id}`);
            }
            else if (z2a.length > 0) {
              val = z2a[0];
              v2 = returnObjVal(`${a[0].rid}-${val.id}`);
              v1 = returnObjVal(`${b[0].rid}-${val.id}`);
            }
            if (val.type === "TEXT" || val.type === "DROPDOWN" || val.type === "IMAGE") {
              val1 = v1;
              val2 = v2;
            }
            else if (val.type === "NUMB") {
              val1 = parseInt(v1);
              val2 = parseInt(v2);
            }
            else if (val.type === "DECIM" || val.type === "PERCENT") {
              val1 = parseFloat(v1);
              val2 = parseFloat(v2);
            }
            else if (val.type === "DATE") {
              val1 = new Date(v1);
              val2 = new Date(v2);
            }
            else if (val.type === "GEOLOCATION") return 0;
            testingA.push([val1,val2,val1 === val2,val1 > val2,val1 < val2])
            if (val1 === val2) return 0
            else if (val1 > val2) return 1
            else if (val1 < val2) return -1
          });
        }
      }
    }
    let rowsIDs = new_Dat_set.map(e=>e[0].rid);
    let allRowsIDs = datset.map(e=>e[0].rid);//console.log("new_Dat_set",rowsIDs,allRowsIDs)
    if (rowsIDs && rowsIDs?.length !== filteredRIDs?.length && filteredRIDs?.toString() !== rowsIDs?.toString()) setFilteredRIDs(rowsIDs);
    return new_Dat_set.map((row, index)=> {
      const selectedRowFields = row.filter(e => categoriesID.includes(e.id));
      const thisRowChecked = checkedRow[row[0].rid];
      return (
        <TableRow key={"missingwo"+index} className={(rowField === row[0].rid) ? classes.tableRowSelected : (checkedRow[row[0].rid]!=="no" && checkedRow[row[0].rid]!=="self") ? classes.greybackground : null}>
          {NewRow2IndicatorComponent(index,row,new_Dat_set)}
          {NewRow2ContentComponent(index,row,rowsIDs,allRowsIDs,selectedRowFields,thisRowChecked)}
        </TableRow>
      )
      }
    )
  }

  const startClickMoving = (row,col,e) => {
    setSelectingFields(true);
    e.stopPropagation();
    setInitialClick([row,col]);
    setClickMovingR([row,row]);
    setClickMovingC([col,col]);
  }
  const continueClickMoving = (row,col,e) => {
    if (selectingFields) {
      e.preventDefault();
      let newRlow = (row < clickMovingR[0]) ? row : clickMovingR[0];
      let newRhigh = (row <= clickMovingR[0]) ? clickMovingR[0] : (row !== clickMovingR[1]) ? row : clickMovingR[1];
      let newClow = (col < clickMovingC[0]) ? col : clickMovingC[0];
      let newChigh = (col !== clickMovingC[1]) ? col : clickMovingC[1];

      if (row <= initialClick[0]) {
        newRlow = row;
        newRhigh = initialClick[0];
      }
      if (col <= initialClick[1]) {
        newClow = col;
        newChigh = initialClick[1];
      }
      setClickMovingR([newRlow, newRhigh]);
      setClickMovingC([newClow, newChigh]);
    }
  }
  const finalClick = (e) => {
    if (selectingFields) {
      setSelectingFields(false);
    }
  }
  const NewRow2ContentComponent = (index,row,rowsIDs,allRowsIDs,selectedRowFields,thisRowChecked) => {
    return selectedRowFields.map((e,column)=> {
      let cssplain = (rowField === row[0].rid) ? null : (cssfields && cssfields[column]) ? cssfields[column] : classes.tableContent;
      let imputName = `${row[0].rid}-${e.id}`;
      let currentIntup = Boolean(inputField === imputName);
      let storedSessionData = returnObjVal(imputName);
      let isonfocus = (currentIntup && e.t !== "s");
      let row2edit = (thisRowChecked==="no" || thisRowChecked==="self") ? true : false;
      let thisclassname =  (othersWorkingOn.includes(imputName)) ? classes.tableContentgreen :
                            (selectedColumn === e.pos ||
                              (
                                (clickMovingC[0] !== clickMovingC[1] || clickMovingR[0] !== clickMovingR[1]) &&
                                 row[0].rid>=clickMovingR[0] &&
                                 row[0].rid<=clickMovingR[1] &&
                                 e.id>=clickMovingC[0] && e.id<=clickMovingC[1]
                               )
                             ) ? classes.tableColumnSelected :
                              (isonfocus) ? classes.tableContentblue :
                                (returnObjVal(`${row[0].rid}-${e.id}-s`) === "red") ? classes.tableContentred :
                                  (row2edit) ? cssplain : null;

      return <TableCell
          key={"main"+index+"-"+column}
          onClick={()=>changeImputField(imputName)}
          onMouseDown={(f)=>startClickMoving(row[0].rid,e.id,f)}
          onMouseUp={(f)=>finalClick(f)}
          onMouseOver={(f)=>continueClickMoving(row[0].rid,e.id,f)}
          className={thisclassname}
          >
          {currentIntup && row2edit && e.t !== "s" && e.t !== "g" && e.t !== "i" && newImputField(imputName,storedSessionData,selectedRowFields,e,column,rowsIDs,allRowsIDs,index,row[0],e.t)}
          {currentIntup && row2edit && e.t === "s" && <>{SelectView(selectedRowFields,e,column,rowsIDs,allRowsIDs,index, row[0])}</>}
          {row2edit && (!currentIntup || e.t === "g" || e.t === "i") && displayFlatText(e,storedSessionData,imputName, row[0],null,index,selectedRowFields, isonfocus)}
          {!row2edit && displayFlatText(e,storedSessionData,imputName, row[0], "grey", index, selectedRowFields, isonfocus)}
        </TableCell>
      }
    )
  }

  const selectCategories = (e,type,subtitlename,headerChecked) => {
    if (workOrder?.work_report) {
      setcssfields();
      let allCategories, allCatIDs;
      if (type === "subheader" && subtitlename) {
        let prevVals = dropdownRef?.current?.textContent?.split(", ");
        if (prevVals) {
          if (prevVals.length === 1 && prevVals[0] === "") prevVals.prop();
          else prevVals[prevVals.length-1]=prevVals[prevVals.length-1].substr(0,prevVals[prevVals.length-1].length-1);
          if (!headerChecked) {
            let fieldFiltered = fieldNames.filter((f)=>subtitlename === f.category || prevVals.includes(f.name));
            allCategories = fieldFiltered.map(f=>f.name);
            allCatIDs = fieldFiltered.map(f=>f.id);
            }
          else {
            let fieldFiltered = fieldNames.filter((f)=>subtitlename !== f.category && prevVals.includes(f.name));
            allCategories = fieldFiltered.map(f=>f.name);
            allCatIDs = fieldFiltered.map(f=>f.id);
            }
          }
      }
      else if (e.target.value.includes("*all*")) {
        allCategories = fieldNames.map((f)=>f.name);
        allCatIDs = fieldNames.map((f)=>f.id);
      }
      else if (e.target.value.includes("*none*")) {
        allCategories = [];
        allCatIDs = [];
      }
      else {
        allCategories = e.target.value;
        allCatIDs = fieldNames.filter((f)=>e.target.value.includes(f.name)).map(f=>f.id);
      }
      post(`/server/request/visprodcat`,{wo:workOrder.work_report,data2set:{cat:allCategories, ids:allCatIDs}})
      .then((response) => {
        if (response?.set) {
          setCategories(allCategories);
          setCategoriesID(allCatIDs);
        }
      });
    }
  };
  const introWorkOrder = (d) => {
    if (d.keyCode === 13) {
      let valueV = d.target.value;
      let subSet = workOrders.filter(e=>e.work_report.substr(0,valueV.length)===valueV);
      if (subSet.length === 1) selectWorkOrder(null, subSet[0])
    }
  }
  const selectWorkOrder = (event, newValue) => {
    setIsCircleProgress(true);
    setIsNewWO(true);
    setMultiFilter([everyregistry]);
    setFilteringRows([]);
    setFilterOptions([]);
    setWorkOrder(newValue);
    if (prevWorkOrder) clearData();
    let newWO = (newValue?.work_report) ? newValue.work_report : "null";
    if (newValue?.work_report) sendMessage(`wo:${newValue.work_report}`)
    else sendMessage(`wo:null`)
  }

  useEffect(()=>{
      if (ws_id && workOrder?.work_report && current_ws_id !== ws_id) {
        setcurrent_ws_id(ws_id);
        sendMessage(`wo:${workOrder.work_report}`);
      }
  },[ws_id, workOrder])

  const addNewRow = (id) => {
    let currentLength = parseInt(datset.length);
    addNewRow2list(true,null,currentLength,null,1)
  }

  const savedata = (incomingDataSet,dataArray,oldinputField) => {
    setPrevWorkOrder();
    let ids = [];
    let rid;
    let newDatSet = (incomingDataSet) ? incomingDataSet : datset;
    let rowfilters = [];
    let wofilterID;
    let ridNames = filters.map(filter=>fieldNames.filter(f=>f.id===filter).map(e=>e.name));
    let startingRowPos, rowCounter = 0, colCounter = 0;
    let isSingleField = Boolean(clickMovingR[0] === clickMovingR[1]);
    let startRow, startColumn, startPos;
    let clickMovingC1IndexArray = incomingDataSet[0]?.filter(e=>e.id === clickMovingC[1]);
    let clickMovingC1Index = clickMovingC1IndexArray[0]?.pos;
    let finalposTotal = (dataArray[0]?.length > 0) ? clickMovingC1Index + dataArray[0].length : clickMovingC1Index;
    let finalposVal = (finalposTotal > incomingDataSet.length) ? incomingDataSet.length - 1 : finalposTotal;
    let finalposIndexArray = incomingDataSet[0]?.filter(e=>e.pos === finalposVal);
    let finalpos = finalposIndexArray[0]?.pos;
    let new_Dat_set = [...newDatSet].filter((e,index0)=>{
      if (globalTableSelected || columnField) return true;
      else if (rowField && e[0].rid === rowField) return true;
      else if (e[0].rid >= clickMovingR[0] && e[0].rid <= clickMovingR[1]) {
        if (isSingleField) {
          startRow = e[0].rid;
          startColumn = clickMovingC[0];
          startPos = index0;
        }
        return true;
      }
      else if (!rowField && oldinputField && e[0].rid === oldinputField.split("-")[0]) return true;
      else if (isSingleField && !isNaN(dataArray?.length) && startRow <= e[0].rid && index0 <= startPos + dataArray.length) return true;
      else return false;
    }).map((f,index)=>f.map((e,pos)=>{
      if (pos === 0) {
        rid = e.rid;
        ids.push(e.rid);
      }
      if (filters.includes(e.id)) rowfilters.push(e.id);
      if (wOfilter === e.id.toString()) wofilterID = e.id;
      if (rid && categoriesID.includes(e.id) && (
          globalTableSelected ||
          rowField ||
          columnField === e.id ||
          e.id === oldinputField?.split("-")[1] ||
          (e.id >= clickMovingC[0] && e.id <= finalpos) ||
          (isSingleField && startRow <= rid && startColumn <= e.id)
        )) {
        let newItem = {
            id: e.id,
            value: returnObjVal(`${rid}-${e.id}`)
          }
        return newItem;
      }
    }).filter((g)=>g)); //console.log(new_Dat_set)
    let data = {
      wo:workOrder.work_report,
      dataset:new_Dat_set,
      ids:ids,
  //    filters:rowfilters,
  //    wofitler:wOfilter
    }
    data.filters = filters;
    data.wofilter = wOfilter;
    post(`/server/request/saveproddata`,data)
    .then((response) => {
      if (response.data) setIsSaved(true);
      setInputField(oldinputField);
    });
  }

  const selectFile = (event) => {
    const removeSpecialChar = (name) => name.normalize('NFD').replace(/[^a-zA-Z0-9\-\s]/g, '');
    setPendingCircular(true);
    let formData = new FormData();
    for (let i=0; i<event.target.files.length; i++) formData.append("proddocument", event.target.files[i], removeSpecialChar(event.target.files[i].name));
    formData.append("rows", [0,1,2]);
    formData.append("wo", workOrder.work_report);
    formData.append("desc", "asdf");
    postPlain('/server/request/proddocument',formData,{'enctype': 'multipart/form-data'}).then((response)=>{
      if (response.docs) {
        setPendingCircular(false);
        setDocumentsList(response.docs);
      }
    })
  }

  const categoryItems = () => {
    let allItems = [];
    categoryTypes.forEach((name,i) =>
      {
        let headerChecked = fieldNames.filter((f)=>name === f.category).filter(f=>categories.indexOf(f.name) === -1).length === 0;
        allItems.push(
          <ListSubheader key={name} className={classes.dropdownmenutitle}>
            <Checkbox checked={headerChecked} onChange={(e)=>selectCategories(e,"subheader",name,headerChecked)} />
            {name}
          </ListSubheader>
        );
        fieldNames.forEach((fieldname,j)=> {
          if (fieldname.category === name) allItems.push(
            <MenuItem key={name+i+j+fieldname.name} value={fieldname.name}>
              <Checkbox checked={categories.indexOf(fieldname.name) > -1} />
              <ListItemText primary={fieldname.name} />
            </MenuItem>
            )}
        )
      }
    );
    return allItems;
  }
  const fileDescriptionChange = (e) => {
    let addingDocumentNew = {...addingDocument};
    addingDocumentNew.description = e.target.value;
    setAddingDocument(addingDocumentNew);
  }
  const privateFile = (e,f) => {
    let addingDocumentNew = {...addingDocument};
    addingDocumentNew.private = f;
    setAddingDocument(addingDocumentNew);
  }
  const cancelDocument = () => {
    if (addingDocument.id) deleteData(`/server/request/deldocument?wo=${workOrder.work_report}`,addingDocument)
    .then((response) => {
      if (response.docs) setDocumentsList(response.docs);
      setAddingDocument({private:false});
      setOpenDocumentDialog(false);
    });
  }
  const saveDocument = () => {
    if (addingDocument.id) {
      let currentDocVals = {...addingDocument}
      currentDocVals.wo = workOrder.work_report;
      post(`/server/request/updateproddocument?wo=${workOrder.work_report}`,currentDocVals)
      .then((response) => {
        if (response.changed) {
          if (response.docs) setDocumentsList(response.docs);
          let cleanDoc = {private:false};
          setAddingDocument(cleanDoc);
          setOpenDocumentDialog(false);
        }
      });
    }
  }

  const editFile = (pos) => {
    setOpenDocumentDialog(pos+1);
    setAddingDocument(documentsList[pos]);
  }

  const changeFilterOptions = (a) => {
    setFilteringRows(datset.map(e=>e[0].rid));//console.log("set filteringRows *4",datset.map(e=>e[0].rid))
    let realColumn = columnFilter-1;
    let newFilterOptions = [...filterOptions];
    if (!filterOptions[realColumn]) newFilterOptions[realColumn] = {};
    if (a.target.value) newFilterOptions[realColumn].order = a.target.value;
    else delete newFilterOptions[realColumn].order;
    if (newFilterOptions.filter(e=>e).length === 0) newFilterOptions = [];
    setFilterOptions(newFilterOptions);
    setNewEmptyRow([]);
  }
  const changeFilterSearch = (a) => {
    setFilteringRows(datset.map(e=>e[0].rid));//console.log("set filteringRows *5",datset.map(e=>e[0].rid))
    let realColumn = columnFilter-1;
    let newFilterOptions = [...filterOptions];
    if (!filterOptions[realColumn])  newFilterOptions[realColumn] = {};
    if (a.target.value) newFilterOptions[realColumn].filter = a.target.value;
    else delete newFilterOptions[realColumn].filter;
    if (newFilterOptions.filter(e=>e).length === 0) newFilterOptions = [];
    setFilterOptions(newFilterOptions);
    setNewEmptyRow([]);
  }
  const clearFilterOptions = () => {
    let realColumn = columnFilter-1;
    let newFilterOptions = [...filterOptions];
    newFilterOptions.splice(realColumn, 1);
    if (newFilterOptions.filter(e=>e).length === 0) newFilterOptions = [];
    setFilterOptions(newFilterOptions);
    setColumnFilter(false);
    setFilteringRows([]);
  }

  const addData2Set = (newData2Set,storeinvariable,num) => {
    if (newData2Set) {
      let new2Data2Set = newData2Set.map((g)=>g.map((e,pos)=>{
        e.pos = pos;
        delete e.value;
        return e;
      }));
      if (storeinvariable) setDatset(new2Data2Set);
      else return new2Data2Set;
    }
  }

  useEffect((e)=> {
    if (inputField !== prevInput_Field) {
      if (workOrder?.work_report) sendMessage(`if:${inputField}//wo:${workOrder.work_report}`);
      setPrevInput_Field(inputField)
    }
  },[inputField]);

  const customDataFeed = () => {
    let newData = datset.map((e,pos)=> {
      return e.map((f,pos2)=>{
        f.value = returnObjVal(`${e[0].rid}-${f.id}`)
        return f;
      })
    });
    return newData
  }

  const selectAllTable = (clear) => {
    if (inputField) setInputField("");
    if (rowField) setRowField();
    if (columnField) setColumnField();
    setSelectedColumn();
    if (clear) setGlobalTableSelected(false);
    else setGlobalTableSelected(!globalTableSelected);
  }

  const selectColumn = (row,val,element) => {
    setSelectedColumn(row);
    if (globalTableSelected) setGlobalTableSelected();
    if (columnField === val.id) {
      setColumnField();
      setSelectedColumn();
    }
    else {
      if (inputField) setInputField("");
      if (rowField) setRowField();
      setColumnField(val.id);
      }
  }

  const selectRow = (row,val,e) => {
    if (globalTableSelected) setGlobalTableSelected();
    if (rowField === row[0].rid) setRowField();
    else {
      if (inputField) setInputField("");
      if (columnField) setColumnField();
      if (row[0].rid) setRowField(row[0].rid);
      setSelectedColumn();
    }
  }

  const addNewData2Fromevent = (dataArray,incomingDataSet,oldinputField) => {//console.log(dataArray,incomingDataSet,oldinputField,clickMovingC,clickMovingR)
      let rowCount = 0;
      let colCount = 0;
      let colFilled = 0;
      let startingPos;
      let depth = (dataArray && dataArray[0]) ? (dataArray.length * dataArray[0].length) : 0;
      incomingDataSet.forEach((e,index1)=>{
        let selectedRowFields = e.filter(currentrow => categoriesID.includes(currentrow.id));
        let clickMovingC0IndexArray = selectedRowFields.filter(e=>e.id===clickMovingC[0]);
        let clickMovingC1IndexArray = selectedRowFields.filter(e=>e.id===clickMovingC[1]);
        let clickMovingC0Index = (clickMovingC0IndexArray.length > 0) ? clickMovingC0IndexArray[0].pos-1 : 0;
        let clickMovingC1Index = (clickMovingC1IndexArray.length > 0) ? clickMovingC1IndexArray[0].pos-1 : 0;
        selectedRowFields.forEach((f,index2)=>{
          if (checkedRow[e[0].rid] !== "yes") {
            let newVal;
            if (f.t === "f_wo") newVal = null;
            else if (globalTableSelected && dataArray[index1] && dataArray[index1][index2] !== undefined) newVal = dataArray[index1][index2]; // FULL TABLE PASTE
            else if (rowField && e[0].rid === rowField && dataArray[0][index2] !== undefined) newVal = dataArray[0][index2]; //FULL ROW PASTE
            else if (columnField && dataArray.length === 1 && dataArray[0].length === 1 && f.id === columnField) newVal = dataArray[0][0]; // FULL COLUMN PASTE FROM SINGLE FIELD
            else if (columnField && f.id === columnField && dataArray.length > index1 && dataArray[index1].length > 0) newVal = dataArray[index1][0]; // FULL COLUMN PASTE FROM MULTIPLE FIELDS
            else if (oldinputField && `${e[0].rid}-${f.id}` === oldinputField) { // SINGLE FIELD FIRST
                  newVal = dataArray[0][0];
                  startingPos = {row: index1, col: index2};
                  if (dataArray[0].length > 1 && e.length > index2+1) colCount++;
                  else if (dataArray.length > 1 && incomingDataSet.length > index1+1) rowCount++;
            }
            else if (oldinputField && (index1+1 > startingPos?.row + rowCount && index2+1 > startingPos?.col + colCount && (rowCount+1)*(colCount+1)<=depth && (depth > 1 || dataArray.length > 1)) && `${e[0].rid}-${f.id}` !== oldinputField) { // SINGLE FIELD NEXT
                  if (dataArray[rowCount]?.length > colCount+1 && e.length > startingPos.col+colCount+1) {
                    newVal = dataArray[rowCount][colCount];
                    colCount++;
                  }
                  else if (dataArray.length > rowCount && incomingDataSet.length > startingPos.row+rowCount) {
                    newVal = dataArray[rowCount][colCount];
                    colCount = 0;
                    rowCount++;
                  }
                }
            else if (clickMovingC[0] !== clickMovingC[1] || clickMovingR[0] !== clickMovingR[1]) {
              if (colFilled < dataArray.length || dataArray.length === 1) {
                let lastpos = (selectedRowFields.length > clickMovingC1Index+dataArray[0].length) ? clickMovingC1Index+dataArray[0].length : selectedRowFields.length-1;
                if (selectedRowFields[lastpos].id >= f.id && clickMovingC[0] <= f.id && clickMovingR[1] >= e[0].rid && clickMovingR[0] <= e[0].rid) {
                  newVal = dataArray[rowCount][colCount];
                  console.log(selectedRowFields[lastpos].id,f.id,newVal,rowCount,colCount)
                  if (selectedRowFields[lastpos].id >= f.id) colCount++;
                  else {
                    colCount = 0;
                    if (dataArray.length > rowCount+1) rowCount++;
                    colFilled++;
                  }
                }
              }
            }
            if (newVal !== null && typeof newVal !== "undefined" && (!f.options || f.options.includes(newVal))) {
              let prevVal = returnObjVal(`${e[0].rid}-${f.id}`);
              storeItem(`${e[0].rid}-${f.id}`, newVal,true,7);
              if (f.t === "n" && isNaN(newVal.replace(',', '.'))) {
                storeItem(`${e[0].rid}-${f.id}-s`,"red", false,8);
              }
              else delObjVal(`${e[0].rid}-${f.id}-s`);
            }
          }
        });
      });
      savedata(incomingDataSet,dataArray,oldinputField);
  }

  const pasteExistingState = async (this_event) => {
    let oldinputField = inputField.toString();
    setInputField("");
    let pastedData;
    let rowPosArray = datset.map((e,index)=>{if (e[0].rid===rowField) return index}).filter(e=>!isNaN(e));
    let rowPos = (rowPosArray) ? rowPosArray[0] : null;
    let fromOtherSources;
    let readClipboard = await navigator.clipboard.read();
    for (const item of readClipboard) {
      for (const mimeType of item.types) {
        if (mimeType === "text/html" || mimeType === "text/plain") {
          const blob = await item.getType("text/plain");
          fromOtherSources = await blob.text();
        }
      }
    }
    if (
        (globalTableSelected ||
          (rowField && (checkedRow[rowField] === "no" || checkedRow[rowField] === "self")) || columnField || oldinputField || (clickMovingC[0] !== clickMovingC[1] || clickMovingR[0] !== clickMovingR[1])
         ) &&
         (fromOtherSources || clipboardText)
       ) {
      this_event.stopPropagation();
      this_event.preventDefault();
      pastedData = (fromOtherSources) ? fromOtherSources : (clipboardText) ? clipboardText : null; //console.log("**",fromOtherSources,"**",clipboardText,"**",pastedData,"**")
      if (!fromOtherSources && clipboardText) await navigator.clipboard.writeText(clipboardText);
      let dataArraySplit = pastedData.split('\r\n');
      if (dataArraySplit[dataArraySplit.length-1].length === 0) dataArraySplit.pop();
      let dataArrayWithNulls = dataArraySplit.map((e)=>e.split('\t'));
      let dataArray = dataArrayWithNulls.map(row => row.map(column => {
        if (column === "null") return "";
        else return column;
      }));
      if (pastedData) {
        let starting_line = 0;
        let starting_rid = oldinputField.split("-");
        if (starting_rid[0]?.length > 0) {
          starting_line = datset.reduce((a,c,p)=>{
            if (c[0]?.rid?.toString() === starting_rid[0]) return p;
          },0)
        }
        if (datset.length - starting_line >= dataArray.length) addNewData2Fromevent(dataArray,datset,oldinputField);
        else {
          let rows2add = dataArray.length - datset.length + starting_line;
          post(`/server/request/addnewprodrow`,{wo:workOrder.work_report,num:rows2add,mssg:0,filters:filters})
          .then((response) => {
            if (response.newids) {
              let newcheckedRow = {...checkedRow};
              let currentLength = parseInt(datset.length);
              //let obj = {}
              let newitems = response.newids.map((item, index) => {
                newcheckedRow[item.rid] = "self";
                let element = {
                  id: item,
                  content: [],
                  locked: "self"
                }
                let responseObj = addNewRow2list(false,null,currentLength+index,element,1);
                setNewEmptyRow([...newEmptyRow, responseObj.rid]);
                //obj = {...obj, ...responseObj.obj};
                return responseObj.newSetNoVal;
              });
              //setAllVals(obj);
              let newdatset = [...datset, ...newitems];
              addNewData2Fromevent(dataArray,newdatset,oldinputField);
              setCheckedRow(newcheckedRow);
            }
          });
        }
      }
    }
    //changeImputField(prevInput_Field,true);
  }

  const cutExistingState = async (e) => {
    if (globalTableSelected || (rowField && (checkedRow[rowField] === "no" || checkedRow[rowField] === "self")) || columnField || inputField || (clickMovingC[0] !== clickMovingC[1] || clickMovingR[0] !== clickMovingR[1])) {
      e?.stopPropagation();
      e?.preventDefault();
      let dataText = "";
      let newhistory = [];
      //let obj = {...allVals};
      datset.forEach((rowD,index1)=>{
        rowD.forEach((f,index2)=>{
          let cutData = false;
          let imputName = `${rowD[0].rid}-${f.id}`;
          if (globalTableSelected) {
            dataText += returnObjVal(imputName);
            dataText += (index2+1 === rowD.length) ? '\r\n' : '\t';
            cutData = true;
          }
          else if (rowField && rowD[0].rid === rowField) {
            dataText += returnObjVal(imputName) + '\t';
            cutData = true;
          }
          else if (columnField && f.id === columnField) {
            dataText += returnObjVal(imputName) + '\r\n';
            cutData = true;
          }
          else if (inputField && imputName === inputField) {
            dataText = returnObjVal(imputName);
            cutData = true;
          }
          else if (clickMovingC[0] !== clickMovingC[1] || clickMovingR[0] !== clickMovingR[1]) {
            if (clickMovingC[1] >= f.id && clickMovingC[0] <= f.id && clickMovingR[1] >= rowD[0].rid && clickMovingR[0] <= rowD[0].rid) {
              dataText += returnObjVal(imputName);
              dataText += (clickMovingC[1] === f.id) ? '\r\n' : '\t';
              cutData = true;
            }
          }
          if (cutData) {//console.log("cutdata",imputName)
            newhistory.push({id:imputName,p:returnObjVal(imputName),n:""})
            storeItem(imputName,"",false,9);
          }
          //console.log("dataText",dataText,cutData)
        });
      });
      if (dataText) await navigator.clipboard.writeText(dataText);
      setClipboardText(dataText);
      savedata();
      if (e.type==="cut") changeImputField(null,true)
    }
  }

  const copyExistingState = async (e) => {
    if (globalTableSelected || rowField || columnField || inputField || (clickMovingC[0] !== clickMovingC[1] || clickMovingR[0] !== clickMovingR[1])) {
      e.stopPropagation();
      e.preventDefault();
      let dataText = "";
      datset.forEach((rowD,index1)=>{
        let selectedRowFields = rowD.filter(currentrow => categoriesID.includes(currentrow.id));
        selectedRowFields.forEach((f,index2)=>{
          if (globalTableSelected) {
            dataText += returnObjVal(`${rowD[0].rid}-${f.id}`);
            dataText += (index2+1 === rowD.length) ? '\r\n' : '\t';
          }
          else if (clickMovingC[0] !== clickMovingC[1] || clickMovingR[0] !== clickMovingR[1]) {
            if (clickMovingC[1] >= f.id && clickMovingC[0] <= f.id && clickMovingR[1] >= rowD[0].rid && clickMovingR[0] <= rowD[0].rid) {
              dataText += returnObjVal(`${rowD[0].rid}-${f.id}`);
              dataText += (clickMovingC[1] === f.id) ? '\r\n' : '\t';
            }
          }
          else if (rowField && rowD[0].rid === rowField) dataText += returnObjVal(`${rowD[0].rid}-${f.id}`) + '\t';
          else if (columnField && f.id === columnField) dataText += returnObjVal(`${rowD[0].rid}-${f.id}`) + '\r\n';
          else if (inputField && `${rowD[0].rid}-${f.id}` === inputField) dataText = returnObjVal(`${rowD[0].rid}-${f.id}`);
        });
      });//console.log("dataText",dataText)
      if (dataText) await navigator.clipboard.writeText(dataText);
      setClipboardText(dataText);
    }
  }

  const useEventListener = (eventName, functionToCall, element) => {
    const savedFunction = React.useRef();

    useEffect(() => {
      savedFunction.current = functionToCall;
    }, [functionToCall]);

    useEffect(() => {
      if (!element) return;
      const eventListener = (event) => savedFunction.current(event);
      element.addEventListener(eventName, eventListener);
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    }, [eventName, element]);
  }

  useEventListener("paste", pasteExistingState, window);
  useEventListener("copy", copyExistingState, window);
  useEventListener("cut", cutExistingState, window);

  const displayFlatText = (e,val,imputName, firstrow, classType, row, selectedRowFields, isonfocus) => {
    let currentPos = selectedRowFields.indexOf(e);

    const presskeydown = (d) => {
      if (((d.keyCode === 9 && !d.shiftKey) || d.keyCode === 39) && currentPos+1<selectedRowFields.length) {
        d.preventDefault();
        changeImputField(`${firstrow.rid}-${selectedRowFields[currentPos+1].id}`)
      }
      else if ((d.keyCode === 9 && d.shiftKey) || (d.keyCode === 37 && currentPos > 0)) {
        d.preventDefault();
        changeImputField(`${firstrow.rid}-${selectedRowFields[currentPos-1].id}`)
      }
      else if (d.keyCode === 38 && row > 0) {
        d.preventDefault();
        changeImputField(`${datset[row-1][0].rid}-${e.id}`)
      }
      else if (d.keyCode === 40 && row+1 < datset.length) {
        d.preventDefault();
        changeImputField(`${datset[row+1][0].rid}-${e.id}`)
      }
      else if (d.keyCode === 46) {
        d.preventDefault();
        cutExistingState();
      }
    }

    if (val && e.t==="n") {
      if (isNaN(val.replace(',', '.')) || val.indexOf(",")>-1 || val.indexOf(".")>-1) {
        storeItem(imputName+"-s","red", false,10);
      }
      else delObjVal(imputName+"-s");
      val = <span className={classes.end}>{val}</span>
    }
    else if (val && e.t==="d") {
      if (isNaN(val.replace(',', '.'))) {
        storeItem(imputName+"-s","red", false,11);
      }
      else delObjVal(imputName+"-s");
      val = <span className={classes.end}>{val}</span>
    }
    else if (val && e.t==="p") {
      if (isNaN(val.replace(',', '.')) || parseFloat(val.replace(',', '.')) < 0 || parseFloat(val.replace(',', '.')) > 100) {
        storeItem(imputName+"-s","red", false,12);
      }
      else delObjVal(imputName+"-s");
      val = <span className={classes.end}>{val}%</span>
    }
    else if (val && ["f","f_from"].includes(e.t)) {
      let newVal = val.split("-");
      if (newVal.length === 3) val = `${newVal[2]}/${newVal[1]}/${newVal[0]}`;
     }
    else if (val && e.t === "i") {
      let allImgs = (val.length>0) ? val.split(",") : [];
      val = <div className={classes.rowImg}>
          {allImgs?.map(img=>
            <div className={classes.columnImg}>
              <a target="_img" href={`/server/request/docdownload?id=${encodeURIComponent(img)}`}>
                <img alt="" src={`/server/request/docdownload?id=${img}`} className={classes.minimg}/>
              </a>
            </div>
            )}</div>
    }
    else if (val && e.t === "g") {
      val = <a target="_map" className={classes.nodecor} href={`https://www.google.com/maps/place/${val.split("&")[0]},${val.split("&")[1]}`}>
              <MapIcon/>
            </a>
    }
    let styleClassName = classes.unselectable;
    if (classType === "grey" &&
      (e.t === "n" || e.t === "d" || e.t === "p")
    ) styleClassName = classes.endflowgrey;
    else if (classType === "grey") styleClassName = classes.grey;
    //else if (e.t === "n" || e.t === "d" || e.t === "p") styleClassName = classes.endflow;
    if (isonfocus) document.getElementById(imputName)?.focus();
    return <div onKeyDown={presskeydown} id={imputName} tabIndex="1" className={classes.no_outline}><span className={styleClassName}> {val} </span></div>
  }

  const openFilterDialog = (row) => {
    setColumnFilter(row+1);
    setGlobalTableSelected();
    setSelectedColumn();
  }

  const displaySelectedHeaders = () => {
    let widths = (!biggestWidth) ? [] : biggestWidth;
    let widthChange = false;
    let cssFieldType = [];
    let headersCells = fieldNames.map((e,row)=> {
      let newWidth = pasteRef?.current?.children[0]?.firstElementChild?.children[0]?.children[row]?.clientWidth;
      if (!biggestWidth || newWidth > biggestWidth[row]) {
        widths[row] = (!newWidth) ? 85 : newWidth;
        widthChange = true;
      }
      let cssCategoryType = (categoryTypes.indexOf(e.category) % 4 === 0) ? classes.nowrap4 : (categoryTypes.indexOf(e.category) % 3 === 0) ? classes.nowrap3 : (categoryTypes.indexOf(e.category) % 2 === 0) ? classes.nowrap2 : classes.nowrap;
      if (categoriesID.includes(e.id)) {
        cssFieldType.push((categoryTypes.indexOf(e.category) % 4 === 0) ? classes.tableContent4 : (categoryTypes.indexOf(e.category) % 3 === 0) ? classes.tableContent3 : (categoryTypes.indexOf(e.category) % 2 === 0) ? classes.tableContent2 : classes.tableContent);
        return (
          <TableCell
            style={{width: `${biggestWidth ? biggestWidth[row+2] : 85}px`,minWidth:'85px',height: "53px"}}
            key={"header"+row}
            align="center"
            onMouseOver={()=>setShowFilterColumn(row)}
            onMouseOut={()=>setShowFilterColumn()}
            className={(selectedColumn === row) ? classes.nowrapblue : cssCategoryType}>
              <span onClick={(f)=>selectColumn(row,e,f)} className={classes.pointer}>
                <strong>{e.name}</strong>
              </span>
              {((filterOptions.length === 0 && showFilterColumn === row) || (filterOptions[row] && (filterOptions[row].filter || filterOptions[row]?.order))) &&
                <IconButton key="dispaly" variant="contained" onClick={()=>openFilterDialog(row)} className={(filterOptions[row] && (filterOptions[row].filter || filterOptions[row]?.order)) ? classes.filter_blue : classes.filter_grey}>
                  <FilterAltIcon/>
                </IconButton>
              }
              {1===0 && !(showFilterColumn === row || (filterOptions[row] && (filterOptions[row].filter || filterOptions[row]?.order))) &&
                <IconButton key="dispaly" variant="contained">
                  <FilterAltIcon className={selectedColumn === row ? classes.lightblue : classes.white} />
                </IconButton>
              }
          </TableCell>
        )
      }
    }
    );
    if (!cssfields || cssfields.length !== cssFieldType.length) setcssfields(cssFieldType);
    if (widthChange) setBiggestWidth(widths);
    return headersCells;
  }
  const newColumnFilter = (e, newValue) => {
    setColumnFilter(fieldNames.indexOf(newValue)+1);
  }
  const pagination = () => {
    return <Grid item xs={isMobileSize ? 12 : displayDocuments ? 8 : 5}/>
  }
  const splitLastOccurrence = (str, substring, gap = 1, prevVal = []) => {
    const lastIndex = str.lastIndexOf(substring);
    const before = str.slice(0, lastIndex);
    const after = str.slice(lastIndex + gap);
    if (before.indexOf(" -- ") === -1) return [before, after, ...prevVal];
    else return splitLastOccurrence(before, substring, gap, [after, ...prevVal])
  }
  const createMultipleFilters = (e, newValue, skipcycles) =>{//console.log("createMultipleFilters",e, newValue, skipcycles)
    setIsCircleProgress(true);
    if (newValue && newValue !== everyregistry[0]) {
      let newFilters = (newValue) ? splitLastOccurrence(newValue," -- ",4) : null;//console.log("newFilters",newFilters,newValue !== everyregistry[0],newValue, everyregistry[0])
      setMultiFilter(newFilters);
      let existingIDs = datset.map(e=>e[0].rid);
      setFilteringRows(existingIDs);//console.log("set filteringRows *6",datset.map(e=>e[0].rid))
      setNewEmptyRow([]);
      let filters2set = filterGroups.filter(e=>e.filtering?.join("-")===newFilters?.join("-"));
      if (filters2set.length > 0 && serverFiltering && skipcycles !== "noreload") {
        retriveProdData(6,null,null,filters2set[0]?.filters,existingIDs);
      }
    }
    else {
      setMultiFilter([everyregistry]);
      setFilteringRows([]);
      if (serverFiltering && skipcycles !== "noreload") {
        retriveProdData(7,null,null,"all");
      }
    }
  }
  const getDisabledItems = () => {
    let previncludeDisabled = !includeDisabled;
    setIncludeDisabled(previncludeDisabled);
    let filters2set = filterGroups.filter(e=>e.filtering?.join("-")===multiFilter?.join("-"));
    retriveProdData(8,null,previncludeDisabled,filters2set[0]?.filters);
  }
  const go2firstfield = (e) => {//console.log("go2firstfield",e)
/*
    if (e.key) {
      let theFitlersNew = [...fullFilterSet];
      if (theFitlersNew[0] === everyregistry[0]) theFitlersNew.shift();
      setFullFilterSet(theFitlersNew.filter(f=>f.includes(e.key)));
    }
    let newfilteredRIDs = (filteredRIDs) ? filteredRIDs : datset;
    if (newfilteredRIDs) {
      let filteredDatSet = datset.filter(e=>newfilteredRIDs.includes(e[0].rid));
      if (filteredDatSet.length>0 && filteredDatSet[0].length>0 && filteredDatSet[0][0].rid && filteredDatSet[0][0].id && categoriesID) {
        let rid = filteredDatSet[0][0].rid;
        let filterFilterDatSet = filteredDatSet[0].filter(currentrow => categoriesID.includes(currentrow.id));
        changeImputField(`${rid}-${filterFilterDatSet[0].id}`);
      }
    }
    */
  }
  const displayFilters = () => {
    //let rids = datset.map(f=>f[0].rid).filter(e=>e);
    //let exitingSets = rids.map(rid=>filters.map(filter =>returnObjVal(`${rid}-${filter}`)).join(" -- "));
    //let fullValSet = exitingSets.reduce((acc, curr) => acc.includes(curr) ? acc : [...acc, curr], []);
    let ridNames = filters.map(filter=>fieldNames.filter(f=>f.id===filter).map(e=>e.name));//console.log("fullFilterSet",fullFilterSet)
    return <Autocomplete
            className = {multiFilter?.length>0 ? classes.selectBlue : null}
            disablePortal
            fullWidth
            id="combo-box-filterVals"
            onChange={createMultipleFilters}
            onKeyDown={go2firstfield}
            options={fullFilterSet?.map(e=>e.join(" -- "))}
            getOptionLabel={(option) => option}
            value={multiFilter ? multiFilter.join(" -- ") : everyregistry}
            renderInput={(params) => <TextField {...params} label={`filtro ${ridNames.join(" -- ")}`} placeholder="comienza a teclear el nombre..."/>}
          />
  }
  const updateAllFilters = () => {
    post(`/server/request/updatefilters`,{wo:workOrder.work_report})
    .then((response) => {
      if (response.updated) {
        retriveProdData(9);
        setAnchorShowSettings(null);
      }
    })
  }
  const fullExcelDownload = () => {
    get(`/server/request/getproddata2edit?wo=${workOrder.work_report}&filter=all`)
    .then((response) => {
      if (response.data) {
        let readOnlyDats = response.data.map((e,pos)=>{
          e.content = (response?.sets) ? response.sets.map(f => {
            if (e.id === f.id) return {id:f.row,value:f.content}
          }).filter(e=>e) : [];
          return e
        });
        downloadFile(readOnlyDats,false)
      }
    });
  }
  const updateProdDataFromDailyshift = () => {
    get(`/server/request/update_proddata_activity`)
    .then((response) => {
      if (!isNaN(response.prev) && !isNaN(response.after)) {
        setIsUpdated(response.after-response.prev)
      }
    });
  }
  useEffect(()=>{
    if (lines2add) {
      let currentLength = parseInt(datset.length);
      addNewRow2list(true,null,currentLength,true,lines2add)
    }
  },[lines2add])
  return (
    <div className={classes.contentBorder}>
      <Grid container direction="row" justify="center" alignContent="center" alignItems="center" spacing={2}>
        <Grid item xs={5}>
          <Autocomplete
            disablePortal
            fullWidth
            id="combo-box-wo"
            value={workOrder ? workOrder : null}
            onChange={selectWorkOrder}
            onKeyDown={introWorkOrder}
            options={workOrders}
            getOptionLabel={(option) => `${option.work_report} ${option.description}`}
            renderInput={(params) => <TextField {...params} label="Orden de trabajo" placeholder="comienza a teclear el nombre..."/>}
          />
        </Grid>
        <Grid item xs={3}>
          {!workOrder &&
            <>
              <Checkbox
                checked={openWOsOnly}
                onClick={()=>setOpenWOsOnly(!openWOsOnly)} />
              SOLO ordenes activas
            </>
          }
          {workOrder && filters.length > 0 && datset && displayFilters()}
        </Grid>
        <Grid item xs={1}>
          {totalDBRows > 0 &&
            <>
              {datset.length}/{totalDBRows} registros
            </>
          }
        </Grid>
        <Grid item xs={3} className={classes.end}>
          {workOrder &&
            <>
              <IconButton color="inherit" aria-label="menu" onClick={(event)=>setAnchorShowSettings(event.currentTarget)}>
                <SettingsIcon/>
              </IconButton>
              <Toolbar sx={{position:"fixed"}}>
                <Menu
                    id="logout-menu"
                    anchorEl={anchorShowSettings}
                    open={Boolean(anchorShowSettings)}
                    onClose={()=>setAnchorShowSettings(null)}
                  >
                    <MenuItem onClick={updateAllFilters}>Actualizar filtros</MenuItem>
                    <MenuItem onClick={fullExcelDownload}>Descargar Excel completo</MenuItem>
                    <MenuItem onClick={updateProdDataFromDailyshift}>Actualizar datos desde parte diario</MenuItem>
                </Menu>
              </Toolbar>
            </>
          }
          {workOrder && !displaySections &&
            <IconButton color="inherit" aria-label="sectionDisply" onClick={()=>setDisplaySections(true)}>
              <ChecklistRtlIcon/>
            </IconButton>
          }
          {workOrder && displaySections &&
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="multiple-categories">Secciones</InputLabel>
              <Select
                id="multiple-categories-checkbox"
                multiple
                value={categories}
                onChange={selectCategories}
                onClose={()=>setDisplaySections(false)}
                renderValue={(selected) => selected.join(', ')}
                ref={dropdownRef}
              >
              {fieldNames.length === categories.length &&
                <MenuItem key="fieldNames-none" value="*none*">
                  <Checkbox checked />
                  <em>Todos</em>
                </MenuItem>
              }
              {fieldNames.length > categories.length &&
                <MenuItem key="fieldNames-all" value="*all*">
                  <Checkbox checked={false} />
                  <em>Todos</em>
                </MenuItem>
                }
                {categoryItems()}
              </Select>
            </FormControl>
          }
        </Grid>
        {workOrder &&
          <Grid item xs={(displayDocuments || isMobileSize) ? 12 : 3}>
            <IconButton key="send" variant="contained" onClick={()=>setDisplayDocuments(!displayDocuments)}>
              {displayDocuments && <FolderIcon/>}
              {!displayDocuments && documentsList.length === 0 && <FolderOpenIcon/>}
              {!displayDocuments && documentsList.length > 0 &&
                <Badge badgeContent={documentsList.length} color="primary">
                  <FolderIcon />
                </Badge>
              }
            </IconButton>
            documentos
            {displayDocuments &&
              <>
                <input
                  accept='application/msword,application/pdf,text/plain,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation'
                  className={classes.nodisplay}
                  id="uploaDOC"
                  multiple
                  type="file"
                  name="proddocument"
                  onChange={(e)=>selectFile(e)}
                />
                <label htmlFor="uploaDOC" >
                  <IconButton
                    variant="contained"
                    size="small"
                    component="span"
                  > <UploadIcon /> </IconButton>
                </label>
              </>
            }
          </Grid>
        }
      {displayDocuments && documentsList.map((e,listpos)=>
           <>
             <Grid item xs={1} className={classes.endblue}>
              <IconButton key={"docIcon"+listpos} variant="contained" onClick={()=>editFile(listpos)}>
                 <InsertDriveFileIcon className={classes.algin2middle}/>
              </IconButton>
             </Grid>
             <Grid item xs={isMobileSize ? 5 : e.description ? 3 : 6} className={classes.wrappingblue}>
              <a href={`/server/request/docdownload?id=${e.name}`} className={classes.nodecors} target="_doc">
                {e.name.split("----------").length > 1 && e.name.split("----------")[1]}
                {e.name.split("----------").length === 1 && e.name.split("-")[1]}
              </a>
             </Grid>
             <Grid item xs={isMobileSize ? 6 : e.description ? 5 : 2} className={classes.wrappingblue}>
              {e.description}
              {e.private === 1 && <LockIcon className={classes.algin2middle2wrap} />}
             </Grid>
             {!isMobileSize &&
               <Grid item xs={3}/>
             }
           </>
         )
      }
      {1===0 && workOrder && !isMobileSize && pagination()}
      {workOrder &&
        <Grid item xs={isMobileSize ? 12 : (displayDocuments && documentsList.length > 0) ? 12 : 9} className={classes.endshort}>
          <FormControl size="small" className={classes.numberLines}>
            <InputLabel id="lines-select-label">Añadir filas</InputLabel>
            <Select
              labelId="lines-select-label"
              id="lines-simple-select"
              value={lines2add}
              label="Líneas"
              onChange={(e)=>setLines2add(e.target.value)}
            >
              <MenuItem value={0}></MenuItem>
              {valRange.map(e=>
                <MenuItem value={e}>{e}</MenuItem>
              )}
            </Select>
          </FormControl>
          {1===0 &&
            <IconButton key={"addNewRow"} variant="contained" onClick={addNewRow}>
              <PlaylistAddIcon className={classes.algin2middle}/>
            </IconButton>
          }
          <Tooltip title="Ocultar filas">
            <IconButton key={"removeSelected"} variant="contained" onClick={()=>removeSelected(false)} disabled={!(Object.values(checkedRow).filter(e=>e==="self").length > 0)}>
              <VisibilityOffIcon className={classes.algin2middle}/>
            </IconButton>
          </Tooltip>
          <Tooltip title="BORRAR filas">
            <IconButton key={"deleteSelected"} variant="contained" onClick={()=>removeSelected(true)} disabled={!(Object.values(checkedRow).filter(e=>e==="self").length > 0)}>
              <DeleteIcon className={classes.algin2middle}/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Exportar Excel">
            <IconButton key={"excelFile"} variant="contained" onClick={()=>downloadFile(datset,true)} disabled={datset.length===0}>
              <img src="/images/file-excel.svg" className={classes.algin2middle20px}/>
            </IconButton>
          </Tooltip>
          {Boolean(totalDisabledRows) &&
            <Tooltip title="Mostrar filas eliminadas">
              <IconButton key={"RestoreFromTrashIcon"} variant="contained" onClick={getDisabledItems} className={includeDisabled ? classes.blue : null}>
                <RestoreFromTrashIcon className={classes.algin2middle}/>
              </IconButton>
            </Tooltip>
          }
          <Tooltip title="Deshacer">
            <IconButton key={"UndoIcon"} variant="contained" onClick={undoAction}>
              <UndoIcon className={classes.algin2middle}/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Rehacer">
            <IconButton key={"RedoIcon"} variant="contained" onClick={redoAction}>
               <RedoIcon className={classes.algin2middle}/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Copiar">
            <IconButton key={"ContentCopyIcon"} variant="contained" onClick={copyExistingState}>
              <ContentCopyIcon className={classes.algin2middle}/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Cortar">
            <IconButton key={"ContentCutIcon"} variant="contained" onClick={cutExistingState} disabled={false}>
             <ContentCutIcon className={classes.algin2middle}/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Pegar">
            <IconButton key={"ContentPasteIcon"} variant="contained" onClick={pasteExistingState} disabled={false}>
              <ContentPasteIcon className={classes.algin2middle}/>
            </IconButton>
          </Tooltip>
        </Grid>
        }
      </Grid>
      {workOrder && isCircleProgress &&
        <div><CircularProgress/></div>
      }
      {workOrder && !isCircleProgress &&
        <>
          <TableContainer component={Paper} className={classes.prodTable} ref={pasteRef} sx={{maxHeight:`calc(100vh - ${260+((displayDocuments && documentsList.length > 0) ? 40*documentsList.length : 0)}px)`}}>
            <Table size="small" stickyHeader aria-label="simple table" className={(globalTableSelected) ? classes.tableRowSelected : classes.tableRowNotSelected }>
              <TableHead>
                <TableRow>
                  <TableCell key="header0Bb" onClick={()=>selectAllTable(false)} className={classes.tableContentShortHeader0}>
                    <IconButton key="send" variant="contained">
                      <BorderAllIcon/>
                    </IconButton>
                  </TableCell>
                  <TableCell key="header0Ba" className={classes.tableContentShortHeader}>
                    <Tooltip title="Revisado">
                      <CheckIcon/>
                    </Tooltip>
                  </TableCell>
                  <TableCell key="header0C" className={classes.tableContentShort}>
                  </TableCell>
                  <TableCell key="header0D" className={classes.tableContentShort}>
                    <Checkbox checked={allRowsChecked} onClick={allCheckedRow} />
                  </TableCell>
                  {displaySelectedHeaders()}
                </TableRow>
              </TableHead>
              <TableBody>
                {displayDatSet && NewFullDatSetDisplay()}
              </TableBody>
            </Table>
          </TableContainer>
              {isMobile &&
                <div className={classes.bottomButtons}>
                      <Button
                        key="save-button"
                        variant="outlined"
                        onClick={savedata}
                        startIcon={<SaveIcon />}
                        >
                          Guardar
                        </Button>
                </div>
            }
        </>
      }
      {workOrder && isMobileSize && pagination()}
      <Dialog open={Boolean(openDocumentDialog)} onClose={()=>setOpenDocumentDialog(false)}>
        <DialogTitle>
          {!openDocumentDialog && <>Añadir nuevo documento</>}
          {openDocumentDialog && <>Modificar documento</>}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="center" alignContent="center" alignItems="center" spacing={1}>
            <Grid item xs={12}>
              <TextField label="Descripción" fullWidth onBlur={fileDescriptionChange} defaultValue={addingDocument.description}/>
            </Grid>
            <Grid item xs={12}>
              <Checkbox onChange={privateFile} checked={addingDocument.private}/> Privado
            </Grid>
            <Grid item xs={4}>
              {addingDocument.name &&
               <IconButton key="delFileBut" variant="contained" onClick={cancelDocument}>
                  <DeleteIcon className={classes.algin2middle}/>
               </IconButton>
              }
            </Grid>
            <Grid item xs={8}>
              {addingDocument?.name && addingDocument?.name.split("-")[1]}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=> openDocumentDialog ? setOpenDocumentDialog(false) : cancelDocument}>Cerrar</Button>
          <Button onClick={saveDocument}>Guardar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={Boolean(columnFilter)} onClose={()=>setColumnFilter(false)}>
        <DialogTitle>
          Filtrar por {columnHeaders[columnFilter-1]}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="center" alignContent="center" alignItems="center" spacing={1}>
            <Grid item xs={2}>
              <SortByAlphaIcon/>
            </Grid>
            <Grid item xs={10}>
              <Select value={filterOptions[columnFilter-1]?.order ? filterOptions[columnFilter-1].order : ""} fullWidth onChange={changeFilterOptions}>
                 <MenuItem key={"original"} value={""}> - </MenuItem>
                 <MenuItem key={"a2z"} value={"a2z"}>de A a Z</MenuItem>
                 <MenuItem key={"z2a"} value={"z2a"}>de Z a A</MenuItem>
               </Select>
            </Grid>
            <Grid item xs={10}>
              <TextField label="filtrar por" fullWidth onBlur={changeFilterSearch} defaultValue={filterOptions[columnFilter-1]?.filter}/>
            </Grid>
            <Grid item xs={2}>
              <IconButton type="button" aria-label="search">
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearFilterOptions}>Borrar filtros</Button>
          <Button onClick={()=>setColumnFilter(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={displayActionRegistry} onClose={()=>setDisplayActionRegistry()} fullWidth  maxWidth="md">
        <DialogTitle>Registro de actividad</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Grid container direction="row" justify="center" alignContent="center" alignItems="center" spacing={1}>
            <Grid item xs={3}> Campo </Grid>
            <Grid item xs={5}> Empleado </Grid>
            <Grid item xs={4}> Fecha </Grid>
            {displayActionRegistry?.map((e,p)=> <>
              <Grid item xs={3} className={p%2===0 ? classes.tableContent : classes.tableContent2}>
                {fieldNames.filter(f=>f.id===e.item)[0]?.name}
                {Boolean(e.comment) && <CommentIcon className={classes.tinyicon}/>}
              </Grid>
              <Grid item xs={5} className={p%2===0 ? classes.tableContent : classes.tableContent2}> {employees.length > 0 && employees.filter(f=>f.code === e.employe_code)[0]?.name} </Grid>
              <Grid item xs={4} className={p%2===0 ? classes.tableContent : classes.tableContent2}>
                {Boolean(e.timestamp.length>0) && e.timestamp?.split("T")[0]}&nbsp;{e.timestamp?.split("T")[1].substr(0,5)} </Grid>
              <Grid item xs={12} className={p%2===0 ? classes.tableContent : classes.tableContent2}>
                {Boolean(fieldNames.filter(f=>f.id===e.item)[0]?.type !== "IMAGE" && fieldNames.filter(f=>f.id===e.item)[0]?.type !== "GEOLOCATION") && e.content}
                {Boolean(fieldNames.filter(f=>f.id===e.item)[0]?.type === "GEOLOCATION") &&
                  <a target="_map" className={classes.nodecor} href={`https://www.google.com/maps/place/${e.content.split("&")[0]},${e.content.split("&")[1]}`}>
                    <MapIcon/>
                  </a>
                }
                {Boolean(fieldNames.filter(f=>f.id===e.item)[0]?.type === "IMAGE") && e.content.length > 0 && e.content.split(",").length > 0 &&
                  <div className={classes.rowImg}>
                    {e.content.split(",").map(img=>
                      <div className={classes.columnImg}>
                        <a target="_img" href={`/server/request/docdownload?id=${encodeURIComponent(img)}`}>
                          <img alt="" src={`/server/request/docdownload?id=${img}`} className={classes.minimg}/>
                        </a>
                      </div>
                    )}
                  </div>
                }
              </Grid>
            </>)}
          </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={()=>{setDisplayActionRegistry()}}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={!isNaN(isUpdated)} autoHideDuration={4500} onClose={()=>setIsUpdated()}>
        <MuiAlert elevation={6} variant="filled"  onClose={()=>setIsUpdated()} severity="success" sx={{ width: '100%' }}>
          Campos actualizados en todas las ordenes de trabajo: {isUpdated}
        </MuiAlert>
      </Snackbar>
      <Snackbar open={isSaved} autoHideDuration={4500} onClose={()=>setIsSaved(false)}>
        <MuiAlert elevation={6} variant="filled"  onClose={()=>setIsSaved(false)} severity="success" sx={{ width: '100%' }}>
          Guardado!
        </MuiAlert>
      </Snackbar>
    </div>
  );

}
