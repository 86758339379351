import React, { useRef, useEffect } from 'react';

import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import Button from '@mui/material/Button';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

import { useStyles } from "./styles";
import {get, post} from "./commons";

function Row({ row,managers,projects,userData,iamadmin,employees,statuses,getWOitems,go2date,leaveTypes,prodModel,workOrder,isMobile,workedHoursType,onlyApproved,addOtherManagers,lastRow,prodDataItems }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogData, setdialogData] = React.useState([]);
  const [dialogDataProd, setdialogDataProd] = React.useState([]);
  const [checked, setChecked] = React.useState([]);
  const work_items = (row.work_items) ? JSON.parse(row.work_items) :null;
  const [isNotified, setisNotified] = React.useState();
  const itemsRef = useRef([]);
  const timings = (row.timings) ? JSON.parse(row.timings) : null;

  const workorders = (work_items && work_items.workorders) ? work_items.workorders : [];
  let woFilteredEq = workorders.filter((row_wo,index) => {
    return userData?.code === row_wo?.manager;
  })
  let woFiltered = (onlyApproved) ? woFilteredEq : woFilteredEq.map((row_wo,index) => {
    let statusesResult = statuses.filter((e)=> e.daily_shift === row.id && e.working_order.toString() === row_wo.workorder);
    console.log(statusesResult,addOtherManagers,statuses)
    if (addOtherManagers) {
      let filteredPendingFromManager = statuses.filter((e)=> e.daily_shift === row.id && e.status > 2 && e.manager === userData?.code);
      if (filteredPendingFromManager.length > 0) return statusesResult[0]
      else return false;
    }
    else return statusesResult[0]?.status < 3;
  }).filter(e => e);
  if (!userData || woFiltered.length > 0) {
    const restOfFieldsNames = (row) => {
      let allKeys = (typeof row === "object") ? Object.keys(row) : []
      return allKeys.forEach((e)=>{
        if (!['manager','description','workorder','prod','status','comments'].includes(e)) {
          return <TableCell align="right" key={e}>{e}</TableCell>
        }
      })
    }
    const restOfFields = (row) => {
      let allKeys = Object.keys(row);
      return allKeys.forEach((e)=>{
        if (!['manager','description','workorder','prod','status','comments'].includes(e)) {
          if (typeof row[e] !== "object") return <TableCell align="right" onClick={()=>displayData(row)} key={e}>{row[e]}</TableCell>
          else {
            let hoursKeys = Object.keys(row[e]);
            return <TableCell align="right" onClick={()=>displayData(row)} key={e}>{
              hoursKeys.reduce((a,c,i)=>{
                if (typeof row[e][c]!== "number" || row[e][c]>0) a+=`${i>0 ? ", " : ""}${c}: ${row[e][c]}`;
                return a;
              },"")
            }</TableCell>
          }

        }
      })
    }
    const displayDate = (date,alsoTime) => {
      if (date) {
        let newDate = new Date(date);
        if (newDate) {
          let localeDAte = newDate.toLocaleDateString('es-ES');
          if (alsoTime) {
            let rowdate = new Date(row.date);
            let str = (localeDAte !== rowdate.toLocaleDateString('es-ES')) ? newDate.toLocaleString('es-ES') : newDate.toLocaleTimeString('es-ES');
            return str.slice(0, -3);
          }
          else return localeDAte;
        }
      }
    }
    const displayData = (row) => {
      let data2show = [], dataprod2show = [];//console.log(projects,row)
      data2show.push({title:"Encargado",text:managers.filter((e)=>e.id===row.manager)[0].name})
      data2show.push({title:"Parte de trabajo #",text:row.workorder})
      data2show.push({title:"Parte de trabajo nombre",text:projects.filter((e)=>e.work_report===row.workorder)[0].description})
      data2show.push({title:"Descripción",text:row.description})
      let allKeys = Object.keys(row);
      allKeys.forEach((e)=>{
        if (!['manager','description','workorder','prod'].includes(e)) {
          data2show.push({title:e,text:row[e]})
        }
      });
      if (row.prod && row.prod.length>0) {
        row.prod.forEach((prodset)=>{
          let dataprodsubset = [];
          let allKeys = Object.keys(prodset);
          allKeys.forEach((e)=>{
            if (!['manager','description','workorder','prod'].includes(e)) {
              dataprodsubset.push({title:e,text:prodset[e]})
            }
          });
          dataprod2show.push(dataprodsubset);
        })
      }
      setdialogData(data2show);
      setdialogDataProd(dataprod2show);//console.log(dataprod2show)
      setOpenDialog(true);
    }
    const hiddeData = () => {
      setdialogData([]);
      setdialogDataProd([]);
      setOpenDialog(false);
    }
    const displayLeaveTypes = (text) => {
      if (leaveTypes && leaveTypes.types) {
        let paidtype = leaveTypes.types.filter((e)=>e.def===text);
        if (paidtype.length > 0 && paidtype[0].name) return paidtype[0].name;
      }
    }
    const displayText = (text) => {
      if (text === null) return "";
      else if (text === true) return <CheckIcon/>
      else if (text === false) return "-";
      else if (typeof text === "object") {
        let hoursKeys = Object.keys(text);
        return hoursKeys.reduce((a,c,i)=>{
            if (typeof text[c]!== "number" || text[c]>0) a+=`${i>0 ? ", " : ""}${c}: ${text[c]}`;
            return a;
          },"")
      }
      else return text
    }
    const displayManagersName = (row) => {
      if (projects && typeof row === "object") {
        let projectId = (row.workorder) ? projects.filter((e)=>e.work_report===row.workorder) : null;
        if (projectId && projectId[0] && projectId[0].work_report) return projectId[0].work_report
      }
    }
    const displayProjectsName = (row) => {
      if (projects && typeof row === "object") {
        let projectId = (row.workorder) ? projects.filter((e)=>e.work_report===row.workorder) : null;
        if (projectId && projectId[0] && projectId[0].description) return projectId[0].description
      }
    }
    const showemployeenmae = (row) => {
      if (row.employee) {
        let emp = employees.filter((e)=>e.code===row.employee);
        if (emp && emp[0] && emp[0].name) return emp[0].name;
      }
    }
    const validation = (val,index,row_ro,row) => {
      let newchecked = [...checked];
      newchecked[index] = val;
      post("/server/request/shiftvalidation", {val:val,workorder:row_ro.workorder,shift:row.id})
      .then((response) => {
        setChecked(newchecked);
        getWOitems();
      });
    }
    const saveComments = (text,index,row_ro,row) => {//console.log(text)
      post("/server/request/savecomments", {val:text,workorder:row_ro.workorder,shift:row.id})
      .then((response) => {
        if (response.done) setisNotified("Guardado!")
      });
    }
    const displayComment = (row_wo,row) => {
      let statusesResult = statuses.filter((e)=> {
        return e.daily_shift === row.id && e.working_order.toString() === row_wo.workorder }
      );
      if (statusesResult.length > 0 && statusesResult[0]?.comments?.length > 0 && !isMobile) {
        return (
        <TableRow key={"comments"+statusesResult[0].working_order}>
          <TableCell style={{ textAlign: "right"}} colSpan={8}>
            {statusesResult[0].comments}
          </TableCell>
        </TableRow>
        )
      }
      else if (statusesResult.length > 0 && statusesResult[0]?.comments?.length > 0 && isMobile) {
        return (
          <p style={{ textAlign: "right"}}>
            {statusesResult[0].comments}
          </p>
        )
      }
    }
    const dbStatus = (row_wo,row,index,ismanager) => {
      let statusesResult = statuses.filter((e)=> {
        return e.daily_shift === row.id && e.working_order.toString() === row_wo.workorder }
      );
      if (statusesResult && statusesResult[0] && statusesResult[0].status) {
        if (statusesResult[0].status === 1 && isMobile) {
          if (ismanager) return (
            <>
              {(checkincomingstatus(row_wo,row,index) || (Boolean(checked[index]) === false || checked[index] === 1)) &&
                  <Button variant="contained" size="small" onClick={()=>validation(3,index,row_wo,row)}>
                    <CheckIcon /> APROBAR
                  </Button>
              }
              {Boolean(checked[index]) && checked[index] === 3 &&
                <Button variant="contained" size="small" onClick={()=>validation(1,index,row_wo,row)}>
                  <CheckCircleIcon /> APROBADO
                </Button>
              }
              {(Boolean(checked[index]) === false || checked[index] === 1) &&
                <>
                &nbsp;
                <Button variant="contained" size="small" onClick={()=>validation(2,index,row_wo,row)}>
                  <CloseIcon /> RECHAZAR
                </Button>
                </>
              }
              {Boolean(checked[index]) && checked[index] === 2 &&
                <Button variant="contained" size="small" onClick={()=>{validation(1,index,row_wo,row);saveComments("",index,row_wo,row)}}>
                  <CloseIcon /> RECHAZADO
                </Button>
              }
            </>
          )
          else return (
            <span className={classes.blue}>
              {statusesResult[0].status === 3 &&
                <CheckCircleIcon  className={classes.blue}/>
              }
              {statusesResult[0].status === 2 &&
                <CloseIcon />
              }
              {statusesResult[0].status === 1 &&
                <HourglassEmptyIcon/>
              }
              {managers.filter((e)=>e.id===row_wo.manager)[0].name}
            </span>
          )
        }
        else if (statusesResult[0].status === 2 && isMobile) return (
          <span size="small">
            <CancelIcon /> RECHAZADO
          </span>
        )
        else if (statusesResult[0].status === 3 && isMobile) {
          if (ismanager) return (
            <span size="small">
              <CheckCircleIcon /> APROBADO
            </span>
            )
          else return (
            <span className={classes.blue}>
              <CheckCircleIcon /> {managers.filter((e)=>e.id===row_wo.manager)[0].name}
            </span>
            )
        }
        else if (statusesResult[0].status === 1 && !isMobile) {
          if (ismanager) return (
            <>
              <TableCell align="center" key="2">
                {(checkincomingstatus(row_wo,row,index) || (Boolean(checked[index]) === false || checked[index] === 1)) &&
                  <CheckIcon onClick={()=>validation(3,index,row_wo,row)}/>
                }
                {Boolean(checked[index]) && checked[index] === 3 &&
                  <CheckCircleIcon onClick={()=>validation(1,index,row_wo,row)}/>
                }
              </TableCell>
              <TableCell align="center" key="3">
                {(Boolean(checked[index]) === false || checked[index] === 1) &&
                  <CloseIcon onClick={()=>validation(2,index,row_wo,row)}/>
                }
                {Boolean(checked[index]) && checked[index] === 2 &&
                  <CancelIcon onClick={()=>{validation(1,index,row_wo,row);saveComments("",index,row_wo,row)}}/>
                }
              </TableCell>
            </>
          )
          else return (
              <TableCell key="2"  colSpan={2} className={classes.blue}>
                <HourglassEmptyIcon/> {managers.filter((e)=>e.id===row_wo.manager)[0].name}
              </TableCell>
          )
        }
        else if (statusesResult[0].status === 2 && !isMobile) {
            if (ismanager) return (
              <>
                <TableCell key="2" colSpan={2} className={classes.notallowedcursor}>
                  <CancelIcon/> Rechazado pendiente de revisión
                </TableCell>
              </>
            )
          else return (
              <TableCell key="2"  colSpan={2} className={classes.blue}>
                <CloseIcon/> {managers.filter((e)=>e.id===row_wo.manager)[0].name}
              </TableCell>
          )
        }
        else if (statusesResult[0].status === 3 && !isMobile) {
            if (ismanager) return (
              <>
                <TableCell key="2" colSpan={2} className={classes.notallowedcursor}>
                  <CheckCircleIcon/> Aprobado
                </TableCell>
              </>
            )
          else return (
              <TableCell key="2" colSpan={2} className={classes.blue}>
                <CheckCircleIcon/> {managers.filter((e)=>e.id===row_wo.manager)[0].name}
              </TableCell>
          )
        }
      }
    }
  /*  const editStatus = (row_wo,row) => {
      let statusesResult = statuses.filter((e)=> {
        return e.daily_shift === row.id && e.working_order.toString() === row_wo.workorder }
      );
      if (statusesResult.length > 0) {
        let theStatus = statusesResult[0].status;
        if (theStatus === 2) return <CloseIcon/>
        else if (theStatus === 3) return <CheckIcon/>
      }
    }*/
    const displayStatus = (row_wo,row) => {
      let statusesResult = statuses.filter((e)=> {
        return e.daily_shift === row.id && e.working_order.toString() === row_wo.workorder }
      );
      if (statusesResult.length > 0) {
        let theStatus = statusesResult[0].status;//console.log("theStatus")
        if (theStatus === 1) return <HourglassEmptyIcon/>
        else if (theStatus === 2) return <CloseIcon/>
        else if (theStatus === 3) return <CheckIcon/>
      }
    }
    const checkincomingstatus = (row_wo,row,index) => {
      if (!checked[index]) {
        let statusesResult = statuses.filter((e)=> e.daily_shift === row.id && e.working_order === row_wo.workorder );
        if (statusesResult.length > 0) {
          let newchecked = [...checked];
          newchecked[index] = (statusesResult[0].status) ? statusesResult[0].status : 1;
          setChecked(newchecked);
        }
      }
    }
    const approvalText = (n) =>{
      if (n === 1) return <span> PENDIENTE  </span>;
      else if (n === 2) return <span className={classes.red}> RECHAZADO </span>;
      else if (n > 2) return <span className={classes.blue}> APROBADO </span>;
    }
    const approvalIcon = (n,date) =>{
      if (n === 1) return <HourglassEmptyIcon className={classes.algin2middle}/>;
      else if (n === 2) return <CloseIcon className={classes.algin2middle}/>;
      else if (n >2) return <CheckIcon className={classes.algin2middle}/>;
    }
    const displayWorkedHours = (hours) => {
      if (typeof hours !== "object") return hours;
      else {
        let hoursKeys = Object.keys(hours);
        return hoursKeys.reduce((a,c,i)=>{a+=`${i>0 ? ", " : ""}${c}: ${hours[c]}`; return a;},"")
      }
    }
    const displayHoursWorked = (row) => {
      if (row[workedHoursType]) {
        let keyVals = Object.keys(row[workedHoursType]);
        let keyValsArray = keyVals.filter(e=>row[workedHoursType][e]>0)
        return keyValsArray.map((e,i)=>{
          let hoursTxt = `${row[workedHoursType][e]} ${e}`
          if (i+1<keyValsArray.length) hoursTxt+=`, `
          return hoursTxt
        })
      }
    }
    const getprodDataItems = (val) => {
      let prodDataItem = prodDataItems.filter(e=>e.id.toString() === val.title.toString());
      if (prodDataItem[0]) {
        return {...prodDataItem[0],...val};
      }
      else return {id:val.title,name:null,type:null,text:null}
    }
    if (!userData || !workorders || !workOrder || !workOrder.work_report || workorders.filter((e)=>e.workorder===workOrder.work_report).length>0) {
      return (
        <React.Fragment>
          {!isMobile &&
            <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell key="1">
                {!row.holidays && row.paidleave === "0" && row.editstage !== 0 &&
                  <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                }
                {!row.holidays && row.paidleave === "0" && row.editstage === 0 &&
                  <div className={classes.centerLabelDiv}>
                    <HourglassEmptyIcon/>
                  </div>
                }
              </TableCell>
              {userData &&
                <TableCell>{showemployeenmae(row)}</TableCell>
              }
              <TableCell component="th" scope="row" key="2">
                {displayDate(row.date)}
              </TableCell>
              <TableCell align="right" key="3">{displayWorkedHours(row.worked_hours)}</TableCell>
              {userData &&
                <>
                  <TableCell align="right" key="4">
                    {timings?.times?.map((item) =>
                      <p>{displayDate(item.start,true)}</p>
                    )}
                  </TableCell>
                  <TableCell align="right" key="5">
                    {timings?.times?.map((item) =>
                      <p>{displayDate(item.end,true)}</p>
                    )}</TableCell>
                </>
              }
              {!userData &&
                <>
                  <TableCell align="right" key="4">{displayText(Boolean(row.holidays))}</TableCell>
                  <TableCell align="right" key="5">{leaveTypes && displayLeaveTypes(row.paidleave)}</TableCell>
                  <TableCell align="center" key="6">{approvalIcon(row.editstage,row.date)}</TableCell>
                  <TableCell align="center" key="7" onClick={()=>go2date(row.date)}><VisibilityIcon className={classes.pointer}/></TableCell>
                </>
              }
            </TableRow>
            {row.comment &&
              <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell className={classes.commentStyle}/>
                <TableCell className={classes.commentStyle} key="1comment" colSpan={5}>
                  <Typography variant="body1">
                    {row.comment}
                  </Typography>
                </TableCell>
              </TableRow>
            }
            {!row.holidays && row.paidleave === "0" &&
              <>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} className={classes.workOrderList}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                        <Table size="small">
                          <TableHead className={classes.shiftHeader}>
                            <TableRow key="header">
                              <TableCell className={classes.width60} key="1">Parte de trabajo</TableCell>
                              {restOfFieldsNames(workorders[0])}
                              {userData && !isMobile &&
                                <>
                                  <TableCell key="2" className={classes.width20}>Validar</TableCell>
                                  <TableCell key="3" className={classes.width20}>Revisar</TableCell>
                                </>
                              }
                              {!userData &&
                                <TableCell key="5" className={classes.textcenter}>Estado</TableCell>
                              }
                            </TableRow>
                          </TableHead>
                          <TableBody className={classes.shiftTable}>
                            {workorders && workorders.map((row_wo,index) => (
                              <>
                               {(!userData || addOtherManagers || userData?.code === row_wo.manager) &&
                                <TableRow key={index}>
                                  <TableCell onClick={()=>displayData(row_wo)} key="1">
                                    {displayManagersName(row_wo)}
                                    &nbsp;
                                    {displayProjectsName(row_wo)}
                                  </TableCell>
                                  {restOfFields(row_wo)}
                                  {userData && dbStatus(row_wo,row,index,userData.code === row_wo.manager)}
                                  {!userData &&
                                    <TableCell align="center" key="5">
                                      {displayStatus(row_wo,row)}
                                    </TableCell>
                                  }
                                </TableRow>
                                }
                                {displayComment(row_wo,row)}
                                {Boolean(checked[index]) && checked[index] === 2 &&
                                  <TableRow key={"cooments"+index}>
                                    <TableCell style={{ textAlign: "right"}} colSpan={8}>
                                      <TextField
                                        id="report_date"
                                        label="Motivo de la revisión"
                                        fullWidth
                                        inputRef ={el => itemsRef.current[index] = el}
                                        InputProps={{
                                          endAdornment: <InputAdornment position="end">
                                                          <SendIcon className={classes.pointer} onClick={(e)=>saveComments(itemsRef.current[index].value,index,row_wo,row)}/>
                                                        </InputAdornment>
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                }
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            }
            </>
          }
          {isMobile &&
            <>
              <TableRow className={classes.datarow1}>
                <TableCell className={classes.datacolumn1}>
                  <p>
                    <span className={classes.dateWo}>{displayDate(row.date)}</span>
                    {!userData &&
                      <>
                        {approvalText(row.editstage,row.date)}
                        <div className={classes.bottomWOend} onClick={()=>go2date(row.date)}>
                            <VisibilityIcon />
                        </div>
                      </>
                  }
                  </p>
                  {Boolean(row.holidays) && <p><BeachAccessIcon/> Vacaciones</p>}
                  {row.paidleave !== "0" && <p>Permiso retribuido: {displayLeaveTypes(row.paidleave)}</p>}
                  <p><strong>{userData && showemployeenmae(row)}</strong></p>
                  <p className={classes.commentStyle}> {row.comment} </p>
                    {workorders && workorders.map((row_wo,index) => (
                      <>
                        {!userData &&
                          <div className={classes.datarow0}>
                            <p onClick={()=>displayData(row_wo)}>
                              {displayManagersName(row_wo)}
                              &nbsp;
                              {displayProjectsName(row_wo)}
                            </p>
                            <p onClick={()=>displayData(row_wo)} className={classes.bottomWO}>
                              {displayHoursWorked(row_wo)}
                              <div className={classes.bottomWOend}>
                                {displayStatus(row_wo,row)}
                              </div>
                            </p>
                            {displayComment(row_wo,row)}
                            {Boolean(checked[index]) && checked[index] === 2 &&
                                <p>
                                  <TextField
                                    id="report_date"
                                    label="Motivo de la revisión"
                                    fullWidth
                                    inputRef ={el => itemsRef.current[index] = el}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end">
                                                      <SendIcon className={classes.pointer} onClick={(e)=>saveComments(itemsRef.current[index].value,index,row_wo,row)}/>
                                                    </InputAdornment>
                                    }}
                                  />
                                </p>
                            }
                          </div>
                        }
                        {(userData?.code === row_wo.manager || addOtherManagers) &&
                          <div className={classes.datarow0}>
                            <p onClick={()=>displayData(row_wo)}>
                              {displayManagersName(row_wo)}
                              &nbsp;
                              {displayProjectsName(row_wo)}
                            </p>
                            <p onClick={()=>displayData(row_wo)}>
                              {displayHoursWorked(row_wo)}
                            </p>
                            <p className={classes.end}>
                              {dbStatus(row_wo,row,index,userData?.code === row_wo.manager)}
                            </p>
                            {displayComment(row_wo,row)}
                            {Boolean(checked[index]) && checked[index] === 2 &&
                                <p>
                                  <TextField
                                    id="report_date"
                                    label="Motivo de la revisión"
                                    fullWidth
                                    inputRef ={el => itemsRef.current[index] = el}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end">
                                                      <SendIcon className={classes.pointer} onClick={(e)=>saveComments(itemsRef.current[index].value,index,row_wo,row)}/>
                                                    </InputAdornment>
                                    }}
                                  />
                                </p>
                            }
                          </div>
                        }
                      </>
                    ))}
                </TableCell>
              </TableRow>
              {!lastRow &&
                <TableRow>
                  <TableCell align="right" className={classes.datacolumn1}>&nbsp;</TableCell>
                </TableRow>
              }
              {1===0 && userData &&
                <>
                  {row.start &&
                    <TableRow>
                      <TableCell align="right">hora comienzo: {row.start.substr(0,5)}</TableCell>
                    </TableRow>
                  }
                  {row.end &&
                    <TableRow>
                      <TableCell align="right">hora fin: {row.end.substr(0,5)}</TableCell>
                    </TableRow>
                  }
                </>
              }

            </>
          }
          <Dialog onClose={hiddeData} open={openDialog}>
            <DialogTitle>
              Orden de trabajo
              <IconButton aria-label="close" onClick={hiddeData}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
            </DialogTitle>
            <Grid container spacing={1} className={classes.dialogShifts}>
            {dialogData.map((e)=>
              <>
                <Grid item xs={4}>
                  <Typography variant="body1" >{e.title}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" >{displayText(e.text)}</Typography>
                </Grid>
              </>
            )}
            {dialogDataProd.map((prodset,index)=>
              <>
                <Grid item xs={12}>
                  <Typography variant="body1"><strong>Datos de Producción {index+1}</strong></Typography>
                </Grid>
                {prodset.map(f=>getprodDataItems(f)).map((e)=>
                  <>
                    {!["IMAGE","GEOLOCATION"].includes(e.type) &&
                      <>
                        <Grid item xs={4}>
                          <Typography variant="body1">{e.name}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body1">
                            {displayText(e.text)}
                          </Typography>
                        </Grid>
                      </>
                    }
                    {e.type === "IMAGE" &&
                      <>
                        <Grid item xs={4}>
                          <Typography variant="body1">{prodModel[e.title]?.name}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <div className={classes.rowImg}>
                            {e.text?.map(img=>
                              <div className={classes.columnImg}>
                                <a href={`/server/request/docdownload?id=${img}`} target="_img">
                                  <img alt="" src={`/server/request/docdownload?id=${img}`} className={classes.minimg}/>
                                </a>
                              </div>
                            )}
                          </div>
                        </Grid>
                      </>
                    }
                    {e.type === "GEOLOCATION" &&
                    <>
                      <Grid item xs={4}>
                        <Typography variant="body1">{prodModel[e.title]?.name}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1">
                          lat: {e.text.split("&")[0]}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1">
                          long: {e.text.split("&")[1]}
                        </Typography>
                      </Grid>
                    </>
                    }
                  </>
                )}
              </>
            )}
            </Grid>
          </Dialog>
          <Snackbar open={Boolean(isNotified)} autoHideDuration={6000} onClose={()=>setisNotified()}>
            <MuiAlert elevation={6} variant="filled"  onClose={()=>setisNotified()} severity="success" sx={{ width: '100%' }}>
              {isNotified}
            </MuiAlert>
          </Snackbar>
        </React.Fragment>
      );
    }
  } // else console.log("error 1",!userData , woFiltered)
}

export default function WorkReport({biz,shiftsigned,managers,projects,userData,iamadmin,getWOitems,go2date,globalVariables,changeGlobalVariables,isMobile}) {
  const classes = useStyles();
  const [employees, setEmployees] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [onlyRejected, setOnlyRejected] = React.useState(true);
  const [onlyApproved, setOnlyApproved] = React.useState(false);
  const [addOtherManagers, setAddOtherManagers] = React.useState(false);
  const [leaveTypes, setLeaveTypes] = React.useState();
  const [prodModel, setProdModel] = React.useState();
  const [workedHoursModel, setWorkedHoursModel] = React.useState();
  const [workedHoursType, setWorkedHoursType] = React.useState();
  const [employee, setEmployee] = React.useState((globalVariables && globalVariables.allshifts_employee) ? globalVariables.allshifts_employee : null);
  const [workOrder, setWorkOrder] = React.useState((globalVariables && globalVariables.allshifts_workOrder) ? globalVariables.allshifts_workOrder : null);
  const [workOrders, setWorkOrders] = React.useState([]);
  const [myShifts,setMyShifts] = React.useState([]);
  const [startDate, setStartDate] = React.useState((globalVariables && globalVariables.allshifts_startDate) ? globalVariables.allshifts_startDate : null);
  const [endDate, setEndDate] = React.useState((globalVariables && globalVariables.allshifts_endDate) ? globalVariables.allshifts_endDate : null);
  const [prodItems, setProdItems] = React.useState([]);
  const [prodDataItems, setProdDataItems] = React.useState([]);
/*  const currentTime = () =>{
    var d = new Date();
    var hr = d.getHours();
    var min = d.getMinutes();
    if (hr < 10) hr = `0${hr}`
    return `${hr}:${min}`
  }*/
  const retriveShifts = (pageVal=page, rowsVal=rowsPerPage, approved=onlyApproved) => {
    if (iamadmin) get(`/server/request/shifts2review?page=${pageVal}&rows=${rowsVal}`).then((response)=>{
      if (response.shifts) setMyShifts(response.shifts);
      else setMyShifts([]);
      if (response.employees) setEmployees(response.employees);
      //else setEmployees([]);
      if (response.status) setStatuses(response.status);
      else setStatuses([]);
      if (response.total) setTotalRows(response.total);
      //else setTotalRows(0);
    })
    else if (!userData) get(`/server/request/myshifts?page=${pageVal}&rows=${rowsVal}&onlyrejected=${onlyRejected}`).then((response)=>{
      if (response.shifts) setMyShifts(response.shifts);
      else setMyShifts([]);
      if (response.status) setStatuses(response.status);
      else setStatuses([]);
      if (response.total) setTotalRows(response.total);
      //else setTotalRows(0);
    })
    else {
      let query = `/server/request/shifts2approve?page=${pageVal}&rows=${rowsVal}&approved=${approved}`;
      if (employee && employee.code) query += `&employee=${employee.code}`;
      if (workOrder && workOrder.work_report) query += `&wo=${workOrder.work_report}`;
      if (startDate) query += `&start=${startDate}`;
      if (endDate) query += `&end=${endDate}`;
      get(query).then((response)=>{
        if (response.shifts) setMyShifts(response.shifts);
        else setMyShifts([]);
        if (response.employees) setEmployees(response.employees);
        //else setEmployees([]);
        if (response.status) setStatuses(response.status);
        else setStatuses([]);
        if (response.total) setTotalRows(response.total);
        //else setTotalRows(0);
        if (response.prodmodel?.model || response.proditems?.length > 0) {
          let allItems = {};
          if (response.prodmodel?.model) {
            let prodModelData = JSON.parse(response.prodmodel.model);
            if (prodModelData.elements) prodModelData.elements.forEach(c=>allItems[c.id] = {name:c.id,type:c.type})
          }
          if (response.proditems?.length > 0) response.proditems.forEach(e=>allItems[e.id] = {name:e.name,type:e.type.toLowerCase()})
          setProdModel(allItems);//console.log(allItems)
        }
      })
    }
  }
  useEffect(()=>{
    retriveShifts();
  },[onlyRejected,onlyApproved,startDate,endDate,employee?.code,workOrder?.work_report]);
  useEffect(()=>{
    if (onlyApproved) retriveShifts();
  },[employee,workOrder,startDate,endDate]);

  useEffect(()=>{
    get(`/server/request/myshiftmodels`).then((response)=>{
      if (response.leavetypes) setLeaveTypes(response.leavetypes);
      if (response.workhoursmodel) setWorkedHoursModel(response.workhoursmodel);
      if (response.prod_items) setProdDataItems(response.prod_items);
      let defhours = response.workhoursmodel.elements.filter((e)=>e.def==="dailyhours");
      if (defhours[0]?.id) setWorkedHoursType(defhours[0].id);
    });
    get(`/server/request/getworkorders?valsegment`).then((response) => {
        if (response && response.wo && response.wo.length > 0) setWorkOrders(response.wo);
      });
  },[]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    retriveShifts(newPage,rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    let rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    setPage(0);
    retriveShifts(0,rows);
  };
  const selectEmployee = (event, newValue) => {
    setEmployee(newValue);
    changeGlobalVariables("allshifts_employee", newValue);
  }
  const selectWorkOrder = (event, newValue) => {
    setWorkOrder(newValue);
    changeGlobalVariables("allshifts_workOrder", newValue);
  }
  const displayaddOtherManagers = () => {
    setAddOtherManagers(!addOtherManagers);
  }
  const changeApprovedStatus = () => {
    setMyShifts([])
    let current = !onlyApproved;
    setOnlyApproved(current);
    retriveShifts(page,rowsPerPage,current);
  }
  const changeStartDate = (e) => {
    let newValue = e.target.value;
    setStartDate(newValue);
    changeGlobalVariables("allshifts_startDate", newValue);
  }
  const changeEndDate = (e) => {
    let newValue = e.target.value;
    setEndDate(newValue);
    changeGlobalVariables("allshifts_endDate", newValue);
  }
  const dateInRange = (row) => {
    let stDate = new Date(startDate)-7200000;
    let enDate = new Date(endDate)-0;
    let rowDate = new Date(row.date)-3600000;
    return (!startDate || rowDate >= stDate) && (!endDate || rowDate <= enDate)
  }
  return (
      <Container maxWidth="md" className={classes.contentTop}>
      <Grid container direction="row" justify="center" alignContent="center" alignItems="center" spacing={2}>
      {userData &&
        <>
          <Grid item xs={isMobile ? 12 : 6} id="title">
            <Typography variant="h5" color="textSecondary">
              Validar Partes
            </Typography>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3} id="approved">
            <FormControlLabel control={<Switch checked={onlyApproved} />} label="aprobados" onClick={changeApprovedStatus}/>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3} id="approved">
            <FormControlLabel control={<Switch checked={addOtherManagers} />} label="pendiente otros encargados" onClick={displayaddOtherManagers}/>
          </Grid>
          <Grid item xs={6} id="search-start-date">
            <TextField id="outlined-start-date" label="Fecha inicio" variant="outlined" type="date" defaultValue={startDate} onChange={changeStartDate} InputLabelProps={{ shrink: true }}/>
          </Grid>
          <Grid item xs={6} id="search-end-date">
            <TextField id="outlined-end-date" label="Fecha fin" variant="outlined" type="date" defaultValue={endDate} onChange={changeEndDate} InputLabelProps={{ shrink: true }}/>
          </Grid>
          {employees &&
            <Grid item xs={6} id="search">
              <Autocomplete
                disablePortal
                fullWidth
                id="combo-box-employees"
                value={employee}
                onChange={selectEmployee}
                options={employees}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Empleado" placeholder="comienza a teclear el nombre..."/>}
              />
            </Grid>
          }
          <Grid item xs={6} id="search">
            <Autocomplete
              disablePortal
              fullWidth
              id="combo-box-wo"
              value={workOrder}
              onChange={selectWorkOrder}
              options={workOrders}
              getOptionLabel={(option) => `${option.work_report} ${option.description}`}
              renderInput={(params) => <TextField {...params} label="Orden de trabajo" placeholder="comienza a teclear el nombre..."/>}
            />
          </Grid>
        </>
      }
      {!userData &&
        <>
          <Grid item xs={12} id="title2">
            <Typography variant="h5" color="textSecondary">
              Mis Partes
            </Typography>
          </Grid>
          <Grid item xs={12} id="rejected">
            <Typography variant="body2" color="textSecondary" className={classes.end}>
              <FormControlLabel control={<Switch checked={onlyRejected} />} label="sólo rechazados" onClick={()=>setOnlyRejected(!onlyRejected)}/>
            </Typography>
          </Grid>
        </>
      }
      {myShifts.length > 0 &&
        <Grid item xs={12} id="maintable">
          <TableContainer component={Paper} className={isMobile && classes.tableWO}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className={isMobile && classes.mobileTable}>
              <TableHead>
                <TableRow>
                  {!isMobile &&
                    <>
                      <TableCell></TableCell>
                      {userData &&
                        <TableCell>Empleado</TableCell>
                      }
                      <TableCell>Fecha</TableCell>
                      <TableCell align="right">Horas trabajadas</TableCell>
                      {userData &&
                        <>
                          <TableCell align="right">hora comienzo</TableCell>
                          <TableCell align="right">hora fin</TableCell>
                        </>
                      }
                      {!userData &&
                        <>
                          <TableCell align="right">Vacaciones</TableCell>
                          <TableCell align="right">Permiso retribuido</TableCell>
                          <TableCell align="center">Estado</TableCell>
                          <TableCell align="center">Revisar</TableCell>
                        </>
                      }
                    </>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {myShifts.map((row,pos) => (
                  <>
                    {(!employee || row.employee === employee.code) && dateInRange(row) &&
                      <Row
                        key={row.date}
                        row={row}
                        managers={managers}
                        projects={projects}
                        userData={userData}
                        iamadmin={iamadmin}
                        employees={employees}
                        statuses={statuses}
                        getWOitems={getWOitems}
                        go2date={go2date}
                        leaveTypes={leaveTypes}
                        prodModel={prodModel}
                        workOrder={workOrder}
                        isMobile={isMobile}
                        workedHoursType={workedHoursType}
                        onlyApproved={onlyApproved}
                        addOtherManagers={addOtherManagers}
                        lastRow={myShifts.length === pos+1}
                        prodDataItems={prodDataItems}
                        />
                    }
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[100, 250, 500]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={<span>Filas:</span>}
            labelDisplayedRows={({ page }) => `Página ${page+1} de ${Math.ceil(totalRows/rowsPerPage)}`}
            className={classes.pagination}
          />
        </Grid>
        }
        {myShifts.length === 0 &&
          <Grid item xs={12} id="nodata">
            <Typography variant="h6" >
              {!userData &&
                <> Sin partes disponibles </>
              }
              {userData &&
                <> Sin partes a validar </>
              }
            </Typography>
          </Grid>
        }
      </Grid>
    </Container>
  );

}
