import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import ButtonGroup from "@mui/material/ButtonGroup";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import UploadIcon from '@mui/icons-material/Upload';
import ImageIcon from '@mui/icons-material/Image';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SaveIcon from '@mui/icons-material/Save';
import TaskIcon from '@mui/icons-material/Task';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import FactoryIcon from '@mui/icons-material/Factory';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MapIcon from '@mui/icons-material/Map';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from "./styles";
import {get, post, postPlain} from "./commons";
import CanvasSign from "./canvas";
import CameraCropper from './cropper';

export default function WorkReport({isMobile,biz,shiftsigned,managers,projects,gotoreports,editingDate,userData}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const workOrderFirstItems = {manager:null, workorder:null, description:"", prod:[]}
  const [workOrderItems, setWorkOrderItems] = React.useState(workOrderFirstItems);
  var tzoffset = (new Date()).getTimezoneOffset() * 120000;
  var startingDay = (new Date()).toISOString().split('T')[0];
  const [today, setToday] = React.useState((editingDate?.date) ? (new Date((new Date(editingDate.date)) - tzoffset)).toISOString().split('T')[0] : startingDay);
  const [productos,setProductos] = React.useState([[]]);
  const [workingOrders,setWorkingOrders] = React.useState([]);
  const [staticWorkingOrders, setStaticWorkingOrders] = React.useState([]);
  const [originalWorkingOrders, setOriginalWorkingOrders] = React.useState([]);
  const [holidays, setHolidays] = React.useState(false);
  const [paidleave, setPaidleave] = React.useState(0);
  const [productionModel, setProductionModel] = React.useState();
  const [leaveTypesModel, setLeaveTypesModel] = React.useState();
  const [workedHoursModel, setWorkedHoursModel] = React.useState();
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [signatureIMG, setSignatureIMG] = React.useState();
  const [signatureID, setSignatureID] = React.useState();
  const [rejectedWO, setRejectedWO] = React.useState([]);
  const [dayshiftID, setDayshiftID] = React.useState();
  const [isExistingDate, setisExistingDate] = React.useState(false);
  const [isTooEarlyDate, setisTooEarlyDate] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const [workingHoursStart, setWorkingHoursStart] = React.useState();
  const [workingHoursEnd, setWorkingHoursEnd] = React.useState();
  const [autoHours, setAutoHours] = React.useState(0);
  const [editStage, setEditStage] = React.useState();
  const [hiddeUntilClientProject, setHiddeUntilClientProject] = React.useState(false);
  const [projectIDs, setProjectIDs] = React.useState([]);
  const [displayCircularProgress, setDisplayCircularProgress] = React.useState(false);
  const [allModels, setAllModels] = React.useState(false);
  const [addedWOs, setAddedWOs] = React.useState([]);
  const [commentText, setCommentText] = React.useState();
  const [timings, setTimings] = React.useState();
  const [canTrack, setCanTrack] = React.useState(true);
  const [currentLocation, setCurrentLocation] = React.useState();
  const [viewTimings, setViewTimings] = React.useState("hidde");
  const [workedTime, setworkedTime] = React.useState();
  const [reviewTimesMode, setReviewTimesMode] = React.useState(false);
  const [ready2sign, setReady2sign] = React.useState(false);
  const [displayTimeEditing, setDisplayTimeEditing] = React.useState(false);
  const [savedWO, setSavedWO] = React.useState([]);
  const [finishedWO, setFinishedWO] = React.useState([]);
  const [docsAndProdData, setDocsAndProdData] = React.useState({});
  const [displayDocuments, setDisplayDocuments] = React.useState(false);
  const [prodFilters, setProdFilters] = React.useState({});
  const [isImageOpen, setIsImageOpen] = React.useState("");
  const [viewandread, setviewandread] = React.useState();
  const [timeCounts, setTimeCounts] = useState(0);
  const [timeRefresh, setTimeRefresh] = useState(1000);
  const [rows2retrive, setRows2retrive] = React.useState({items:[],filter:null});
  const [openCameraPopUp, setOpenCameraPopUp] = React.useState(false);
  const [imageVal, setImageVal] = React.useState();
  const [multiProdFilterIDs, setMultiProdFilterIDs] = React.useState(false);
  const [multiProdFilterData, setMultiProdFilterData] = React.useState([]);
  const [productionButtonSelected, setProductionButtonSelected] = React.useState([-1,-1]);
  const [deleteProdItem, setDeleteProdItem] = React.useState();

  const iconsSet = {
    LocationOnIcon:<LocationOnIcon className={classes.algin2bottom}/>,
    ImageIcon:<ImageIcon className={classes.algin2bottom}/>,
    SaveIcon:<SaveIcon className={classes.algin2bottom}/>,
    TaskIcon:<TaskIcon className={classes.algin2bottom}/>,
    ArrowBackIcon:<ArrowBackIcon className={classes.algin2bottom}/>,
    VerifiedUserIcon:<VerifiedUserIcon className={classes.algin2bottom}/>,
    FactoryIcon:<FactoryIcon className={classes.algin2bottom}/>,
    NoteAddIcon:<NoteAddIcon className={classes.algin2bottom}/>,
    RemoveCircleIcon:<RemoveCircleIcon className={classes.algin2bottom}/>,
    AddCircleIcon:<AddCircleIcon className={classes.algin2bottom}/>,
    WatchLaterIcon:<WatchLaterIcon className={classes.algin2bottom}/>,
    ImageIcon:<ImageIcon className={classes.algin2bottom}/>,
    SaveIcon:<SaveIcon className={classes.algin2bottom}/>,
    TaskIcon:<TaskIcon className={classes.algin2bottom}/>,
    ArrowBackIcon:<ArrowBackIcon className={classes.algin2bottom}/>,
    VerifiedUserIcon:<VerifiedUserIcon className={classes.algin2bottom}/>,
    FactoryIcon:<FactoryIcon className={classes.algin2bottom}/>,
    NoteAddIcon:<NoteAddIcon className={classes.algin2bottom}/>,
    RemoveCircleIcon:<RemoveCircleIcon className={classes.algin2bottom}/>,
    AddCircleIcon:<AddCircleIcon className={classes.algin2bottom}/>,
    WatchLaterIcon:<WatchLaterIcon className={classes.algin2bottom}/>
  }
  useEffect(() => {
    setTimeCounts(timings)
    if (workedTime) setTimeRefresh(60000);
  },[timings,workedTime]);
  useEffect(() => {
      const interval = setInterval(() => {
        if (canTrack) getTimingLocation();
        let fullTimes = timeCounts?.reduce((a,c)=>{
          let starting = parseInt(c.start);
          let ending = parseInt(c.end);
          if (starting) {
            if (ending) {
              a+= ending - starting;
            }
            else {
              let currTime = new Date();
              a += currTime.getTime() - starting;
            }
          }
          return a;
        },0)
        if (fullTimes) setworkedTime(number2time(fullTimes));
        else setworkedTime("00:00");
      }, timeRefresh);
      return () => clearInterval(interval);
  }, [timeCounts,timeRefresh]);

  const number2time = (fullTimes) => {
    let min = Math.floor(fullTimes/1000/60);
    let hours = (min >= 60) ? Math.floor(min/60) : 0;
    let minutes = min - hours * 60;
    if (hours<10) hours = `0${hours}`;
    if (minutes<10) minutes = `0${minutes}`;
    return `${hours}:${minutes}`;
  }

  const clearAll = () => {
    setSignatureIMG();
    setRejectedWO([]);
    setDayshiftID();
    setEditStage();
    setRows2retrive({items:[],filter:null});
    setMultiProdFilterIDs(false);
    setMultiProdFilterData([]);
    setWorkingOrders([]);
    setStaticWorkingOrders([]);
    setDocsAndProdData({});
    setProdFilters({});
  }
  function findPosition(obj) {
    if (obj) {
      var currenttop = 0;
      if (obj.offsetParent) {
        do {
          currenttop += obj.offsetTop;
        } while ((obj = obj.offsetParent));//console.log(currenttop)
        return [currenttop];
      }
    }
    else return [0];
  }
  const addproduct = (index,index2)  => {
    var newproducts = [...productos];
    newproducts[index]=[...newproducts[index],1];
    setProductos(newproducts);
  }
  const addProd = (index,prevIndex2,newFilter,displayval)  => {
    setProductionButtonSelected([null,null]);
    let newproducts = [...productos];
    let index2 = prevIndex2+1;
    newproducts[index]=[...newproducts[index],1];
    setProductos(newproducts);
    let thisWO = workingOrders[index].workorder;//console.log(docsAndProdData[thisWO],docsAndProdData[thisWO].allfilters)
    if (prodFilters[thisWO] && !prodFilters[thisWO].display[index2] && newFilter) {
      let newProdFilters = {...prodFilters}
      newProdFilters[thisWO].display[index2] = newProdFilters[thisWO].all;
      newProdFilters[thisWO].selected[index2] = {};
      newProdFilters[thisWO].subset[index2] = {};
      if (index2 > 0 && prodFilters[thisWO].selected[prevIndex2]) {
        let val = (displayval) ? displayval : prodFilters[thisWO].selected[prevIndex2]?.filter;
        selectProdFilterIDs(thisWO,index,index2,val,null,"addprod")
      }
      setProdFilters(newProdFilters);
    }
    window.scrollTo(0, findPosition(document.getElementById("production"+index)));
  }
  const addWorkOrder = () => {
    setProductos([...productos,[]]);
    let newworkOrderItems = {...workOrderItems};
    workedHoursModel.elements.forEach((e,j)=>{
      if (!isNaN(e["default-others"]) || Boolean(e["default-others"])) newworkOrderItems[e.id] = [...e["default-others"]];
    });
    let newWorkingOrders = [...workingOrders, {...newworkOrderItems}];
    setWorkingOrders(newWorkingOrders);
    let newAddedWOs = [...addedWOs];
    newAddedWOs.push(newWorkingOrders.length-1)
    setAddedWOs(newAddedWOs);
    setIsCompleted(false);
  }
  const deleteWorkOrder = (e,index) => {
    let newProjectIDs = [...projectIDs].filter((f,i)=>i!=index);
    setProjectIDs(newProjectIDs);
    let newProdcutos = [...productos].filter((f,i)=>i!=index);
    setProductos(newProdcutos);
    let newWorkingOrders = [...workingOrders].filter((f,i)=>i!=index);
    setWorkingOrders(newWorkingOrders);
    let [dailyHoursValsPerWOPerType, dailyHoursValsPerWO, dailyHoursVal, finalworkingOrders] = wo2DB(newWorkingOrders);
    if (dayshiftID && savedWO.filter(f=>f.workorder===e.workorder).length > 0) {
      let data2send = {
        date: today,
        dayshift: dayshiftID,
        workorders: finalworkingOrders,
        dailyhours: dailyHoursVal,
        dailyhoursperwopertype: JSON.stringify({hoursbytype:dailyHoursValsPerWOPerType})
      };
      if (e.workorder) data2send.workorder = e.workorder;
      if (editingDate?.employee) data2send.employee = editingDate.employee;
      post('/server/request/deletewo4date',data2send).then((response)=>{
        console.log("del",response)
      });
    }
  }
  const showStage = (stage) => {
    if (stage === 1) return "pendiente";
    else if (stage === 2) return "rechazado";
    else if (stage === 3) return "aprobado";
    else if (stage === 4) return "aprobado por administrador";
  }
  const managerEditing = (num) => {
      setAutoHours(num);
      setTimings([{"start":null,"end":null}]);
  }
  const startTimingSetting = (num,stage,times) => {
    if (times) setTimings(times)
    else setTimings([{"start":null,"end":null}]);
    if (!(editingDate?.employee)) setAutoHours(num);
    else setAutoHours(0);
    setViewTimings(stage);
  }
  const getWorkOrderData = (response) => {
      if (response.code && response.code === 401) navigate(`/${biz}`);
      else {
        if (response?.error === "nodata") {
          if (workedHoursModel?.working_hours?.system === "auto") {
            if (today === startingDay && !(editingDate?.employee)) {
              startTimingSetting(1,"start",null);
            }
            else if (today !== startingDay && !(editingDate?.employee)) {
              startTimingSetting(2,"hidde",null);
            }
            else if (editingDate?.employee) managerEditing(2);
            else setAutoHours(0);
          }
          else if (workedHoursModel?.working_hours?.start && workedHoursModel?.working_hours?.end) {
              setWorkingHoursStart(workedHoursModel.working_hours.start)
              setWorkingHoursEnd(workedHoursModel.working_hours.end)
              setAutoHours(0);
            }
        }
        else if (workedHoursModel?.working_hours?.system === "auto") {
          if (response?.dayshift?.timings) {
            let timings = JSON.parse(response.dayshift.timings);
            if (timings?.times) {
              startTimingSetting(2,"hidde",timings.times);
            }
            else if (today === startingDay && !(editingDate?.employee)) startTimingSetting(1,"start",null);
            else if (editingDate?.employee) managerEditing(2);
            else setAutoHours(0);
          }
          else if (today !== startingDay && !(editingDate?.employee)) startTimingSetting(2,"hidde",null);
          else if (!(editingDate?.employee)) startTimingSetting(1,"start",null);
          else if (editingDate?.employee) managerEditing(2);
          else managerEditing(0);
        }
        else {
          let startHour = (workedHoursModel?.working_hours?.start && response?.dayshift?.start) ? response.dayshift.start : (workedHoursModel?.working_hours?.start) ? workedHoursModel.working_hours.start : "08:00";
          let endHour = (workedHoursModel?.working_hours?.end && response?.dayshift?.end) ? response.dayshift.end : (workedHoursModel?.working_hours?.end) ? workedHoursModel.working_hours.end : "15:00";
          setWorkingHoursStart(startHour)
          setWorkingHoursEnd(endHour)
          setAutoHours(0);
        }
        if (workedHoursModel['await-client-project']) setHiddeUntilClientProject(workedHoursModel['await-client-project']);
        let  newOrderItems= {};
        for (let i=0;i<workedHoursModel.elements.length;i++) {
          newOrderItems[workedHoursModel.elements[i].id] = workedHoursModel.elements[i].default;
        }
        let newAllworkOrderItems = {...workOrderFirstItems,...newOrderItems}
        setWorkOrderItems({...newAllworkOrderItems});
        let newWO = [newAllworkOrderItems];
        let newRejectedWO = [];
        let completedStatuses = [];
        let projectsIDS = [];
        if (workedHoursModel && workedHoursModel.elements) {
          if (response.dayshift) {
            if (response.dayshift.start) setWorkingHoursStart(response.dayshift.start);
            if (response.dayshift.end) setWorkingHoursEnd(response.dayshift.end);
            if (response.dayshift.comment) setCommentText(response.dayshift.comment);
            if (response.dayshift.signature) setSignatureID(response.dayshift.signature);
            else setSignatureID();
            if (response.dayshift.editstage) setEditStage(response.dayshift.editstage);
            let workitems = JSON.parse(response.dayshift.work_items);
            if (workitems) {
              setSavedWO(workitems.workorders);
              if (workitems && workitems.workorders && workitems.workorders.length > 0) {
                let allProductos = [], newProjectIDs = [];
                for (let i=0;i<workitems.workorders.length;i++) {
                  allProductos[i] = [];
                  for (let j=0;j<workitems.workorders[i].prod.length;j++) {
                    allProductos[i][j]=1;
                  }
                  if (workitems.workorders[i].manager && workitems.workorders[i].workorder) newProjectIDs[i]={manager:workitems.workorders[i].manager,wo:workitems.workorders[i].workorder};
                }
                setProductos(allProductos);
                projectsIDS = newProjectIDs;
              }
              else setProductos([[]]);
            }
            if (response.dayshift.holidays) {
              setHolidays(true);
              setPaidleave("0");
            }
            else if (response.dayshift.paidleave) {
              setHolidays(false);
              setPaidleave(response.dayshift.paidleave);
            }
          }
        }
        if (response.dayshift) {
          setDayshiftID(response.dayshift.id)
          if (response.dayshift.work_items) {
            let workitemsPrev = JSON.parse(response.dayshift.work_items);
            let defhours = workedHoursModel.elements.filter((e)=>e.def==="dailyhours");
            workitemsPrev.workorders = workitemsPrev.workorders.map((e)=>{
              if (Array.isArray(e[defhours[0].id])) return e;
              else {
                let newVal = Object.keys(e[defhours[0].id]);
                e[defhours[0].id] = newVal.map((f)=>e[defhours[0].id][f]);
                return e;
              }
            });
            let workitems = {...workitemsPrev}
            if (workitems && workitems.workorders) {
              if (!response.status || response.status.length === 0){
                if (workitems.workorders.length > 0) newWO = workitems.workorders;
                else newWO = [newAllworkOrderItems];
              }
              else {
                let pendingApproval = [];
                let neworkorders = workitems.workorders.map((e,index)=>{
                  let newstatuswo = response.status.filter((s)=> s.working_order.toString() === e.workorder && e.manager === s.manager);
                  if (newstatuswo[0]?.status === 2) {
                    e.status =  "pending";
                    if (newstatuswo[0].comments) e.comments = newstatuswo[0].comments;
                    pendingApproval.push(index);
                  };
                  return e;
                });
                newRejectedWO = pendingApproval;
                newWO = neworkorders;
              }
            }
          }
          if (response.dayshift.editstage > 0) {
            let canProceed = shouldProceed(today);
            let newAllStatuses = response.status?.filter((s)=> s.status < 3);
            completedStatuses = response.status?.filter((s)=> s.status > 2);
            if (!canProceed || (response.status?.length > 0 && newAllStatuses?.length === 0 && userData.role < 20)) setIsCompleted(true);
            else setIsCompleted(false);
          }
          else setIsCompleted(false);
          if (response.dayshift.holidays) {
            setHolidays(true);
            setPaidleave("0");
          }
          else if (response.dayshift.paidleave) {
            setHolidays(false);
            setPaidleave(response.dayshift.paidleave);
          }
        }
        else {
          setHolidays(false);
          setPaidleave("0");
          setIsCompleted(false);
        }
        setFinishedWO(completedStatuses);
        setWorkingOrders(newWO);
        let startingWO = JSON.parse(JSON.stringify([...newWO]));
        setStaticWorkingOrders(startingWO);
        setOriginalWorkingOrders(startingWO);
        setRejectedWO(newRejectedWO);
        setProjectIDs(projectsIDS);
        retriveproddata(projectsIDS.map(e=>e.wo),newWO,0)
      }
  }
  useEffect(()=>{
    sessionStorage.clear();
    if (productionModel === null || !leaveTypesModel || !workedHoursModel) {
      get(`/server/request/workreportmodels`)
      .then((response) =>{
        if (response.prodmodel?.visible2) {
          let userType = response.prodmodel?.visible2?.subcode?.filter(e=>userData?.subcode?.includes(e));
          if (userType?.length > 0 || userData.role > 19) setProductionModel(response.prodmodel);
        }
        else if (response.prodmodel?.elements) setProductionModel(response.prodmodel);
        if (response.leavetypesmodel?.types && response.leavetypesmodel?.name) setLeaveTypesModel(response.leavetypesmodel);
        if (response.workhoursmodel) setWorkedHoursModel(response.workhoursmodel);
        setAllModels(true);
      });
    }
    else setAllModels(true);
  },[])
  useEffect(()=>{
    if (today && allModels) {
      clearAll();
      let query = `/server/request/workreportdate?date=${today}`;
      if (editingDate?.employee) query+=`&employee=${editingDate.employee}`;
      get(query).then((response) => getWorkOrderData(response));
    }
  },[today,allModels])
  const assignHolidays = () => {
    let newHolidays = !holidays;
    setHolidays(newHolidays);
    if (newHolidays) setPaidleave("0");
  }
  const assigntPaidleave = (e) => {
    let newpaidleave = e.target.value;
    setPaidleave(newpaidleave);
    if (newpaidleave !== "0") setHolidays(false);
  }
  const assignManager = (index,id) => {
    let newworkingOrders = [...workingOrders];
    newworkingOrders[index].manager = id;
    setWorkingOrders(newworkingOrders);
  }
  const retriveproddata = (id,working_Orders,index) => {
    let filterData = (working_Orders[index]?.prod) ? `&f=${JSON.stringify(working_Orders[index].prod)}`  : "";
    get(`/server/request/getprodcontent?wo=${id}${filterData}`)
    .then((response)=> {
      if (!viewandread && response.items2extract?.viewandread) setviewandread(response.items2extract.viewandread);
      if (response.docs || response.allfilters || response.filters || response.items2extract) {
        let newDocsAndProdData = {...docsAndProdData};
        let newProdFilters = {...prodFilters};
        id.forEach(wo_id =>{
          let newResponse = {...response}
          let newDocs = newResponse.docs?.filter(e=>e.wo.toString()===wo_id.toString());
          if (newDocs?.length > 0) newResponse.docs = newDocs
          else delete newResponse.docs;
          newResponse.dataset = [];
          let newFilters = response.allfilters.filter(e=>e.wo?.toString() === wo_id.toString()).map(e=>e.filters);
          newResponse.allfilters = newFilters;
          newResponse.filtervals = newFilters?.map(e=>JSON.parse(e));
          newDocsAndProdData[wo_id] = newResponse;
          newDocsAndProdData[wo_id].filters = response.filters;
          newDocsAndProdData[wo_id].items2extract = response.items2extract;
          if (newResponse.filtervals?.length > 0) {
            let filterK = Object.keys(newResponse.filtervals[0]);
            let groupname = [];
            filterK.forEach((item) => {
              let nam = response.filters.filter(e=>e.id.toString()===item.toString());
              if (nam[0].name) groupname.push(nam[0].name);
            });
            newDocsAndProdData[wo_id].filtersGroupName = groupname;
            if (response.items2extract?.wofitler) {
              let wofilterpos = response.filters.filter(e=>e.id.toString()===response.items2extract?.wofitler);
              if (wofilterpos[0]) newDocsAndProdData[wo_id].subfilterName = wofilterpos[0].name;
            }
          }
          newProdFilters[wo_id] = {all:[],display:{0:[]},subset:[],selected:{},dataset:[]};
        })
        setDocsAndProdData(JSON.parse(JSON.stringify(newDocsAndProdData)));
        setProdFilters(newProdFilters);
      }
    });
  }
  useEffect(()=>{
      if (!prodFilters?.isempty &&
          Object.keys(prodFilters).length > 0 &&
          workingOrders[0]?.workorder &&
          prodFilters[workingOrders[0].workorder]?.subset &&
          Object.keys(prodFilters[workingOrders[0]?.workorder]?.subset)?.length === 0 &&
          workingOrders.length > 0) {
      let newProdFilters = {...prodFilters};
      let isEmpty = true;
      let allids2retrive = [];
      workingOrders.forEach((wo, i) => {
        let current_wo = wo.workorder;
        wo.prod.forEach((item,prod_item)=> {
          if (newProdFilters[current_wo]) {
            isEmpty = false;
            if (!newProdFilters[current_wo].subset[prod_item]) newProdFilters[current_wo].subset[prod_item] = []
            if (!newProdFilters[current_wo].selected[prod_item]) newProdFilters[current_wo].selected[prod_item] = {}
            if (!newProdFilters[current_wo].display[prod_item]) newProdFilters[current_wo].display[prod_item] = []
            Object.keys(item).filter(e=>e!=="id").forEach(i=>{
              if (docsAndProdData[current_wo].differentItems && Object.keys(docsAndProdData[current_wo].differentItems).includes(i)) {
                newProdFilters[current_wo].subset[prod_item][i] = docsAndProdData[current_wo].differentItemsPos[i][current_wo][item[i]];
                newProdFilters[current_wo].selected[prod_item][i] = [item[i]];
              }
            });
            newProdFilters[current_wo].display[prod_item] = [item.id];
            allids2retrive.push(item.id);
          }
        });
      });
      newProdFilters.isempty = isEmpty;
      setProdFilters(newProdFilters);
    }
  },[prodFilters,workingOrders])
  const asignProject = (index,id,manager) => {
    let newProjectIDs = [...projectIDs];
    if (!newProjectIDs[index]) newProjectIDs[index] = {};
    newProjectIDs[index].manager=manager;
    newProjectIDs[index].wo=id;
    setProjectIDs(newProjectIDs);
    let newworkingOrders = [...workingOrders];
    newworkingOrders[index].workorder = id;
    setWorkingOrders(newworkingOrders);
    if (productionModel) retriveproddata([id],workingOrders,index);
  }
  const assignDescription = (index,text) => {
    let newworkingOrders = [...workingOrders];
    newworkingOrders[index].description = text;
    setWorkingOrders(newworkingOrders);
  }

  const assignNumber = (element,index,param1,param2,param3,param4) => {
    let newworkingOrders = [...workingOrders];
    if (element.type === "number") {
      let val = (!isNaN(workingOrders[index][element.id][param4])) ? workingOrders[index][element.id][param4] : 0;
      if (param1 === "add" && (param3 === undefined || val + param2 <= param3)) newworkingOrders[index][element.id][param4] = val + param2;
      else if (param1 === "reduce" && (param3 === undefined || val - param2 >= param3)) newworkingOrders[index][element.id][param4] = val - param2;
    }
    else if (element.type === "checkbox" || element.type === "dropdown" || element.type === "kilometers") {
      newworkingOrders[index][element.id] = param1;
    }
    setWorkingOrders(newworkingOrders);
  }
  const assignProdText = (ind1,ind2,id,val,rowid,pushArray,group,notstored,prevWorkingOrders) => {
    let newworkingOrders = (prevWorkingOrders) ? [...prevWorkingOrders] : [...workingOrders];
    let newArray = [...newworkingOrders[ind1].prod];
    if (typeof newArray[ind2] !== "object") newArray[ind2] = {};
    if (group) id.forEach((e,p)=>newArray[ind2][e] = val[p]);
    else if (pushArray) newArray[ind2][id] = [...newArray[ind2][id],val];
    else newArray[ind2][id] = val;
    if (rowid) newArray[ind2].id = rowid;
    if (staticWorkingOrders[ind1]?.prod && staticWorkingOrders[ind1].prod[ind2] && staticWorkingOrders[ind1].prod[ind2][id]?.length > 0) {
      if (!newArray[ind2].prevval) newArray[ind2].prevval = {}
      newArray[ind2].prevval[id] = staticWorkingOrders[ind1].prod[ind2][id];
    }
    newworkingOrders[ind1].prod = newArray;
    if (notstored) return newworkingOrders;
    else setWorkingOrders(newworkingOrders);
  }
  const wo2DB = (workingOrders2) => {
    let workingOrdersNew = (workingOrders2) ? workingOrders2 : workingOrders;
    let defhours = workedHoursModel.elements.filter((e)=>e.def==="dailyhours");
    let defNumber = (defhours && defhours[0] && defhours[0].id) ? defhours[0].id : null;
    let dailyHoursValsPerWOPerType = (defNumber && (!holidays || paidleave !== "0")) ? workingOrdersNew.map((c)=>c[defNumber].reduce((a,c,i) => {
      if (defhours[0].multipleoptions && defhours[0].multipleoptions[i]) a[defhours[0].multipleoptions[i]] = c;
      else a.default = c;
      return a;
    },{})) : [];
    let dailyHoursValsPerWO = (defNumber) ? workingOrdersNew.map((c)=>c[defNumber].reduce((a,c)=>{a+=c;return a},0)) : [];
    let dailyHoursVal = (dailyHoursValsPerWO.length > 0) ? dailyHoursValsPerWO.reduce((a,c)=>{a+=c;return a},0) : (defhours[0] && defhours[0].default) ? defhours[0].default : 8;
    let finalworkingOrders = workingOrdersNew.map((c,i)=>{
      let newC = {...c}
      newC[defNumber] = dailyHoursValsPerWOPerType[i]
      return newC
    });
    return [dailyHoursValsPerWOPerType, dailyHoursValsPerWO, dailyHoursVal, finalworkingOrders];
  }
  const saveshiftdata = (final_action) => {
    setReady2sign(false);
    let [dailyHoursValsPerWOPerType, dailyHoursValsPerWO, dailyHoursVal, finalworkingOrders] = wo2DB();
    let data2send = {
      workorders: (!holidays && paidleave === "0") ? finalworkingOrders : null,
      date: today,
      start: (!holidays && paidleave === "0" && autoHours === 0) ? workingHoursStart : null,
      end: (!holidays && paidleave === "0" && autoHours === 0) ? workingHoursEnd : null,
      holidays: holidays,
      paidleave: paidleave,
      signature: signatureIMG,
      dailyhours: (!holidays && paidleave === "0") ? dailyHoursVal : 0,
      dailyhoursperwo: (!holidays && paidleave === "0") ? dailyHoursValsPerWO : null,
      dailyhoursperwopertype: JSON.stringify({hoursbytype:dailyHoursValsPerWOPerType})
    }
    let woDat = Object.keys(docsAndProdData);
    let woDatWithProdFilters = woDat.filter(e=>docsAndProdData[e].items2extract);
    if (woDatWithProdFilters.length > 0) {
      let i2e = docsAndProdData[woDatWithProdFilters[0]].items2extract
      let filters = (i2e.filters) ? i2e.filters : [];
      if (i2e.wofitler) filters.push(parseInt(i2e.wofitler))
      if (filters.length > 0) data2send.prodfilters = filters;
    }
    if (autoHours > 0 || (timings && editingDate?.employee)) {
      let newtimings = [...timings];
      let endTiming = (new Date).getTime();
      if (final_action !== "stay") {
        if (!newtimings[newtimings.length-1].end) newtimings[newtimings.length-1].end = endTiming;
        if (canTrack && currentLocation) newtimings[newtimings.length-1].endtrack = currentLocation;
        data2send.stage = 2;
      }
      else data2send.stage = 1;
      data2send.timings = JSON.stringify({times:newtimings});
    }
    if (commentText) data2send.comment = commentText;
    if (dayshiftID) data2send.dayshift = dayshiftID;
    if (editingDate?.employee) data2send.employee = editingDate.employee;
    let storageURL = (dayshiftID || rejectedWO.length > 0 || Boolean(editingDate?.employee && !editingDate.isnew) || (autoHours === 2 && today === startingDay && (!editingDate?.employee || !editingDate?.isnew))) ? '/server/request/updateworkshift' : '/server/request/storeworkshift';
    post(storageURL,data2send).then((response)=>{
      if (final_action === "stay") setIsSaved(response);
      if (response.stored && Number.isInteger(Number(response.stored))) {
        setStaticWorkingOrders(JSON.parse(JSON.stringify(finalworkingOrders)));
        setSavedWO(finalworkingOrders);
        setDayshiftID(response.stored);
        if (final_action !== "stay") gotoreports(rejectedWO.length);
      }
    })
  }
  const projectName = (index) => {
    if (projects && projects.length > 0) {
      try {
        let wr = projects.filter((e)=>e.work_report===workingOrders[index].workorder)[0].work_report;
        let de = projects.filter((e)=>e.work_report===workingOrders[index].workorder)[0].description
        return `${wr} ${de}`
      }
      catch (err){
        console.log(err)
      }
    }
  }
  const shouldProceed = (date2set) => {
    if (userData?.role >= 40) return true;
    else if (userData && userData.role < 40 && workedHoursModel && workedHoursModel['limit-days'] > 0) {
        let selectedDate = new Date(date2set);
        selectedDate.setHours(0,0,0,0);
        let earliest = new Date();
        earliest.setHours(0,0,0,0);
        earliest.setDate(earliest.getDate() - parseInt(workedHoursModel['limit-days']));
        let canProceed = (selectedDate>=earliest) ? true : false;
        return canProceed;
    }
    else return false;
  }
  const checkDate = (e) => {
    let date2set = e.target.value;
    let query = `/server/request/checkdateshift?date=${date2set}`;
    if (editingDate?.employee) query+=`&employee=${editingDate.employee}`;
    get(query)
    .then((response)=>{
      sessionStorage.clear();
      if (!isNaN(response.count) && response.count === 0) {
        if (date2set !== startingDay) {
          setAutoHours(0);
          }
        let canProceed = shouldProceed(date2set);
        if (canProceed) {
          setWorkOrderItems(workOrderFirstItems);
          setProductos([[]]);
          setHolidays(false);
          setPaidleave("0");
          setSignatureID();
          clearAll();
          setToday(date2set);
          setisTooEarlyDate(false);
        }
        else setisTooEarlyDate(true);
      }
      else {
        setToday(date2set);
      }
    })
  }
  const presentDate = (date2) => {
    let today = new Date(date2)
    return today.toLocaleDateString()
  }
  const inputProps = {
    step: 300,
  };
  const displaysummary = (val)=> {
    let newworkingOrders = workingOrders.filter((e,i)=>{
      if (e.manager && e.workorder) return true
      else return false;
    })
    setWorkingOrders(newworkingOrders);
    setSignatureIMG(val)
  }
  const displayNumberArray = (element,e) => {
    let options = (element.multipleoptions) ? element.multipleoptions : [""];
    return (
      <>
        <Grid item xs={4}>
          {element.id}:
        </Grid>
        {options.map((option,pos)=>
          <Grid item xs={4}>
            {option} {e[element.id][pos]}
          </Grid>
        )}
      </>
      )
  }
  const getPosition = () => {
    return new Promise((res, rej) => {
       if (navigator?.geolocation) navigator.geolocation.getCurrentPosition(res, rej);
   });
  }
  const getTimingLocation = () => {
    let geostatus;
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((pos)=>{
        setCurrentLocation({lat:pos.coords.latitude,long:pos.coords.longitude});
      });
      navigator.geolocation.watchPosition(function(position) {
        geostatus="track";
      },
      function(error) {
        if (error.code == error.PERMISSION_DENIED) {
          geostatus="notrack";
        }
      });
    }
  }
  const getLocation = (index,index2,id) => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((pos)=>assignProdText(index,index2,id,`${pos.coords.latitude}&${pos.coords.longitude}`));
      }
    }
  const imageupload = (index,index2,id,event,grupSet) => {
    setDisplayCircularProgress("Actualizando");
    let formData = new FormData();
    formData.append("image", event.target.files[0]);
    postPlain('/server/request/uploadimage',formData,{'enctype': 'multipart/form-data'})
    .then((response)=>{
      if (response.stored) {
        assignProdText(index,index2,id,[...grupSet,response.stored]);
        saveshiftdata("stay");
      }
      setDisplayCircularProgress(false);
      })
    }
  const imagedelete = (index,index2,id,name,grupSet) => {
    get('/server/request/deldocbytype?name='+name)
    .then((response)=>{
      if (response.deleted) {
        let start = grupSet.indexOf(name);
        grupSet.splice(start, 1);
        assignProdText(index,index2,id,grupSet);
        saveshiftdata("stay");
      }
      })
    }
  const leaveTypeName = () => {
    let paidtype = leaveTypesModel.types.filter((e)=>e.def===paidleave);
    if (paidtype.length > 0 && paidtype[0].name) return paidtype[0].name;
  }
  const sendStartingTime = (data2send,geoloc=false) => {
    post('/server/request/startingtime',data2send)
    .then((response)=>{
      if (response.stored) {
        setAutoHours(2);
        if (response.timing) {
          let timingsSet = JSON.parse(response.timing);
          if (timings?.times) setTimings([...timings.times, ...timingsSet.times]);
          else setTimings(timingsSet.times);
          }
        if (response.id) setDayshiftID(response.id);
        if (geoloc && !data2send.track) {
          getPosition().then((pos)=>{
            let newPos = {lat:pos.coords.latitude,long:pos.coords.longitude};
            setCurrentLocation(newPos);
            data2send.track = newPos;
            sendStartingTime(data2send,false);
          }).catch(e=>post('/server/request/geoerror',{error:e.code}));
        }
      }
    });
  }
  const startingEndingTime = (addNewVal,step) => {
    let startTiming = (today === startingDay) ? (new Date()).getTime() : null;
    let data2send = {date:today,time:startTiming,timings:timings,step:step};
    if (addNewVal === "add") data2send['add'] = true;
    if (canTrack && currentLocation && today === startingDay) {
      data2send.track = currentLocation;
      sendStartingTime(data2send,false);
    }
    else if (!canTrack || today !== startingDay) sendStartingTime(data2send,false);
    else {
      sendStartingTime(data2send,true);
    }
    if (today === startingDay) setViewTimings("hidde");
  }
  const displayTotalTime = () => {
    if (timings?.length > 0) {
      let diffTimeNum = timings.reduce((a,c)=>{
        if (c.start && c.end) {
          let timeSet1 = new Date(c.start);
          let timeSet2 = new Date(c.end);
          a += timeSet2.getTime()-timeSet1.getTime();
        }
        return a;
      },0);
      return number2time(diffTimeNum);
    }
  }
  const setNewTiming = (value,step,row) => {
    let startTiming = new Date(`${today}T${value}`);
    let newTimings = (timings) ? [...timings] : [{"start":null,"end":null}];
    if (!newTimings[row]) newTimings[row] = {"start":null,"end":null};
    newTimings[row][step] = startTiming.getTime();
    newTimings[row][`manual${step}`] = (new Date).getTime();
    let data2send = {date:today,timings:newTimings,reset:true};
    sendStartingTime(data2send);
  }
  const newChangedTiming = (value,step,row) => {
      let steps = {"start":0,"end":1};
      let t = (new Date(`${today}T${value}`)).getTime();
      let isOk = 0;
      if (timings && timings[row] && timings[row][step] && timings[row][step] === t) isOk = 6;
      else {
        let timingsSetsA = timings.map((e)=>{
          let starting = (e.start) ? new Date(e.start).getTime() : null;
          let ending = (e.end) ? new Date(e.end).getTime() : null;
          return [starting, ending];
        });
        let timingsSets = [...timingsSetsA].sort((a,b)=>{
          if ((a[1] && b[0] && a[1]<b[0]) || (!a[1] && a[0] && b[0] && a[0]<b[0]) || (!b[0] && a[1] && b[1] && a[1]<b[1])) return -1
          else if (a[1]===b[0]) return 0
          else return 1
        });
        let newRow = timingsSets.reduce((a,c,pos)=>{
          if (timingsSetsA[row] === c) a = pos;
          return a;
        },null)
        let timeSet = (new Date(t)).getTime();
        let startDateTiming = (new Date(`${today}T00:00:00`)).getTime();
        let endDateTiming = (new Date(`${today}T23:59:59`)).getTime();
        if (t <= endDateTiming && t >= startDateTiming) {
          for (var i=0;i<timingsSets.length;i++) {
            if (newRow === i && (
                (step === "start" && (!timingsSets[i][1] || t < timingsSets[i][1]) && (i===0 || !timingsSets[i-1][1] || t > timingsSets[i-1][1])) ||
                (step === "end" && (!timingsSets[i][0] || t > timingsSets[i][0]) && (i===timingsSets.length-1 || !timingsSets[i+1][0] || t < timingsSets[i+1][0]))
              )) isOk = 2;
          }
        }
      }
      if (isOk) setNewTiming(value,step,row);
      else {
        if (!reviewTimesMode) {
          let newTimings = [...timings];
          newTimings[row][step] = null;
          setTimings([...newTimings]);
        }
      }
      setReviewTimesMode(false);
  }
  const displayTime = (t) => {
    if (t) {
      let timeSet = new Date(t);
      let h = timeSet.getHours();
      if (h<10) h = `0${h}`;
      let m = timeSet.getMinutes();
      if (m<10) m = `0${m}`;
      return `${h}:${m}`;
    }
  }
  const selectMultiProdFilterIDs = (fullDataSet) => {
    let currentval = sessionStorage.getItem("fullDataSet")
    let currentvalParsed = JSON.parse(currentval);
    let newVal = (currentval) ? [...currentvalParsed, ...fullDataSet] : fullDataSet;
    sessionStorage.setItem("fullDataSet", JSON.stringify(newVal));
    if (!multiProdFilterIDs) setMultiProdFilterIDs(true);
  }
  useEffect(()=>{
    if (multiProdFilterIDs) {
      let currentval = sessionStorage.getItem("fullDataSet");
      sessionStorage.removeItem("fullDataSet");
      let currentvalParsed = JSON.parse(currentval);
      let newVal = []
      setMultiProdFilterIDs(false);
      if (currentval && currentvalParsed.length > 0) {
        let fullDataSetStringified = currentvalParsed.map(e=>JSON.stringify(e));
        let newSet = fullDataSetStringified.filter((value, index) => fullDataSetStringified.indexOf(value) === index).map(e=>JSON.parse(e));
        selectProdFilterIDs(...newSet[0],[...newSet].slice(1));
        }
    }
  },[multiProdFilterIDs]);

  const continueSelectProdFilterIDs = (response,thisWO,index,index2,val,subval,title,multiple,prevProdfilter,prevWorkingOrders,prevRow2select) => {
    let newProdFilters = (prevProdfilter) ? {...prevProdfilter} : {...prodFilters};
    if (!newProdFilters[thisWO].selected[index2]) newProdFilters[thisWO].selected[index2] = {};
    newProdFilters[thisWO].selected[index2].filter = val;
    delete newProdFilters[thisWO].selected[index2].reset;
    if (!newProdFilters[thisWO].subset[index2]) newProdFilters[thisWO].subset[index2] = [];
    if (response.rows) newProdFilters[thisWO].subset[index2] = response.rows;
    let valParse = JSON.parse(val)
    let ids = Object.keys(valParse);
    let values = ids.map(e=>valParse[e]);
    let row2select = [];
    if (subval) {
      newProdFilters[thisWO].selected[index2].subfilter = subval;
      ids.push(docsAndProdData[thisWO]?.items2extract?.wofitler);
      values.push(subval);
      row2select = response.rows.filter(e=>e.subfilters === subval);
      if (row2select.length > 0) row2select = row2select.map(e=>e.id);
    }
    if (prevRow2select) row2select.push(...prevRow2select);
    if (multiple && multiple.length > 0) {
      let newPrevWorkingOrders = assignProdText(index,index2,ids,values,null,null,true,true,prevWorkingOrders);
      selectProdFilterIDs(...multiple[0],[...multiple].slice(1),{...newProdFilters},newPrevWorkingOrders,row2select);
    }
    else {
      if (row2select.length > 0) setRows2retrive({items:row2select,filter:val});
      setProdFilters(newProdFilters);
      assignProdText(index,index2,ids,values,null,null,true);
    }
  }
  const selectProdFilterIDs = (thisWO,index,index2,val,subval,title,multiple,prevProdfilter,prevWorkingOrders,prevRow2select) => {//console.log("1",val)
    if (val?.length > 0) {
      let oldval = sessionStorage.getItem(`${thisWO}-${val}-prodcontent4filter`);//console.log("2",oldval)
      if (!oldval) {
        get(`/server/request/getprodcontent4filter?wo=${thisWO}&filters=${val}`)
        .then((response)=> {
          sessionStorage.setItem(`${thisWO}-${val}-prodcontent4filter`,JSON.stringify(response));
          continueSelectProdFilterIDs(response,thisWO,index,index2,val,subval,title,multiple,prevProdfilter,prevWorkingOrders,prevRow2select)
        });
      }
      else continueSelectProdFilterIDs(JSON.parse(oldval),thisWO,index,index2,val,subval,title,multiple,prevProdfilter,prevWorkingOrders,prevRow2select)
    }
    else {
      let newProdFilters = {...prodFilters};
      newProdFilters[thisWO].selected[index2] = {reset:true};
      newProdFilters[thisWO].subset[index2] = [];
      setProdFilters(newProdFilters);
      assignProdText(index,index2,[],[],null,null,true);
    }
  }

  const selectProdSubfilter = (thisWO,index,index2,e,displayval) => {
    if (e) {
      let newProdFilters = {...prodFilters};
      let items = e.target.value.split("-");console.log("newProdFilters[thisWO]",thisWO,index2,newProdFilters[thisWO],newProdFilters[thisWO].selected[index2])
      newProdFilters[thisWO].selected[index2].subfilter = items[0];
      setProdFilters(newProdFilters);
      assignProdText(index,index2,docsAndProdData[thisWO]?.items2extract?.wofitler,items[0],items[1]);
      setRows2retrive({items:[items[1]],filter:displayval});
      setProductionButtonSelected([index,index2]);
      window.scrollTo(0, findPosition(document.getElementById("production"+index)));
    }
  }

  useEffect(()=>{
    if (rows2retrive?.items?.length > 0) {
      get(`/server/request/getprodrowscontent?ids=${rows2retrive.items}&items=${viewandread}`)
      .then((response)=> {
        let newdocsAndProdData = {...docsAndProdData};
        let wos = Object.keys(docsAndProdData);
        let allItems = viewandread.map(id=>{
          return  {id: id, value: ""}
        })
        response.rows?.forEach(((e,p)=>{
          let existing = newdocsAndProdData[e.wo].dataset.filter(f=>f.id===e.id);
          if (existing.length === 0) newdocsAndProdData[e.wo].dataset.push({id:e.id,content:e.content,filter:rows2retrive.filter});
          else newdocsAndProdData[e.wo].dataset = newdocsAndProdData[e.wo].dataset.map(f => {
            if (f.id !== e.id) return f;
            else return {id:e.id,content:e.content,filter:e.filter};
          })
        }));
        setDocsAndProdData(JSON.parse(JSON.stringify(newdocsAndProdData)));
        setRows2retrive({items:[],filter:null});
      });
    }
  },[rows2retrive]);
  const docsAndProdDataFilters = (index,index2,thisWO,woData2retrive,fullLength,allFilterValues,allRows,allAddProdButtons) => {
    let allItems = [];
    let allSubFilterItems = [];
    let itemDetails = [];
    let displayval = "";
    let newItemDetails = [];
    let isInitialLoad = false;
    if (docsAndProdData[thisWO]?.filtervals && docsAndProdData[thisWO].filtersGroupName) {
       let subfilterSelectVal = "";
       let name = docsAndProdData[thisWO].filtersGroupName.join(" -- ");
       let subdisplayval = "";
       let subdisplayid;
       let completed = false;
       let next_Filter_DisplayVal = "";
       if (prodFilters[thisWO]?.selected[index2]?.reset) displayval = "";
       else if (prodFilters[thisWO]?.selected[index2]?.filter)  {
         displayval = prodFilters[thisWO].selected[index2].filter;
         if (prodFilters[thisWO]?.selected[index2+1]?.filter) next_Filter_DisplayVal = prodFilters[thisWO].selected[index2+1].filter;
       }
       else if (workingOrders[index]?.prod[index2] && docsAndProdData[thisWO].items2extract?.filters) {
         let newdisplayval = {}
         let newNextfilter2DisplayVal = {}
         docsAndProdData[thisWO].items2extract.filters.forEach(e=>{
           if (workingOrders[index].prod[index2][e]) completed = true
          newdisplayval[e] = workingOrders[index].prod[index2][e]
          if (workingOrders[index].prod[index2+1]) newNextfilter2DisplayVal[e] = workingOrders[index].prod[index2+1][e];
        });
        displayval = JSON.stringify(newdisplayval);
        if (workingOrders[index].prod[index2+1]) next_Filter_DisplayVal = JSON.stringify(newNextfilter2DisplayVal);
        isInitialLoad = true;
       }
       if (prodFilters[thisWO]?.selected[index2]?.subfilter) {
         subfilterSelectVal = prodFilters[thisWO].selected[index2].subfilter;
         subdisplayval = prodFilters[thisWO].selected[index2].subfilter;
         let subdisplayidSet = prodFilters[thisWO].subset[index2].filter(e=>e.subfilters === subdisplayval.toString());
         if (subdisplayidSet[0]?.id) subdisplayid = subdisplayidSet[0].id;
       }
       else if (workingOrders[index]?.prod[index2] && docsAndProdData[thisWO].items2extract?.wofitler) {
          let completed = false;
          subfilterSelectVal = workingOrders[index].prod[index2][docsAndProdData[thisWO].items2extract.wofitler];
          subdisplayval = workingOrders[index].prod[index2][docsAndProdData[thisWO].items2extract.wofitler];
          subdisplayid =  workingOrders[index].prod[index2].id;
       }
       allRows[index2] = (subdisplayid)? subdisplayid : null;
       if (completed && !prodFilters[thisWO]?.selected[index2]?.reset) woData2retrive.push([thisWO,index,index2,displayval,subdisplayval,"completed"])// selectProdFilterIDs(thisWO,index,index2,displayval,subdisplayval,"completed")
       if (fullLength === index2+1) selectMultiProdFilterIDs(woData2retrive)
       let values = {}
       docsAndProdData[thisWO].filtervals.map((e,pos)=>{
         values[docsAndProdData[thisWO].allfilters[pos]] = docsAndProdData[thisWO].items2extract.filters.map(f=>e[f]).join(" -- ");
       })
       allFilterValues[index2] = displayval;
       let prodFiltersSelectedKeys = Object.keys(prodFilters[thisWO]?.selected)
       let prodFiltersSelected = (prodFiltersSelectedKeys?.length > 0) ? prodFiltersSelectedKeys.map(e=>prodFilters[thisWO].selected[e]).filter(e=>e.filter).map(e=>e.filter) : [];
       let sections2display = docsAndProdData[thisWO].allfilters?.filter(e=>!prodFiltersSelected.includes(e) || e === displayval).sort();
       //console.log(docsAndProdData[thisWO].allfilters?.filter(e=>!prodFiltersSelected.includes(e) || e === displayval),sections2display)
       //console.log(docsAndProdData[thisWO].allfilters)
       let cssClass = (productos[index].length < 1 || docsAndProdData[thisWO].allfilters?.indexOf(displayval)%2) ? classes.prodsection1 : classes.prodsection2;
       if ((index2 === 0 || allFilterValues[index2-1] !== allFilterValues[index2]) && sections2display.length > 0) allItems.push(
          <Grid item key={`filter-${index}-${index2}-filters`} xs={12}>
            <FormControl fullWidth className={classes.fullblue}>
              <InputLabel id={`label${index2}`}>
                {name}
              </InputLabel>
              <Select
                key={`filter-${index}-${index2}-select`}
                fullWidth
                value={displayval}
                className={cssClass}
                onChange={(e)=>selectProdFilterIDs(thisWO,index,index2,e.target.value,null,"select")}>
                 {sections2display.map((value,pos)=>
                   <MenuItem key={pos} value={value} name={value}>{values[value]}</MenuItem>
                 )}
              </Select>
            </FormControl>
          </Grid>
        )
       if ((prodFilters[thisWO]?.subset[index2]?.length > 0 || workingOrders[index].prod[index2]?.length > 0 ) && subfilterSelectVal) {
         allSubFilterItems.push(
           <Grid item key={`filter-${index}-${index2}-subfitler1`} xs={4} className={classes.topbottombutton}>
             <Button
               variant="contained"
               key={`filter-${index}-${index2}-button`}
               className={(productionButtonSelected[0] === index && productionButtonSelected[1] === index2) ? classes.prodsection0 : cssClass}
               onClick = {()=>setProductionButtonSelected([index,index2])}
             > {subfilterSelectVal}
             </Button>
           </Grid>
         );
       }

      let rows2display = prodFilters[thisWO].subset[index2]?.filter(e=>!allRows.includes(e.id));
      if (next_Filter_DisplayVal !== allFilterValues[index2] && rows2display?.length > 0) {
        allAddProdButtons[displayval] = index2;
        let globalButtonVal = sessionStorage.getItem(`g_p_b_${index}_${displayval}`);
          if ((!globalButtonVal || globalButtonVal === index2.toString()) && productionButtonSelected[0] !== null) {
            allSubFilterItems.push(
              <Grid item key={`filter-${index}-${index2}-subfitler2`} xs={4} className={classes.topbottombutton}>
                <Button
                  variant="contained"
                  key={`filter-${index}-${index2}-button`}
                  className={cssClass}
                  onClick = {()=>{addProd(index,fullLength-1,true,displayval);allAddProdButtons[displayval] = index2+1;}}
                > +
                </Button>
              </Grid>
            );
          }
          else if ((!globalButtonVal || globalButtonVal === (index2-1).toString() || globalButtonVal === allAddProdButtons[displayval].toString()) && productionButtonSelected[0] === null) {
             allSubFilterItems.push(
             <Grid item key={`filter-${index}-${index2}-subfitler3`} xs={4}>
               <FormControl fullWidth className={classes.fullblue}>
                 <InputLabel id={`label${index2}`}>
                   {docsAndProdData[thisWO].subfilterName}
                 </InputLabel>
                 <Select
                    key={`filter-${index}-${index2}-select`}
                    fullWidth
                    className={cssClass}
                    value={subdisplayval+"-"+subdisplayid}
                    onChange={(e)=>selectProdSubfilter(thisWO,index,fullLength-1,e,displayval)}>
                    {rows2display.map((value,pos)=>
                      <MenuItem key={value.id} value={value.subfilters+"-"+value.id} name={value.id}>{value.subfilters}</MenuItem>
                    )}
                 </Select>
               </FormControl>
             </Grid>
           );
          }
      }
      if (Boolean(productionButtonSelected.toString() === [index,index2].toString() &&
          prodFilters[thisWO]?.selected[index2]?.filter &&
          prodFilters[thisWO]?.selected[index2]?.subfilter &&
          workingOrders[index]?.prod &&
          workingOrders[index].prod[index2])) {
        itemDetails.push(
          <>
            <Grid item key={`filter-${index}-${index2}-titleA`} xs={11}>
              <Typography variant="h6" color="textSecondary">
                {docsAndProdData[thisWO].subfilterName} {subfilterSelectVal}
              </Typography>
            </Grid>
            <Grid item key={`filter-${index}-${index2}-titleB`} xs={1}>
              <CloseIcon onClick={()=>setProductionButtonSelected([-1,-1])}/>
            </Grid>
          </>
        );
        let itemData = docsAndProdData[thisWO].dataset?.filter(e=>e.id === subdisplayid);
        if (itemData?.length === 1) docsAndProdData[thisWO].filters?.forEach((e,p)=>{
            if (itemData?.length > 0 && itemData[0]?.content) {
              if (e.wo_visible) {
                let text = itemData[0].content.filter(g=>g.id.toString() === e.id.toString());
                let currentVal = (text[0]?.value) ? text[0].value : " ";
                itemDetails.push(
                  <Grid item key={`filter1-${index2}-${e.name}-${p}`} xs={6}>
                    <TextField
                      className={classes.full}
                      key={`${index2}-${e.name}-${p}-key`}
                      label={e.name}
                      disabled={true}
                      value={currentVal}
                      />
                  </Grid>
                )
              }
              else if (e.wo_item) {
                let rowID = prodFilters[thisWO]?.display[index2];
                let text = itemData[0].content.filter(g=>g.id.toString() === e.id.toString());
                let prodDBval = (text[0]?.value) ? text[0].value : null;
                let originalVal = (originalWorkingOrders[index]?.prod && originalWorkingOrders[index]?.prod[index2]) ? originalWorkingOrders[index]?.prod[index2][e.id] : null;
                let currentVAlue = (workingOrders[index]?.prod && workingOrders[index]?.prod[index2]) ? workingOrders[index]?.prod[index2][e.id] : null;
                let editableIfStored = Boolean(!prodDBval || e.type === "IMAGE" || originalVal?.toString() === prodDBval?.toString());
                let newValue = (editableIfStored && currentVAlue) ? currentVAlue : (prodDBval) ? prodDBval : "";//console.log("newValue",newValue,editableIfStored , currentVAlue,prodDBval)
                let class4items = (Boolean(prodDBval && !editableIfStored)) ? classes.full : classes.fullblue;
                if (newValue && ((e.type === "NUMB" && !Number.isInteger(Number(newValue)))) ||
                                ((e.type === "DECIM" && (newValue && !Boolean(newValue % 1 >= 0)))) ||
                                ((e.type === "PERCENT" && (newValue && !Boolean(Number(newValue)>=0 && Number(newValue)<=100))))) class4items = classes.fullred;
                itemDetails.push(
                  <>
                      {["TEXT","NUMB","DECIM","PERCENT","DATE"].includes(e.type) &&
                      <>
                      <Grid item key={`filter2-${index2}-${e.name}-${p}`} xs={e.type==="TEXT" ? 8 : 6}>
                        <TextField
                          className={class4items}
                          key={`${index2}-${e.name}-${p}-key`}
                          label={e.name}
                          disabled={Boolean(prodDBval && !editableIfStored)}
                          defaultValue={newValue}
                          type={(e.type === "DATE") ? "date" : "text"}
                          onChange={(d)=>assignProdText(index,index2,e.id,d.target.value)}
                          />
                        </Grid>

                        {e.type === "TEXT" &&
                          <Grid item key={`filter2-${index2}-${e.name}-${p}-ocr`} xs={4}>
                            {!Boolean(prodDBval && !editableIfStored) &&
                              <Button component="label"> <CameraAltIcon/> escanear
                                  <input
                                    id = "ocr-text"
                                    type="file"
                                    accept="image/*"
                                    onChange={(f)=>selectImg(f,index,index2,e.id)}
                                    style={{ display: "none" }}
                                  />
                              </Button>
                            }
                          </Grid>
                        }
                        </>
                        }
                        {e.type === "DROPDOWN" &&
                        <Grid item key={`filter2-${index2}-${e.name}-${p}`} xs={6}>
                          <FormControl fullWidth>
                            <InputLabel id="element_name">{e.name ? e.name : e.id}</InputLabel>
                            <Select
                              value={newValue}
                              options={e.options && JSON.parse(e.options)?.values}
                              fullWidth
                              disabled={Boolean(prodDBval && !editableIfStored)}
                              onChange={(d)=>assignProdText(index,index2,e.id,d.target.value)}>
                             <MenuItem key={`${index2}-${e.name}-${p}-0`} value={0} />
                             {e.options && JSON.parse(e.options)?.values?.map((i,e) => <MenuItem key={`${index2}-${e.name}-${p}-${i}`} value={i} name={i}>{i}</MenuItem> )}
                           </Select>
                          </FormControl>
                          </Grid>
                         }
                         {e.type === "GEOLOCATION" &&
                          <Grid item key={`filter2-${index2}-${e.name}-${p}`} xs={6}>
                            {newValue?.split("&")?.length === 2 &&
                              <a target="_map" href={`https://www.google.com/maps/place/${newValue.split("&")[0]},${newValue.split("&")[1]}`}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  component="label"
                                  key={`${index2}-${e.name}-${p}geo`}
                                  className={classes.full}
                                  startIcon={<MapIcon/>}
                                >
                                  Mapa: {e.name}
                                </Button>
                              </a>
                            }
                           {newValue?.split("&")?.length !== 2 &&
                             <Button
                               variant="contained"
                               size="small"
                               component="label"
                               key={`${index2}-${e.name}-${p}geo`}
                               onClick={()=>getLocation(index,index2,e.id)}
                               className={classes.full}
                               startIcon={<LocationOnIcon/>}
                             >
                               Geolocalizar: {e.name}
                             </Button>
                           }
                         </Grid>
                        }

                        {e.type === "IMAGE" && (isImageOpen !== `${index2}-${e.name}-${p}`) &&
                          <>
                            {newValue?.length > 0 &&
                              <>
                                <Grid item key={`filter2img1-${index2}-${e.name}-${p}`} xs={5}

                                  onClick={()=>setIsImageOpen(`${index2}-${e.name}-${p}`)}
                                  className={classes.minimgdiv}>
                                  <Typography variant="body2" color="textSecondary">
                                    IMÁGENES: {e.name}
                                  </Typography>
                                  {newValue?.map(img=><img alt="" src={`/server/request/docdownload?id=${img}`} className={classes.minimg}/>)}
                                </Grid>
                                <Grid item xs={1}>
                                  <ArrowDropDownIcon onClick={()=>setIsImageOpen(`${index2}-${e.name}-${p}`)}/>
                                </Grid>
                              </>
                            }
                            {newValue?.length === 0 &&
                              <Grid item key={`filter2img2-${index2}-${e.name}-${p}`} xs={6}>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    component="label"
                                    className={classes.cambutton}
                                    startIcon={<ImageIcon/>}>
                                      {e.name}: Añadir imagen
                                      <input
                                        id = {`${index2}-${e.name}-${p}img`}
                                        type="file"
                                        accept="image/*"
                                        onChange={(d)=>imageupload(index,index2,e.id,d,newValue)}
                                        style={{ display: "none" }}
                                      />
                                  </Button>
                              </Grid>
                            }
                          </>
                        }
                        {e.type === "IMAGE" && (isImageOpen === `${index2}-${e.name}-${p}`) &&
                        <>
                          <Grid item key={`filter2img-${index2}-${e.name}-${p}`} xs={11}>
                            <Typography variant="body2" color="textSecondary">
                              IMÁGENES: {e.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <ArrowDropUpIcon onClick={()=>setIsImageOpen("")}/>
                          </Grid>
                          <Grid item key={`filter2img-${index2}-${e.name}-${p}`} xs={12}>
                              <ImageList gap={1} cols={isMobile ? 1 : 3}>
                                {newValue?.length > 0 && newValue?.map((img,posImg) => (
                                    <ImageListItem key={img} cols={1}>
                                      <a className={classes.nodecor} target={"_img"+posImg} href={`/server/request/docdownload?id=${img}`}>
                                        <img loading="lazy" src={`/server/request/docdownload?id=${img}`} className={isMobile ? classes. mulimagemobile : classes.mulimage}/>
                                      </a>
                                      <ImageListItemBar className={classes.imgbar} position="top" actionPosition="right" title={img.split("-")[1]}
                                        actionIcon={ !Boolean(prodDBval && !editableIfStored) &&
                                          <IconButton>
                                            <DeleteIcon className={classes.white} onClick={()=>imagedelete(index,index2,e.id,img,newValue)}/>
                                          </IconButton>
                                        }
                                      />
                                    </ImageListItem>
                                ))}
                                { !Boolean(prodDBval && !editableIfStored) &&
                                  <ImageListItem key="button" cols={1}>
                                    <div className={classes.minimgbutton}>
                                      <Button
                                        variant="contained"
                                        size="small"
                                        component="label"
                                        className={classes.cambutton}
                                        startIcon={<ImageIcon/>}>
                                          {e.name}: Añadir imagen
                                          <input
                                            id = {`${index2}-${e.name}-${p}img`}
                                            type="file"
                                            accept="image/*"
                                            onChange={(d)=>imageupload(index,index2,e.id,d,newValue)}
                                            style={{ display: "none" }}
                                          />
                                        </Button>
                                    </div>
                                  </ImageListItem>
                                }
                              </ImageList>
                          </Grid>
                          </>
                        }
                      </>
                    )
              }
            }
        })
        itemDetails.push(
          <Grid item key={`comment-${index2}`} xs={12}>
            <TextField
              className={classes.fullblue}
              key={`comment-${index2}-key`}
              label="comentario"
              value={(workingOrders[index].prod[index2] && workingOrders[index].prod[index2]["comment"]) ? workingOrders[index].prod[index2]["comment"] : ""}
              onChange={(d)=>assignProdText(index,index2,"comment",d.target.value)}
              />
          </Grid>
        );
        newItemDetails.push(<Grid item id={`container-${index2}`} xs={12}><Grid key={`container-${index}`} container direction="row" justify="center" alignContent="center" alignItems="center" spacing={1} className={classes.itemDetails}>{itemDetails}</Grid></Grid>)
      //  window.scrollTo(0, findPosition(document.getElementById("production"+index)));
      }
    }
    return {items:[...allItems],allSubFilterItems:[...allSubFilterItems],itemDetails:newItemDetails,filter:displayval};
  }
  const displayProdItems = (index,mustDisable,woNotFinished) => {
    let thisWO = workingOrders[index]?.workorder;
    let allproductionModelElements = productionModel?.elements.map(e=>e.id);
    let existingProductos = workingOrders[index]?.prod.map(e=>Object.keys(e));
    let prodElementsFromPreviousSession = existingProductos?.map(f=>f.filter(e=>allproductionModelElements?.includes(e))).map(e=>e.length);
    let prodElementsFromPreviousSessionTotal = prodElementsFromPreviousSession?.reduce((a,c)=>{c+=parseInt(a);return c},0);
    let prodElementsFromOtherSources = existingProductos?.map(f=>f.filter(e=>!allproductionModelElements?.includes(e))).map(e=>e.length);
    let prodElementsFromOtherSourcesTotal = prodElementsFromOtherSources?.reduce((a,c)=>{c+=parseInt(a);return c},0);
    let pendingload = [];
    let woData2retrive = [];
    let allFilterValues = [];
    let allRows = [];
    let allAddProdButtons = {};
    if (Boolean(productionModel?.visible2 && (prodElementsFromPreviousSessionTotal===0 || prodElementsFromOtherSourcesTotal>0))) {
      let newAllItems = productos[index].map((e,index2) => docsAndProdDataFilters(index,index2,thisWO,woData2retrive,productos[index].length,allFilterValues,allRows,allAddProdButtons));
      //console.log("newAllItems",newAllItems)
      let allAddProdButtonsKeys = Object.keys(allAddProdButtons);
      allAddProdButtonsKeys.forEach(e=>{
        let globalButtonVal = sessionStorage.getItem(`g_p_b_${index}_${e}`);
        if (!globalButtonVal || globalButtonVal !== allAddProdButtons[e]) sessionStorage.setItem(`g_p_b_${index}_${e}`,allAddProdButtons[e]);
      })
      let allFilters = newAllItems.map(e=>e.filter);
      let newAllItems2 = [...newAllItems].map(e=>{
        return {items: e.items, allSubFilterItems: e.allSubFilterItems, itemDetails: e.itemDetails, filter: e.filter}
      });

      newAllItems.forEach((e,p)=>{
        let startPos = newAllItems.map(f=>f.filter).indexOf(e.filter);
        if (startPos > -1 && startPos !== p && e.allSubFilterItems) {
          newAllItems2[startPos].allSubFilterItems.push(e.allSubFilterItems);
          newAllItems2[p].allSubFilterItems = [];
          newAllItems2[startPos].itemDetails.push(e.itemDetails);
          newAllItems2[p].itemDetails = [];
          newAllItems2[p].items = [];
        }
      });
      //console.log("newAllItems2",[...newAllItems2])
      return newAllItems2.map(e=>[...e.items,...e.allSubFilterItems,...e.itemDetails]);
    }
    else if (Boolean(productionModel?.elements && (prodElementsFromOtherSourcesTotal===0 && (!productionModel?.visible2 || prodElementsFromPreviousSessionTotal>0)))) return productos[index].map((e,index2) => (
      <>
        {productionModel.elements.map((element,index3) => (
          <>
            {element.type.toLowerCase() === "text" &&
              <Grid item key={`${index}-${index2}-${index3}grid`} xs={element.length}>
                <TextField
                  className={index2  % 2 === 0 ? classes.full2 : classes.full}
                  id={`${index}-${index2}-${index3}`}
                  key={`${index}-${index2}-${index3}key`}
                  label={element.name ? element.name : element.id}
                  onChange={(d)=>assignProdText(index,index2,element.id,d.target.value)}
                  disabled={mustDisable && !editingDate?.employee}
                  value={(workingOrders[index].prod[index2] && workingOrders[index].prod[index2][element.id])? workingOrders[index].prod[index2][element.id] : ""}
                  />
              </Grid>
            }
            {element.type.toLowerCase() === "dropdown" &&
              <Grid item key={`${index}-${index2}-${index3}grid`} xs={element.length}>
                <FormControl fullWidth>
                  <InputLabel id="element_name">{element.name ? element.name : element.id}</InputLabel>
                  <Select
                    value={(workingOrders[index].prod[index2] && workingOrders[index].prod[index2][element.id])? workingOrders[index].prod[index2][element.id] : 0}
                    options={element.options?.values}
                    fullWidth
                    disabled={mustDisable && !editingDate?.employee}
                    onChange={(d)=>assignProdText(index,index2,element.id,d.target.value)}>
                     <MenuItem key={`${index}-${index2}-${index3}-0`} value={0} />
                     {element.options?.values?.map((i,e) => <MenuItem key={`${index}-${index2}-${index3}-${i}`} value={i} name={i}>{i}</MenuItem> )}
                   </Select>
                </FormControl>
              </Grid>
            }
            {element.type.toLowerCase() === "date" && (!mustDisable || (workingOrders[index].prod[index2] && workingOrders[index].prod[index2][element.id])) &&
              <Grid item key={`${index}-${index2}-${index3}grid`} xs={element.length}>
                <TextField
                  className={index2  % 2 === 0 ? classes.full2 : classes.full}
                  id={`${index}-${index2}-${index3}`}
                  key={`${index}-${index2}-${index3}key`}
                  label={element.name}
                  onChange={(d)=>assignProdText(index,index2,element.id,d.target.value)}
                  disabled={mustDisable && !editingDate?.employee}
                  type="date"
                  value={(workingOrders[index].prod[index2] && workingOrders[index].prod[index2][element.id])? workingOrders[index].prod[index2][element.id] : ""}
                  />
              </Grid>
            }
            {element.type.toLowerCase() === "geolocation" &&
              <>
                {(workingOrders[index].prod[index2] && workingOrders[index].prod[index2][element.id]) &&
                  <>
                    <Grid item key={`${index}-${index2}-${index3}grid-icon`} xs={1}>
                      <LocationOnIcon/>
                    </Grid>
                    <Grid item key={`${index}-${index2}-${index3}grid-text`} xs={element.length-1}>
                      lat: {workingOrders[index].prod[index2][element.id].split("&")[0]}
                      <br/>
                      long: {workingOrders[index].prod[index2][element.id].split("&")[1]}
                    </Grid>
                  </>
                }
                {!(workingOrders[index].prod[index2] && workingOrders[index].prod[index2][element.id]) && !(isCompleted || woNotFinished) &&
                  <Grid item key={`${index}-${index2}-${index3}grid`} xs={element.length}>
                    <Button
                      key={`${index}-${index2}-${index3}key`}
                      onClick={(e)=>getLocation(index,index2,element.id)}
                      className={classes.inputicons}
                      startIcon={<LocationOnIcon/>}
                    >
                      {element.name}
                    </Button>
                  </Grid>
                }
              </>
            }
            {element.type.toLowerCase() === "image" &&
              <Grid item key={`${index}-${index2}-${index3}grid`} xs={element.length}>
                {(workingOrders[index].prod[index2] && workingOrders[index].prod[index2][element.id]) &&
                  <img alt="" src={`/server/request/docdownload?id=${workingOrders[index].prod[index2][element.id]}`} className={classes.maxfull}/>
                }
                {!(workingOrders[index].prod[index2] && workingOrders[index].prod[index2][element.id]) && !(isCompleted || woNotFinished) &&
                  <Button
                    variant="contained"
                    size="small"
                    component="label"
                    className={classes.cambutton}
                    startIcon={<ImageIcon/>}>
                      {element.name}
                      <input
                        id = {`${index}-${index2}-${index3}img`}
                        type="file"
                        accept="image/*"
                        onChange={(d)=>imageupload(index,index2,element.id,d)}
                        style={{ display: "none" }}
                      />
                  </Button>
                }
              </Grid>
            }
          </>
        ))}

      </>
    ))
  }
  const productionsummary = (e,index) => {
    let toDisplay = []
    let allproductionModelElements = productionModel?.elements?.map(e=>e.id);
    let existingProductos = workingOrders[index].prod.map(e=>Object.keys(e));
    let prodElementsFromPreviousSession = existingProductos.map(f=>f.filter(e=>allproductionModelElements?.includes(e))).map(e=>e.length);
    let prodElementsFromOtherSources = existingProductos.map(f=>f.filter(e=>!allproductionModelElements?.includes(e))).map(e=>e.length);
    productos[index].map((f,index2) => {
      toDisplay.push(<Grid item xs={12} className={classes.strong}> Datos de producción {index2+1}</Grid>);
      if (prodElementsFromOtherSources[index2]>0) {
          let prodBlock = e.prod[index2];
          let blockItems = Object.keys(prodBlock);
          blockItems.map(item=>{
            let itemVals = docsAndProdData[e.workorder].filters.filter(filter=>filter.id?.toString() === item.toString());
            if (itemVals.length > 0) {
              if (["TEXT","NUMB","DECIM","PERCENT","DATE","DROPDOWN","GEOLOCATION"].includes(itemVals[0].type)) toDisplay.push( <Grid item xs={4}>{itemVals[0].name}: {prodBlock[item]}</Grid> );
              else if (itemVals[0].type==="IMAGE") {
                if (Array.isArray(prodBlock[item])) toDisplay.push(
                  <div className={classes.rowImg}>
                    {prodBlock[item]?.map(img=>
                      <div className={classes.columnImg}>
                          <img alt="" src={`/server/request/docdownload?id=${img}`} className={classes.minimg}/>
                      </div>
                    )}
                  </div>
                );
              }
            }
          });
      }
      else if (prodElementsFromPreviousSession[index2]>0) {
        toDisplay.push(productionModel?.elements?.map((element,index3) => (
            <Grid item xs={4}>{element.id}: {(workingOrders[index].prod[index2] && workingOrders[index].prod[index2][element.id])? workingOrders[index].prod[index2][element.id] : ""}</Grid>
        )))
      }
    });
    return toDisplay;
  }
  const displayWOdata = (e, index) => {
    let woNotFinished = rejectedWO.includes(index) || addedWOs.includes(index);
    let fullyfinished = isCompleted && !woNotFinished;
    let ismanagerInRangeDate = shouldProceed(today) && userData.role > 19;
    let mustDisable = ((isCompleted || finishedWO.filter((j)=>j.manager === e.manager && j.working_order.toString() === e.workorder).length > 0) && !woNotFinished && !ismanagerInRangeDate);
    let totalFitlers = (workingOrders[index]?.workorder && docsAndProdData[workingOrders[index]?.workorder]?.allfilters) ? docsAndProdData[workingOrders[index]?.workorder].allfilters.length : 0;
    let distinctFilters = (workingOrders[index]?.workorder && docsAndProdData[workingOrders[index]?.workorder]?.dataset) ? docsAndProdData[workingOrders[index]?.workorder].dataset.map(e=>e.filter) : [];
    let distinctFiltersUnique = distinctFilters.filter((v,i) => distinctFilters.indexOf(v) === i);
    let remainingFilters = totalFitlers - distinctFiltersUnique.length;
    return (
      <>
        <Grid item xs={12} className={classes.topgap}>
          <Typography variant="body1" color="textSecondary" className={classes.projectTitle}>
            <span className={classes.title100}>
              Orden de trabajo {index+1}
              {e.manager && e.workorder &&
                <>:&nbsp;
                {projectName(index)}
                </>
              }
            </span>
            <span className={classes.delEnd}>
            {workingOrders.length > 1 && (( !fullyfinished && !editingDate?.employee) || userData.role > 49) &&
              <DeleteIcon onClick={()=>deleteWorkOrder(e,index)} className={classes.pointer}/>
            }
            </span>
          </Typography>
        </Grid>
        {workingOrders[index].comments &&
          <Grid item xs={12} className={classes.topgap}>
            <Typography variant="body1" color="textSecondary" className={classes.rejectionComments}>
              {workingOrders[index].comments}
            </Typography>
          </Grid>
        }
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="Encargado">Encargado</InputLabel>
            <Select
              id="Encargado"
              value={e.manager ? e.manager : ""}
              onChange={(j) => assignManager(index,j.target.value)}
              disabled={mustDisable && !editingDate?.employee}
              >
              <MenuItem value="" disabled key="01-first">elige un encargado</MenuItem>
              {managers && managers.map((e,index2) =>
                <MenuItem key={`manager${index2}`} value={e.id} selected={e.id===e.manager}>{e.name}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="categoria">Orden de trabajo</InputLabel>
            <Select
              id="categoria"
              value={e.workorder ? e.workorder : ""}
              onChange={(f)=>asignProject(index,f.target.value,e.manager)}
              disabled={mustDisable && !editingDate?.employee}
              >
              <MenuItem value="" disabled key="01-first">elige un proyecto</MenuItem>
              {projects && projects.map((proj,index2) => (
                     (proj.assembly_manager === e.manager || proj.workshop_manager === e.manager || proj.internal === 1 || (proj.work_report === e.workorder && editStage > 0)) &&
                    <MenuItem
                      value={proj.work_report}
                      key={index2}
                      selected={proj.work_report===e.workorder}
                      disabled={projectIDs.filter((f) => {
                        return f.manager === e.manager && f.wo === proj.work_report;
                      }).length > 0}
                      >
                      {proj.work_report} {proj.description}
                    </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="descripcion"
            label="Descripción"
            className={classes.full}
            onChange={(e)=>assignDescription(index,e.target.value)}
            disabled={mustDisable && !editingDate?.employee}
            value={workingOrders[index].description}
            />
        </Grid>
        {(!hiddeUntilClientProject || (e.manager && e.workorder)) &&
          <>
            {workedHoursModel.elements.map((element,indexelement)=>
              <>
                {element.type === "number" && displayNumberElement(element,index,mustDisable)}
                {element.type === "checkbox" &&
                  <Grid item xs={6} className={classes.topgap}>
                    <Checkbox
                      color="default"
                      className={mustDisable ? classes.grey : null}
                      checked={workingOrders[index][element.id]}
                      onClick={(e)=>assignNumber(element,index,e.target.checked)}
                      disabled={mustDisable && !editingDate?.employee}/>
                      <span className={mustDisable ? classes.grey : null}>{element.id}</span>
                  </Grid>
                }
                {element.type === "dropdown" &&
                  <>
                    <Grid item xs={6} className={classes.topgap}>
                      <FormControl fullWidth>
                        <InputLabel id="tipohoraslabl">{element.id}</InputLabel>
                        <Select
                          id="tipohoras"
                          className={mustDisable ? classes.grey : null}
                          value={workingOrders[index][element.id]}
                          onChange={(e)=>assignNumber(element,index,e.target.value)}
                          disabled={mustDisable && !editingDate?.employee}
                          >
                          {element.options.map((option,index4)=>
                            <MenuItem value={option} key={index4}>{option}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                }
                {element.type === "kilometers" &&
                  <Grid item xs={6} className={classes.topgapright}>
                    <TextField
                      label={element.id}
                      className={classes.full}
                      value={workingOrders[index][element.id]}
                      onChange={(e)=>assignNumber(element,index,e.target.value)}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">Km</InputAdornment>,
                      }}
                      disabled={mustDisable && !editingDate?.employee}
                      />
                  </Grid>
                }
              </>
            )}
            {((!isCompleted || woNotFinished) || editingDate?.employee) &&
              <>
                {productionModel && docsAndProdData[workingOrders[index]?.workorder]?.docs?.length > 0 &&
                  <>
                    <Grid item xs={12} className={classes.topgapheader} onClick={()=>{setDisplayDocuments(!displayDocuments)}}>
                    {!displayDocuments &&
                      <Button
                        className={classes.button1}
                        onClick={()=>{setDisplayDocuments(!displayDocuments)}}
                        startIcon={<FolderIcon/>}
                        > Documentos ({docsAndProdData[workingOrders[index].workorder].docs.length})
                      </Button>
                      }
                      {displayDocuments &&
                        <Typography variant="body1" color="textSecondary">
                          <FolderIcon className={classes.algin2bottom}/> Documentos
                        </Typography>
                      }
                     </Grid>
                    {displayDocuments && docsAndProdData[workingOrders[index].workorder].docs?.map((e)=>
                      <>
                        <Grid item xs={1}>
                         <a href={`/server/request/docdownload?id=${e.name}`} className={classes.nodecors} target="_doc">
                           <InsertDriveFileIcon/>
                         </a>
                        </Grid>
                        <Grid item xs={11} className={classes.wrapping}>
                         <a href={`/server/request/docdownload?id=${e.name}`} className={classes.nodecors} target="_doc">
                           {Boolean(e.description) && e.description}
                           {!Boolean(e.description) && e.name.split("----------").length > 1 && e.name.split("----------")[1]}
                           {!Boolean(e.description) && e.name.split("----------").length === 1 && e.name.split("-")[1]}
                         </a>
                        </Grid>
                      </>
                    )}
                  </>
                 }
                </>
            }
            {productionModel &&
              <Grid item xs={12} className={classes.wo_header} id={"production"+index}>
                <Typography variant="body1" color="textSecondary">
                  <FactoryIcon className={classes.algin2middle}/> Producción
                </Typography>
              </Grid>
            }
            {productos.length > 0 && displayProdItems(index,mustDisable,woNotFinished)}
            {Boolean(productionModel?.visible2) && Boolean(remainingFilters) &&
              <Grid item key={`addProdSection-${index}`} xs={12} className={classes.topbottomgap}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button1}
                  onClick={()=>addProd(index,productos[index].length,false)}
                  startIcon={productos.length === 0 && <FactoryIcon/>}
                >
                  {productos.length > 0 && <>añadir nueva sección de producción</>}
                  {productos.length === 0 && <>añadir producción</>}
                </Button>
              </Grid>
            }
          </>
        }
      </>
    )
  }

  const selectImg = (event,index,index2,id) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setImageVal({data:reader.result?.toString(),index:index,index2:index2,id:id,type:"ocr"})
      }
    )
    reader.readAsDataURL(event.target.files[0])
  }
  const filetoOCR = (file) => {
    setDisplayCircularProgress("Actualizando");
    async function base64ToFile(base64) {
      const res = await fetch(base64)
      const buf = await res.arrayBuffer()
      const file = new File([buf], "capture_camera.jpeg", {
        type: 'image/jpeg',
      })
      let formData = new FormData();
      formData.append("image", file);
      postPlain('/server/request/uploadocr',formData,{'enctype': 'multipart/form-data'})
      .then((response)=>{
        if (response.detections) {
          assignProdText(imageVal.index,imageVal.index2,imageVal.id,response.detections)
        }
        setDisplayCircularProgress(false);
        setImageVal();
        })
    };
    base64ToFile(file)
  }
  useEffect(()=>{
    if (canTrack) getTimingLocation();
    else setCurrentLocation();
  },[canTrack]);

  const displayNumberElement = (element,index,mustDisable) => {
    let options = (element.multipleoptions) ? element.multipleoptions : [""];
    return options.map((option,pos)=>
    <>
      {pos === 0 &&
        <Grid item xs={options.length > 1 ? 12 : 6} className={classes.wo_header}>
          {!element.icon &&
            <Typography variant="body1" color="textSecondary">
              {element.id}:
            </Typography>
          }
          {element.icon &&
            <Typography variant="body1" color="textSecondary">
              {Boolean(iconsSet[element.icon]) && iconsSet[element.icon]}
              {` ${element.id}`}
            </Typography>
          }
        </Grid>
      }
      <Grid item xs={6}>
      <p className={mustDisable ? classes.greynomarings : classes.nomarings}>{option}</p>
        <FormControl fullWidth className={classes.numberitem} key={`tipohoraslabl-${index}-${element.id}-${pos}-form`}>
          <ButtonGroup size="small">
            {(!mustDisable || editingDate?.employee) &&
              <Button className={classes.numberButton} onClick={()=>assignNumber(element,index,"add",element.incremental,element.highest,pos)} key={`tipohoraslabl-${index}-${element.id}-${pos}-add`}>
                <AddCircleIcon/>
              </Button>
            }
            <Button className={classes.numberButtonMiddle} disabled key={`tipohoraslabl-${index}-${element.id}-${pos}-display`}>
              <Typography variant="body1" color="textSecondary" className={mustDisable ? classes.grey : null}>
                {workingOrders[index][element.id][pos]}
              </Typography>
            </Button>
            {(!mustDisable || editingDate?.employee) &&
              <Button className={classes.numberButton} onClick={()=>assignNumber(element,index,"reduce",element.decremental,element.lowest,pos)} key={`tipohoraslabl-${index}-${element.id}-${pos}-remove`}>
                <RemoveCircleIcon/>
              </Button>
            }
          </ButtonGroup>
          </FormControl>
      </Grid>
    </>
    )
  }

  return (
    <>
    <Container maxWidth="md" className={classes.contentTop}>
      <FormGroup>
        <Grid container direction="row" justify="center" alignContent="center" alignItems="center" spacing={1}>
        {!(signatureIMG && ready2sign) &&
          <>
            {editingDate?.employee &&
              <Grid item xs={12} className={classes.topgap}>
                <Typography variant="body1" color="textSecondary" className={classes.rejectionComments}>
                  Empleado: <strong>{editingDate.name}</strong>
                </Typography>
              </Grid>
            }
            <Grid item xs={6}>
              <Typography variant="h6" color="textSecondary">
              {viewTimings !== "view" &&
                <>
                  Parte del día {editStage && <strong>{showStage(editStage)}</strong>}
                </>
              }
              {viewTimings === "view" &&
                <>
                  Horas trabajadas
                </>
              }
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="report_date"
                label="Fecha"
                type="date"
                size="small"
                value={today}
                fullWidth
                onChange={checkDate}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={editingDate?.employee}
              />
            </Grid>
            {viewTimings === "start" && today === startingDay && today === startingDay && autoHours === 1 && !(editingDate?.employee) &&
              <>
              <Grid item xs={12} className={classes.buttonStartDiv}>

                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component="span"
                  onClick={startingEndingTime}
                  className={classes.buttonStart}>
                  Comenzar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FormGroup className={classes.centerLabelDiv}>
                  <FormControlLabel className={classes.centerLabel} control={<Checkbox defaultChecked color="default" checked={canTrack} onClick={()=>setCanTrack(!canTrack)}/>} label="Guardar geolocalización" />
                </FormGroup>
              </Grid>
              <Grid item xs={12} className={classes.centerLabelDiv}>
                <Typography variant="body2" color="textSecondary">
                  La geolocalización sólo se registra en el instante de clicar "Comenzar", "Finalizar" y "Firmar".
                </Typography>
              </Grid>
              </>
            }
            {viewTimings === "view" && !holidays && paidleave === "0" && workedHoursModel.working_hours.system !== "auto" &&
              <>
                <Grid item xs={6}>
                  <TextField
                    id="start_time"
                    type="time"
                    label="Hora de entrada"
                    inputProps={inputProps}
                    value={workingHoursStart}
                    disabled={(isCompleted || signatureIMG) && !editingDate?.employee}
                    fullWidth
                    onChange={e=>setWorkingHoursStart(e.target.value)}/>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="end_time"
                    type="time"
                    label="Hora de salida"
                    inputProps={inputProps}
                    value={workingHoursEnd}
                    disabled={(isCompleted || signatureIMG) && !editingDate?.employee}
                    fullWidth
                    onChange={e=>setWorkingHoursEnd(e.target.value)}/>
                </Grid>
              </>
            }
            {viewTimings === "view" && autoHours === 2 && !holidays && paidleave === "0" && workedHoursModel.working_hours.system === "auto" && timings?.map((e,i)=>
              <>
              {i === 0 &&
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
              }
              {!reviewTimesMode && today === startingDay &&
                <>
                  <Grid item xs={2}/>
                  <Grid item xs={1}>
                    <AccessTimeIcon/>
                  </Grid>
                  <Grid item xs={2} onClick={()=>setReviewTimesMode({start:i})}>
                    {displayTime(e.start)}
                  </Grid>
                  <Grid item xs={1}>
                    {e.manualstart && <EditIcon />}
                    {!e.manualstart && e.starttrack && e.starttrack.lat && e.starttrack.long && <a target="_map" className={classes.nodecor} href={`https://www.google.com/maps/search/?api=1&query=${e.starttrack.lat}%2C${e.starttrack.long}`}><LocationOnIcon /></a>}
                  </Grid>
                  <Grid item xs={1}> - </Grid>
                  {e.end &&
                    <Grid item xs={2} onClick={()=>setReviewTimesMode({end:i})}>
                      {displayTime(e.end)}
                    </Grid>
                  }
                  {!e.end &&
                    <Grid item xs={2}>
                      <CircularProgress className={classes.smallIcon}/>
                    </Grid>
                  }
                  <Grid item xs={1}>
                    {e.manualend && <EditIcon />}
                    {!e.manualend && e.endtrack && e.endtrack.lat && e.endtrack.long && <a target="_map" className={classes.nodecor} href={`https://www.google.com/maps/search/?api=1&query=${e.endtrack.lat}%2C${e.endtrack.long}`}><LocationOnIcon /></a>}
                  </Grid>
                  <Grid item xs={2}/>
                </>
              }
            {i+1 === timings.length && timings.filter((j)=>j.manualstart || j.manualend)?.length > 0 &&
              <>
                <Grid item xs={1}/>
                <Grid item xs={10} className={classes.redAlert}>
                  Cambios Manuales: Pendiente de aprobación por Administración.
                </Grid>
                <Grid item xs={1}/>
              </>
            }
              {(reviewTimesMode || today !== startingDay) &&
                  <>
                  {i===0 &&
                    <Grid item xs={12}>&nbsp;</Grid>
                  }
                    <Grid item xs={1}/>
                    <Grid item xs={5}>
                      <TextField
                        id={`start_time_${i}`}
                        type="time"
                        label="Comienzo"
                        inputProps={inputProps}
                        value={displayTime(e.start)}
                        color={!e.start?"warning":""}
                        focused={!e.start}
                        className={!e.start && classes.backgroundWarning}
                        disabled={workedHoursModel.working_hours.system === "auto" && today === startingDay && !(reviewTimesMode?.start === i)}
                        fullWidth
                        onChange={e=>newChangedTiming(e.target.value,"start",i)}/>
                      </Grid>
                      <Grid item xs={5}>
                        {(i+1 < timings.length || e.end || today !== startingDay) &&
                          <TextField
                            id={`end_time_${i}`}
                            type="time"
                            label="Fin"
                            inputProps={inputProps}
                            value={displayTime(e.end)}
                            color={!e.end?"warning":""}
                            focused={!e.end}
                            className={!e.end && classes.backgroundWarning}
                            disabled={workedHoursModel.working_hours.system === "auto" && today === startingDay && !(reviewTimesMode?.end === i)}
                            fullWidth
                            onChange={e=>newChangedTiming(e.target.value,"end",i)}/>
                          }
                      </Grid>
                      <Grid item xs={1}/>
                      {i+1 === timings.length && today !== startingDay &&
                        <>
                          <Grid item xs={12}>&nbsp;</Grid>
                          <Grid item xs={1}/>
                          <Grid item xs={5} className={classes.center}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              component="span"
                              onClick={() => startingEndingTime("add","start")}
                              startIcon={<AddIcon/>}
                              className={classes.button1}>
                              Añadir
                            </Button>
                          </Grid>
                        </>
                      }
                    </>
                  }
              {i+1 === timings.length &&
               workedHoursModel.working_hours.system === "auto" &&
               e.start && e.end &&
               today === startingDay &&
                  <Grid item xs={12} className={classes.buttonDateDiv}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      component="span"
                      onClick={() => startingEndingTime("add","start")}
                      className={classes.buttonStart}>
                      Comenzar
                    </Button>
                  </Grid>
                }
              {i+1 === timings.length &&
               workedHoursModel.working_hours.system === "auto" &&
               !e.end &&
               today === startingDay &&
                  <Grid item xs={12} className={classes.buttonDateDiv}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      component="span"
                      onClick={() => startingEndingTime("add","end")}
                      className={classes.buttonStart}>
                      Finalizar
                    </Button>
                  </Grid>
                }
              {i+1 === timings.length && today === startingDay && today === startingDay &&
                <>
                <Grid item xs={12}>
                  <FormGroup className={classes.centerLabelDiv}>
                    <FormControlLabel className={classes.centerLabel} control={<Checkbox defaultChecked color="default" checked={canTrack} onClick={()=>setCanTrack(!canTrack)}/>} label="Guardar geolocalización" />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} className={classes.centerLabelDiv}>
                  <Typography variant="body2" color="textSecondary">
                    La geolocalización sólo se registra en el instante de clicar "Comenzar", "Finalizar" y "Firmar".
                  </Typography>
                </Grid>
                </>
              }
              </>
            )}
            {viewTimings === "view" && autoHours === 2 && !holidays && paidleave === "0" && workedHoursModel.working_hours.system === "auto" && today !== startingDay &&
              <>
                <Grid item xs={5} className={classes.center}>
                  Total: {displayTotalTime()}
                </Grid>
                <Grid item xs={1}/>
              </>
            }
            {viewTimings === "hidde" && autoHours !== 1 && workedHoursModel && workedHoursModel.comment &&
              <Grid item xs={12}>
                <TextField
                  id="comment"
                  label="Comentario"
                  value={commentText}
                  disabled={(isCompleted || signatureIMG) && !editingDate?.employee}
                  className={classes.full}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={e=>setCommentText(e.target.value)}/>
              </Grid>
            }
            {viewTimings === "hidde" && autoHours !== 1 && (userData.role > 49 || userData?.privileges?.holidays_approval === "true" || ((isCompleted || signatureIMG) && !editingDate?.employee)) &&
              <>
                <Grid item xs={6}>
                  <Checkbox color="default" checked={holidays} disabled={(isCompleted || signatureIMG) && !editingDate?.employee} onClick={assignHolidays}/>
                  Vacaciones
                </Grid>
                <Grid item xs={6}>
                  {leaveTypesModel &&
                    <FormControl fullWidth>
                      <InputLabel id="leaveTypesModelName">{leaveTypesModel.name}</InputLabel>
                      <Select
                        id="leavetype"
                        value={paidleave}
                        onChange={assigntPaidleave}
                        disabled={(isCompleted || signatureIMG) && !editingDate?.employee}
                        >
                        <MenuItem value="0" key="leavetypenot">-</MenuItem>
                        {leaveTypesModel && leaveTypesModel.types.map((e,index2) =>
                          <MenuItem key={`leavetype${index2}`} value={e.def} selected={e.def===paidleave}>{e.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  }
                  </Grid>
                </>
              }
            {viewTimings === "hidde" && autoHours !== 1 && !holidays && paidleave === "0" && workingOrders.map((e,index) => displayWOdata(e,index))}
            {viewTimings === "hidde" &&
             autoHours !== 1 &&
             !holidays && paidleave === "0" &&
             ((!isCompleted || rejectedWO.length > 0 || today === startingDay) || editingDate?.employee) &&
             (workingOrders.length === 0 || (workingOrders[workingOrders.length-1] && workingOrders[workingOrders.length-1].manager && workingOrders[workingOrders.length-1].workorder)) &&
                <Grid item xs={12} alignItems="center" className={classes.topbottomgap}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    component="span"
                    className={classes.button1}
                    onClick={addWorkOrder}
                    startIcon={<NoteAddIcon/>}
                  > Nueva orden de Trabajo </Button>
                </Grid>
              }
            </>
            }

        {(signatureIMG && ready2sign) &&
        <>
          <Grid item xs={12}> Parte del día {presentDate(today)} </Grid>
          { !holidays && paidleave === "0" && workedHoursModel.working_hours.system !== "auto" &&
            <>
              <Grid item xs={6}> Hora de entrada: {workingHoursStart} </Grid>
              <Grid item xs={6}> Hora de salida: {workingHoursEnd} </Grid>
            </>
          }
          { !holidays && paidleave === "0" && workedHoursModel.working_hours.system === "auto" && timings?.map((e,i)=>
            <>
              <Grid item xs={6}>
                Hora de entrada:
                {e.start && <>{displayTime(e.start)}</>}
              </Grid>
              <Grid item xs={6}>
                Hora de salida:
                {e.end && <>{displayTime(e.end)}</>}
                {!e.end && <>{displayTime(new Date())}</>}
              </Grid>
              {i+1 === timings.length &&
                <Grid item xs={6}>
                  Total de horas: {displayTotalTime()}
                </Grid>
              }
            </>
          )}
          {holidays && <Grid item xs={6}> Vacaciones </Grid>}
          {paidleave !== "0" &&
            <Grid item xs={6}>
              {leaveTypesModel.name}:
              {leaveTypeName()}
            </Grid>
          }
          { (!holidays && paidleave === "0") && workingOrders.map((e,index) => (
            <>
                <Grid item xs={12}> &nbsp;</Grid>
                <Grid item xs={12} className={classes.strong}>
                  Orden de trabajo {index+1}
                  {e.manager && e.workorder &&
                    <>:&nbsp;
                    {projectName(index)}
                    </>
                  }
                </Grid>
                <Grid item xs={12}> {e.description} </Grid>
                {workedHoursModel.elements.map((element)=>
                  <>
                    {element.type !== "checkbox" && element.type !== "number" &&
                      <Grid item xs={4}>{element.id}: {e[element.id]}</Grid>
                    }
                    {element.type === "number" &&
                      <> {displayNumberArray(element,e)} </>
                    }
                    {element.type === "checkbox" && Boolean(e[element.id]) &&
                      <Grid item xs={4}>{element.id}: Si</Grid>
                    }
                  </>
                )}
                {productionsummary(e,index)}
            </>
          ))}
          </>
        }
        {ready2sign &&
          <CanvasSign returnCanvas={displaysummary} saveshiftdata={saveshiftdata} date={today} signatureID={signatureID} resubmit={Boolean(rejectedWO.length > 0)} isCompleted={isCompleted}/>
        }
      </Grid>
    </FormGroup>
    <Snackbar open={isTooEarlyDate} autoHideDuration={4500} onClose={()=>setisTooEarlyDate(false)}>
      <MuiAlert elevation={6} variant="filled"  onClose={()=>setisExistingDate(false)} severity="warning" sx={{ width: '100%' }}>
        Fecha de creación de parte no puede ser antenior a {workedHoursModel && workedHoursModel['limit-days'] && `${workedHoursModel['limit-days']}`} días
      </MuiAlert>
    </Snackbar>
    <Snackbar open={isExistingDate} autoHideDuration={4500} onClose={()=>setisExistingDate(false)}>
      <MuiAlert elevation={6} variant="filled"  onClose={()=>setisExistingDate(false)} severity="warning" sx={{ width: '100%' }}>
        Ya existe un parte en esa fecha
      </MuiAlert>
    </Snackbar>
    <Snackbar open={isSaved?.stored} autoHideDuration={4500} onClose={()=>setIsSaved(false)}>
      <MuiAlert elevation={6} variant="filled"  onClose={()=>setIsSaved(false)} severity="success" sx={{ width: '100%' }}>
        Guardado!
      </MuiAlert>
    </Snackbar>
    <Snackbar open={isSaved?.error} autoHideDuration={4500} onClose={()=>setIsSaved(false)}>
      <MuiAlert elevation={6} variant="filled"  onClose={()=>setIsSaved(false)} severity="warning" sx={{ width: '100%' }}>
        No estás autorizado
      </MuiAlert>
    </Snackbar>
    <Dialog open={displayCircularProgress}>
      <DialogTitle>{displayCircularProgress}</DialogTitle>
      <Box className={classes.circularprogressdialog}>
        <CircularProgress />
      </Box>
    </Dialog>
    <Dialog open={displayTimeEditing} onClose={()=>setDisplayTimeEditing(false)}>
      <DialogTitle>Modificar horas</DialogTitle>
      <DialogContent>
        <DialogContentText>¿Estás seguro de cambiar las horas trabajadas?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>setDisplayTimeEditing(false)}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>{setDisplayTimeEditing(false);setReviewTimesMode(displayTimeEditing)}}>
          Estoy Seguro
        </Button>
      </DialogActions>
    </Dialog>
    <BottomNavigation/>
  </Container>
  <Box className={classes.bottomNavBox}>
    {!ready2sign &&
      <BottomNavigation showLabels className={classes.bottomNav}>
        {!editingDate?.employee && autoHours === 1 &&
          <BottomNavigationAction className={classes.bottomNavButton} label={(today === startingDay) ? "Comenzar" : "Horas trabajadas"} icon={<AccessTimeIcon />} onClick={()=>setViewTimings("start")} key="start"/>
        }
        {!editingDate?.employee && autoHours !== 1 && viewTimings === "hidde" &&
          <BottomNavigationAction label={(today === startingDay) ? workedTime : "Horas trabajadas"} icon={<AccessTimeIcon className={classes.spining}/>} onClick={()=>setViewTimings("view")} key="view"
            className={(today !== startingDay || (timings && timings[timings.length-1].end)) ? classes.bottomNavButton : classes.bottomNavButtonRed}/>
        }
        {!editingDate?.employee && autoHours !== 1 && viewTimings === "view" &&
          <BottomNavigationAction label={(today === startingDay) ? workedTime : "Horas trabajadas"} icon={<AccessTimeIcon />} onClick={()=>setViewTimings("hidde")} key="hidde"
            className={(today !== startingDay || (timings && timings[timings.length-1].end)) ? classes.bottomNavButton : classes.bottomNavButtonRed}/>
        }
        {(workingOrders?.filter((e)=>e.manager && e.workorder).length > 0 ||
          ( viewTimings === "hidde" &&
            autoHours !== 1 &&
            ( userData.role > 49 ||
              userData?.privileges?.holidays_approval === "true" ||
              ( ( isCompleted || signatureIMG ) && !editingDate?.employee ) )
            )
        ) &&
          <BottomNavigationAction className={classes.bottomNavButton} label="Guardar" icon={<SaveIcon onClick={()=>saveshiftdata("stay")}/>} />
        }
        {( !editingDate?.employee &&
           (
             (isCompleted && signatureID) ||
             ( !isCompleted &&
               ( holidays ||
                 paidleave !== "0" ||
                 ( workingOrders[0] &&
                   workingOrders[0].manager && workingOrders[0].workorder
                 )
               )
             ) ||
             rejectedWO.length > 0
           )
          ) &&
          (workedHoursModel?.working_hours?.system !== "auto" || autoHours === 2) &&
            <BottomNavigationAction
              className={classes.bottomNavButton}
              label={(!isCompleted && !signatureID) ? "Firmar y Cerrar" : "Volver a firmar"}
              icon={(!isCompleted) ? <TaskIcon onClick={()=>setReady2sign(true)}/> : <TaskIcon onClick={saveshiftdata}/>}
              />
        }
      </BottomNavigation>
      }
      {ready2sign &&
        <BottomNavigation showLabels className={classes.bottomNav}>
          <BottomNavigationAction className={classes.bottomNavButton} label="Modificar" icon={<ArrowBackIcon onClick={()=>setReady2sign(false)}/>} />
          <BottomNavigationAction className={classes.bottomNavButton} label="Enviar" icon={<VerifiedUserIcon onClick={saveshiftdata}/>} />
        </BottomNavigation>
      }
    </Box>
    {imageVal &&
        <CameraCropper src={imageVal.data} croppedImg={(img)=>filetoOCR(img)} type="ocr" cancel={()=>setImageVal()}/>
    }
  </>
  );
}
